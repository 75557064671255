<script setup>
import {ref, onMounted, reactive} from 'vue';
import {messageObj} from 'src/helpers/message';

//Нужно понять в каком виде вы хотим пропсы, как они передаются
//Я так понял, что сам рейтинг будет запрашиваться в компоненте родителя и передаваться в качестве пропса
//Надо подумать, откуда брать проголосовавших юзеров и в каком виде они придут

const props = defineProps({
  // !!! Ссылка на this компонента App.vue
  parent: {
    type: Object,
    required: true,
  },
  lang: {
    type: String,
    required: true,
    default: 'en',
  },

  // Id того элемента на странице к которому привязываем рейтинг
  entityId: {
    type: String,
    required: true,
    default: 'rate_none',
  },

  size: {
    type: String,
    default: '1em',
  },
  icon: {
    type: String,
    required: true,
    default: 'star',
  },
  icon_half: {
    type: String,
    default: '',
  },
  color: {
    type: String,
    required: true,
    default: 'red-7',
  },
  max: {
    type: Number,
    default: 5,
  },
});

const message = messageObj;
const currentRating = ref(0);
const averageRating = ref(0);
const showSecondDiv = ref(false);

// !!!
const votedUsers = ref([]);
const loading = ref(false);
const id=ref(false); // id в БД для сохранений

function updateRating() {
  //Логика при изменении рейтинга
  //!!! Что бы избежать коллизий нужно загрузить свежее состояние рейтинга для entityId
  //Обновить или добавить результат по конкретному юзеру и сразу же записать в БД
  loading.value = true; //какой нибудь спинер
  props.parent.loadRatings(props.entityId).then((res) => {
    id.value = res?.id || false;
    let list = res?.data || [];
    const uid = props.parent.user_current.ID;
    const foundIndex = list.findIndex((el) => el.uid == uid);
    if (foundIndex > -1) {
      list[foundIndex] = {uid: uid, rate: currentRating.value};
    } else {
      list.push({uid: uid, rate: currentRating.value});
    }
    const len = votedUsers.value?.length || 0;
    votedUsers.value.splice(0, votedUsers.value.length, ...list);
    getAverageRating();
    //Сохраним в БД
    props.parent.saveRating(id.value, props.entityId, list).then((res) => {
      loading.value = false; //выключаем крутилку
    });
  });
}

function getAverageRating() {
  // !!! заменил currentValue.currentRating на currentValue.rate что бы не путалось с переменной currentRating
  // и потому что массив votedUsers теперь выглядит так: [{uid:юзер-id, rate:значение выбранное юзером}, ...]
  // console.log('::getAverageRating ',props.entityId, votedUsers.value);
  if(votedUsers.value?.length){ 
    const sum = votedUsers.value.reduce((acc, currentValue) => acc + currentValue?.rate, 0);
    averageRating.value = sum / votedUsers.value.length;
  }else{
    averageRating.value = 0;
  }
}

function clearRating(){
 // eslint-disable-next-line vue/no-mutating-props
  props.parent.do_confirm = ()=>{
    votedUsers.value.splice(0, votedUsers.value.length, []);
    getAverageRating();
    //Сохраним в БД
    props.parent.saveRating(id.value, props.entityId, []).then((res) => {
      loading.value = false; //выключаем крутилку
    });
    // eslint-disable-next-line vue/no-mutating-props
    props.parent.confirm=false;
  }
  // eslint-disable-next-line vue/no-mutating-props
  props.parent.title_confirm =  false;
  // eslint-disable-next-line vue/no-mutating-props
  props.parent.message_confirm = message[props.lang].rating_clear+'?';
  // eslint-disable-next-line vue/no-mutating-props
  props.parent.modal_ok =  message[props.lang].rating_clear;
  // eslint-disable-next-line vue/no-mutating-props
  props.parent.modal_cancel =  false
  // eslint-disable-next-line vue/no-mutating-props
  props.parent.confirm=true;
}

onMounted(() => {
  // getAverageRating();
  // !!! Загрузка из БД данных по конкретному entityId
  // Метод loadRatings находится в App.vue потому что он потребуется в отчетах скорее всего
  props.parent.loadRatings(props.entityId).then((res) => {
    if(res?.data){
      id.value = res.id || false;
      //votedUsers=[{uid:юзер-id, rate:значение выбранное юзером}, ...]
      votedUsers.value.splice(0, 0, ...res.data);
      const my_rating = votedUsers.value.find((el) => el.uid == props.parent.user_current.ID);
      if (my_rating) {
        currentRating.value = my_rating.rate;
      }
      // console.log('::loadRatings votedUsers=',votedUsers.value)
      getAverageRating();
    }
    
  });
});
</script>

<template>
  <div @mouseover="showSecondDiv = true" @mouseleave="showSecondDiv = false">
    
    <!-- При наведении мыши показываем личный рейтинг -->
    <div v-show="showSecondDiv"  id="2" class="row no-wrap items-center">
    <!-- Не все иконки имеют половинки, поэтому два раза -->
      <q-rating v-if="props.icon_half" 
        class="q-mr-sm" 
        v-model="currentRating" 
        :size="props.size" 
        :color="props.color"
        :icon="props.icon" 
        :icon-half="props.icon_half" 
        @update:model-value="updateRating" 
        :max="props.max" />

      <q-rating v-else 
        class="q-mr-sm" 
        v-model="currentRating" 
        :size="props.size" 
        :color="props.color"
        :icon="props.icon" 
        @update:model-value="updateRating" 
        :max="props.max" />

        <q-btn v-if="props.parent.isModer" 
          round flat icon="clear" color="warning"
          size="xs"
          @click="clearRating"
          />

    </div>
    <!-- мышь убрали показываем общий усредненный рейтинг  -->
    <div v-show="!showSecondDiv" id="1" class="row no-wrap items-center">
      <q-rating v-if="props.icon_half" 
          class="q-mr-sm" 
          v-model="averageRating" 
          :size="props.size" 
          :color="props.color"
          :icon="props.icon" 
          :icon-half="props.icon_half" 
          :max="props.max" />

        <q-rating v-else 
          class="q-mr-sm" 
          v-model="averageRating" 
          :size="props.size" 
          :color="props.color"
          :icon="props.icon" 
          @update:model-value="updateRating" 
          :max="props.max" />

    </div>
    
  </div>
</template>

<style></style>
