<template>
  <div>
    <q-card :dark="mode !== 'add'" v-if="mode" class="cat-card">
      <q-card-section class="h4 text-primary">
        <div class="row items-center justify-between">
          <div class="col-2">
            {{ message[lang].message_mode[mode] }}
            <span v-show="messageItem?.ID && parent?.showSysInfo=='Y'">
              {{messageItem?.ID}}
            </span>
          </div>
          <div class="col-4" v-if="messageItem?.DATE_CREATE">
            {{ parent.customFormatter(messageItem.DATE_CREATE)}}
          </div>
          <div class="col-1 h5 text-secondary" v-if="anonim == 3">
            {{ message[lang].message_anon }}
          </div>
          <div class="col-3" v-if="is_likes && mode === 'view'">
            <rating v-if="messageItem?.ID"
              v-bind:parent="parent" 
              v-bind:lang="lang"
              v-bind:entityId="'mes_'+messageItem.ID"
              v-bind:icon="'favorite_outlined'"
              v-bind:icon_half="''"
              v-bind:color="'red-13'"
              v-bind:size="'1em'"

              />
          </div>
          <div class="col-2" v-if="parent.isModer">
            <div class="text-secondaty" v-if="messageItem?.ACTIVE == 'Y'">{{ message[lang].message_published }}</div>
            <div class="text-warning" v-else>{{ message[lang].message_unpublished }}</div>
          </div>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section class="h4 text-accent" v-if="mode === 'add' || mode === 'edit'">
        <div class="row">
          <div class="col-7">
            <q-input class="q-mb-md" outlined v-model="title" :label="message[lang].quiz_title + ' *'" />
            <div>{{ message[lang].quiz_description }}</div>
            <q-editor v-model="description" min-height="5rem" />
          </div>
          <div class="col-5 q-pl-md">
            <!-- Логотип-->
            <div class="row items-center">
              <div class="col-10" v-if="picture">
                <q-img :src="picture" spinner-size="2rem" spinner-color="positive" style="border: #dddddd 1px dashed; border-radius: 15px" />
                <q-btn size="xs" class="absolute all-pointer-events q-ma-xs" round icon="clear" color="positive" @click="picture = null">
                  <q-tooltip class="bg-primary h-tt">
                    {{ message[lang].remove }}
                  </q-tooltip>
                </q-btn>
              </div>
              <div class="col-12" v-else>
                <div class="col-4">
                  <q-uploader
                    :url="`${parent.app_server_url}api/sendimage?client_key=${parent.member_id}&portal=${parent.portal}&access_token=${parent.access_token}`"
                    style="max-width: 300px"
                    @uploaded="onUploaded"
                    :label="message[lang].picture"
                    flat
                    bordered
                    accept="image/jpeg, image/png, .svg"
                    @added="logo_added = true"
                    @removed="logo_added = false"
                  />
                </div>
                <div class="col-12 text-warning text-left q-pt-sm" v-if="logo_added">
                  {{ message[lang].logo_added }}
                </div>
              </div>
            </div>

            

          </div>
        </div>
      </q-card-section>
      <q-card-section v-else class="h4 text-accent q-py-xs">
        <div class="row">
          <div class="col-8 q-pa-sm">
            <div class="h3 text-primary">{{ title }}</div>
            <div class="h4 text-accent" v-html="description" />
          </div>
          <div class="col-4 q-pa-sm" v-if="picture">
            <q-img :src="picture" spinner-size="2rem" spinner-color="positive" style="border-radius: 15px" />
          </div>
        </div>
      </q-card-section>

      <!-- Просмотр результатов комментарии -->
      <q-card-section v-if="mode === 'view' && is_comments" class="h4 text-accent q-py-xs">
        <div class="h5 q-px-lg">
          <Comments v-bind:lang="lang" v-bind:entity-id="messageItem?.ID" v-bind:user_current="parent.user_current" v-bind:parent="parent" v-bind:mode="'list'" v-bind:heght="500" />
        </div>
      </q-card-section>

      <q-card-section v-if="mode === 'add' || mode === 'edit'">
        <div class="row">
              <div class="col-12">
                <div class="column justify-start q-gutter-sm q-mt-xs text-accent">
                  <q-checkbox v-model="is_comments" :label="message[lang].quiz_is_comments" />
                  <q-checkbox v-model="is_likes" :label="message[lang].quiz_is_likes" />
                  <q-checkbox v-model="allaccess" :label="message[lang].message_is_all_access" />
                </div>

                <div v-if="!allaccess" class="row justify-start q-gutter-xs q-mt-xs">
                  <q-btn icon-right="add" flat no-caps rounded color="accent" :label="message[lang].quiz_users_add" @click="usersAdd" />
                  <div v-for="(user, user_index) in users" :key="user.id">
                    <q-chip color="primary" outline removable @remove="users.splice(user_index, 1)" size="sm">
                      {{ user.name }}
                    </q-chip>
                  </div>
                </div>

                <div v-if="!allaccess" class="row justify-start q-gutter-xs q-mt-xs text-accent">
                  <q-btn icon-right="add" flat no-caps rounded color="accent" :label="message[lang].quiz_deps_add" @click="depsAdd" />
                  <div v-for="(dep, dep_index) in departments" :key="dep.value">
                    <q-chip color="secondary" outline removable @remove="dep.splice(dep_index, 1)" size="sm">
                      {{ dep.text }}
                    </q-chip>
                  </div>
                </div>
              </div>
            </div>
      </q-card-section>

      <q-separator />

      <q-card-actions v-if="mode === 'add' || mode === 'edit'">
        <q-btn flat rounded :label="message[lang].quiz_cancel" color="primary" @click="emitCancel" />
        <q-btn :disabled="!isValidSave" flat rounded :label="message[lang].quiz_save" color="primary" @click="emitSave" />
        <q-btn :disabled="!isValidPublish" v-if="messageItem?.ACTIVE === 'Y'" flat rounded :label="message[lang].quiz_unpublish" color="primary" @click="emitPublish(false)" />
        <q-btn :disabled="!isValidPublish" v-else flat rounded :label="message[lang].quiz_publish" color="primary" @click="emitPublish(true)" />
        <q-btn v-if="messageItem?.ID" flat rounded :label="message[lang].message_delete" color="primary" @click="emitDelete" />
      </q-card-actions>
      <q-card-actions v-else-if="mode === 'view' && (parent.isModer || parent.user_current.ID == messageItem?.PREVIEW_TEXT)">
        <!-- eslint-disable-next-line vue/no-mutating-props -->
        <q-btn flat rounded :label="message[lang].message_edit" color="primary" @click="mode = 'edit'" />
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import {ref, reactive, computed, onMounted, onBeforeMount} from 'vue';
import dayjs from 'dayjs';
import {messageObj} from 'src/helpers/message';
import Comments from 'src/Comments.vue';
import rating from "src/components/Rating.vue";


export default {
  name: 'Quiz',
  props: {
    parent: Object,
    lang: String,
    messageItem: Object,
    modeItem: String, // add|edit|view
    index: Number,
  },
  components: {
    Comments, rating
  },
  setup(props, {emit}) {
    const message = messageObj;
    const mode = ref('');
    const title = ref('');
    const description = ref('');
    const users = reactive([]);
    const departments = reactive([]);
    const results = reactive([]);
    const is_multy = ref(false);
    const is_comments = ref(true);
    const is_likes = ref(true);
    const allaccess = ref(true);
    const anonim = ref(1);
    const rating = ref(0);
    const picture = ref(null);
    const logo_added = ref(false);

    const isValidSave = computed(() => {
      return title.value.trim() > '';
    });

    const isValidPublish = computed(() => {
      return isValidSave.value;
    });

    const isValidSend = computed(() => {
      let today = dayjs().format('YYYY-MM-DD');
      if (today < props.messageItem.DATE_ACTIVE_FROM || today > props.messageItem.DATE_ACTIVE_TO) {
        return false;
      }

      return false;
    });

    onBeforeMount(() => {
      mode.value = props.modeItem;
      // console.log('beforeMount mode=', mode.value);
    });

    onMounted(() => {
      if (props.messageItem) {
        title.value = props.parent.restoreQuotes(props.messageItem?.NAME);
        picture.value = props.messageItem?.DETAIL_PICTURE;
        description.value = props.parent.restoreQuotes(props.messageItem?.DETAIL_TEXT);
        let new_users = props.parent.isJSON(props.messageItem.PROPERTY_VALUES.users) ? JSON.parse(props.messageItem.PROPERTY_VALUES.users) : [];
        users.push(...new_users);
        let new_departments = props.parent.isJSON(props.messageItem.PROPERTY_VALUES.deps) ? JSON.parse(props.messageItem.PROPERTY_VALUES.deps) : [];
        departments.push(...new_departments);
        let new_results = props.parent.isJSON(props.messageItem.STUFF) ? JSON.parse(props.messageItem.STUFF) : [];
        results.push(...new_results);

        const my_results = new_results.find((el) => el.uid == props.parent.user_current.ID);
        const my_rating = my_results?.rating || 0;
        rating.value = my_rating;

        allaccess.value = props.messageItem.PROPERTY_VALUES.allaccess === true || props.messageItem.PROPERTY_VALUES.allaccess === 'true' ? true : false;

        const flags = props.messageItem?.PROPERTY_VALUES?.flags ? JSON.parse(props.messageItem?.PROPERTY_VALUES?.flags) : {};

        is_multy.value = flags.is_multy ? true : false;
        is_comments.value = flags.is_comments ? true : false;
        is_likes.value = flags.is_likes ? true : false;
        anonim.value = flags.anonim || 1;


        // console.log(':=======:onMounted title.value=', title.value, props.messageItem);
      }
    });

    const onUploaded = (info) => {
      // console.log(info);
      if (info.xhr && props.parent.isJSON(info.xhr.response)) {
        let res = JSON.parse(info.xhr.response);
        picture.value = res.location;
      }
      logo_added.value = false;
    };


    const save_item = () => {
      // console.log('::save_item allaccess.value= ', is_multy.value, is_comments.value, is_likes.value, allaccess.value);

      const uids = users.map((item) => 'U' + item.id);
      const dids = departments.map((item) => 'D' + item.value);

      const ret = {
        id: props?.messageItem?.ID,
        title: props.parent.replaceQuotes(title.value),
        description: props.parent.replaceTags(description.value),
        users: JSON.stringify(users),
        departments: JSON.stringify(departments),
        is_multy: is_multy.value,
        is_comments: is_comments.value,
        is_likes: is_likes.value,
        allaccess: allaccess.value,
        anonim: anonim.value,
        // startFinish: startFinish.value,
        search: JSON.stringify({uids: uids, dids: dids}),
        index: props.index,
        picture: picture.value,
      };
      // console.log('::save_item = ', ret);
      return ret;
    };

    const usersAdd = () => {
      props.parent.usersAdd(users).then((userlist) => {
        // console.log(':: userlist=', userlist);
        users.splice(0, 0, ...userlist);
      });
    };

    const depsAdd = () => {
      props.parent.getDepartments().then((res) => {
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_list = [];
        for (let i in res) {
          let item = res[i];
          // eslint-disable-next-line vue/no-mutating-props
          props.parent.modal_list.push(item);
        }

        // eslint-disable-next-line vue/no-mutating-props
        props.parent.model = null;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_flt = '';
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_list_single = false;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_list_label = message[props.lang].selectdeps_label;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_title = message[props.lang].selectdep_title;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_message = message[props.lang].selectdep_message;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_ok = message[props.lang].selectusers_add;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_cancel = message[props.lang].selectusers_finish;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_filterFn = null;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.okModal = () => {
          let deps_id = props.parent.modal_list_selected;
          // console.log('::allDeps', deps_id);
          props.parent.getAllSubDeps(props.parent.modal_list, parseInt(props.parent.modal_list_selected), deps_id);
          let deps_full = props.parent.modal_list.filter((item) => deps_id.includes(item.value));

          departments.splice(0, departments.length, ...deps_full);
          // console.log('::departments', departments);

          // eslint-disable-next-line vue/no-mutating-props
          props.parent.hideModal();
        };
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.showModal();
      });
    };

    const emitCancel = () => {
      mode.value = props.modeItem;
      emit('message-cancel');
    };

    const emitSave = () => {
      let saveItem = save_item();
      mode.value = props.modeItem;
      saveItem.publish = props.messageItem?.ID && props.messageItem?.ACTIVE ? props.messageItem.ACTIVE : 'N';
      // console.log('::emitSave saveItem=', saveItem);
      emit('message-save', saveItem);
    };

    const emitPublish = (isPublish) => {
      let saveItem = save_item();
      saveItem.publish = isPublish ? 'Y' : 'N';
      mode.value = props.modeItem;
      emit('message-save', saveItem);
    };

    const emitDelete = () => {
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.do_confirm = () => {
        mode.value = props.modeItem;
        emit('message-delete', save_item());
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.confirm = false;
      };
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.title_confirm = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.message_confirm = message[props.lang].message_delete + '?';
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.modal_ok = message[props.lang].message_delete;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.modal_cancel = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.confirm = true;
    };


    return {
      mode,
      message,
      results,
      title,
      description,
      emitCancel,
      emitSave,
      emitPublish,
      emitDelete,
      usersAdd,
      users,
      departments,
      depsAdd,
      is_multy,
      is_comments,
      is_likes,
      allaccess,
      anonim,
      rating,
      isValidPublish,
      isValidSave,
      isValidSend,
      logo_added,
      picture,
      onUploaded,
    };
  },
};
</script>
