<template>
  <span>
<!--  <q-spinner-oval v-if="isLoading || this_parent.file_download_loading"  color="positive" size="30px"/>-->
  <q-btn v-if="id"
         flat
         round
         color="positive"
         :icon="isClone?'file_copy':'file_download'"
         @click="exportLesson"
         :loading="isLoading"
    >
    <q-tooltip class="bg-primary h-tt">{{isClone?message[lang].clone:message[lang].export}}</q-tooltip>
  </q-btn>
    <q-spinner v-else-if="list && isLoading"
      color="positive"
     class="q-mt-md"
      size="2em"
    />
  <q-img  v-else-if="list" style="cursor: pointer; height: 30px; width: 30px; margin-top:16px" @click="exportListLessons" src="~assets/secondary/doc/export.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].export}}</q-tooltip></q-img>
</span>
</template>
<script>

    export default {
        props:{
          lang:String,
          id:String,
          list:Object,
          parent:Object,
          isClone:Boolean
        },
        data: function () {
            return {
                isLoading:false,
                files:[],
                lesson:'',
                jsonList:'',
                message:{
                    ru:{
                      export:"Экспорт",
                      clone:"Создать копию",
                    },
                    en:{
                        export:"Export",
                      clone: "Create a copy",
                    },
                  ua:{
                    export:"Експорт",
                    clone: "створити копію",
                  },
                  fr:{
                    export:"Export",
                    clone: "Créer une copie",
                  },
                  it:{
                    export:"Esporta",
                    clone: "crea una copia",
                  },
                  tr:{
                    export:"İhracat",
                    clone:"Kopya oluştur",
                  },
                  de:{
                    export:"Export",
                    clone:"Kopie erstellen",
                  },
                  pl:{
                    export:"Eksport",
                    clone: "Utwórz kopię",
                  },
                  pt:{
                    export:"Exportar",
                    clone: "criar uma cópia",
                  },
                  es:{
                    export:"Exportar",
                    clone: "Crear una copia",
                  },
                  vn:{
                    export:"Xuất khẩu",
                    clone: "Tạo một bản sao",
                  },
                },
            }
        },
        mounted: function(){
          // console.log(':: mounted list=',this.list)

        },
        methods: {
            exportListLessons(){
              // console.log(':!!!:exportLesson ', this.id, this.list);
                this.isLoading = true;
                this.jsonList=[];
                this.exportLesson();
            },
            exportLesson() {
                let $this = this;
                // console.log('::exportLesson ', this.id, this.list);
                if(this.id) {
                    this.isLoading = true;
                }
                let lesson_id = this.id ? parseInt(this.id) : 0;
                if (this.list && Object.keys(this.list).length > 0) {
                  // eslint-disable-next-line vue/no-mutating-props
                    let item = this.list.pop();
                    lesson_id = parseInt(item.ID);
                }
                //
                if(lesson_id){
                    this.parent.getLessonById(lesson_id).then(lesson => {
                        // console.log('::lesson=', lesson);

                        //Устарело я тех пор как в файлах хранятся только ссылки
                        //проверим на наличие файлов в ответах
                        // let files_id = [];
                        // let files = [];
                        // if (lesson.PROPERTY_VALUES.files) {
                        //     let files = $this.parent.isJSON(lesson.PROPERTY_VALUES.files)? JSON.parse(lesson.PROPERTY_VALUES.files) : lesson.PROPERTY_VALUES.files;
                        //     // console.log(':: files=', files);
                        //     for (let i in files) {
                        //         let file = files[i];
                        //         if (file.I) {
                        //             files_id.push(file.I);
                        //         }
                        //     }
                        // }

                        // if (Object.keys(files_id).length > 0) {
                        //     this.loadFiles(lesson_id, this.id, files_id, lesson, files);
                        // } else {
                            let json = JSON.stringify(lesson);

                            if(this.id) {
                              if(this.isClone){
                                this.$emit('cloneExpLesson', {id: lesson_id, data: json});
                              }else {
                                this.$emit('saveExpLesson', {id: lesson_id, data: json});
                              }
                              this.isLoading = false;
                            }else{
                                this.jsonList.push({id: lesson_id, data: json});
                                this.exportLesson();
                            }
                        // }
                    }, err => {
                        console.error(':: err=', err);
                    });
                }else{
                    this.isLoading = false;
                    // console.log(':***: saveExpList jsonList=',this.jsonList)
                    this.$emit('saveExpList', this.jsonList);
                }
            },
            loadFiles(lesson_id, isLoadingToFalse, files_id, lesson, files){
                // console.log('::loadFiles lesson_id=',lesson_id);
                let $this = this;
                if (Object.keys(files_id).length > 0) {
                    let file_id = files_id.pop();
                    $this.parent.parent.loadFile(file_id).then(file=>{
                        // console.log(':: lesson_id=%s file_id=%s', lesson_id, file_id);
                      if(file) {
                        files.push({ID: file.ID, NAME: file.NAME, DETAIL_TEXT: file.DETAIL_TEXT});
                      }
                      $this.loadFiles(lesson_id, isLoadingToFalse, files_id, lesson, files);
                    }, err=>console.error(err));
                } else {
                    lesson.files=files;
                    let json=JSON.stringify(lesson);
                    // console.log(':end: files loaded lesson_id=', lesson_id);
                    if(isLoadingToFalse) {
                      if(this.isClone){
                        this.$emit('cloneExpLesson', {id: lesson_id, data: json});
                      }else {
                        this.$emit('saveExpLesson', {id: lesson_id, data: json});
                      }
                        this.isLoading = false;
                    }else{
                        this.jsonList.push({id: lesson_id, data: json});
                        this.exportLesson();
                    }
                }
            },


        },
        components: {

        }
    }
</script>

<style scoped>


</style>

