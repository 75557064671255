<template>
  <div id="block" class="q-pa-md q-gutter-y-lg">
    <div class="row items-center">
      <div class="col-12 text-positive h4">{{message[lang].profiles_lb}}</div>
      <div class="col-8" >
        <q-select
          class="body-0 bg-negative"
          borderless
          style="padding-left:15px; border-radius: 25px !important;"
          v-model="profile"
          :options="profiles"
          option-label="name"
          option-value="id"
          map-options
          :label="message[lang].profiles_plc"
          :loading="loading_profiles"
          :disable="!profiles || profiles.length==0"
        />
      </div>
      <div class="col-4 q-pl-md">
        <q-btn  :loading="saving_profiles" icon="save" color="primary" no-caps :disable="!profile" flat rounded :label="message[lang].add_cat" @click="setProfiles" />
      </div>
    </div>

    <div class="row items-center">
      <div class="col-12 text-positive h4">{{message[lang].add_from_comps}}</div>
      <div class="col-8" >
        <q-select
          class="body-0 bg-negative"
          borderless
          style="padding-left:15px; border-radius: 25px !important;"
          v-model="competence"
          :options="comps_options"
          option-label="name"
          map-options
          :label="message[lang].comps_plc"
          :hint="message[lang].comps_asterisk"
          @update:model-value="setIndicators"
          :loading="loading_comps"
          :disable="!comps_options || comps_options.length==0"
        />
      </div>
      <div class="col-4 q-pl-md">
        <q-select
          class="body-0 bg-negative"
          borderless
          style="padding-left:15px; border-radius: 25px !important;"
          v-model="scale"
          :options="scores_options"
          option-label="name"
          map-options
          :label="message[lang].scale_plc"
          @update:model-value="setScale"
          :loading="loading_scales"
          :disable="!scores_options || scores_options.length==0"
        />
      </div>
    </div>

<!--    <q-splitter inset/>-->

    <div class="row items-center">
      <div class="col-12 text-positive h4">{{message[lang].comp_etence}}</div>
      <div class="col-12 h5 cursor-pointer">
        <span v-if="mCompetence">{{mCompetence}}</span>
        <span v-else>{{message[lang].click_for_enter}}</span>
        <q-icon name="edit" color="positive"/>
        <q-popup-edit
          v-model="mCompetence"
          auto-save
          v-slot="scope"
          buttons
          :label-set="message[lang].save"
          :label-cancel="message[lang].cancel"
        >
          <q-input
            v-model="scope.value" dense autofocus @keyup.enter="scope.set"
            :rules="[ val => val.length > 0 || message[lang].requaried_field]"
          />
        </q-popup-edit>
      </div>
    </div>

    <div class="q-pa-lg">
      <q-separator inset/>
    </div>

    <div class="row items-center" v-if="isShowCats">
      <div class="col-6">
        <div class="row">
          <div class="col-12 text-positive h4">{{message[lang].indi_cators}}</div>
          <div class="col-6 text-warning">{{cat_message}}</div>
        </div>
      </div>
      <div class="col-6 q-gutter-x-xs">
        <div class="row">
          <div class="col-4 text-positive h4">
            {{message[lang].subcategories}}
          </div>
          <div class="col-4">
            <div class="q-gutter-xs">
              <q-btn  flat size="sm" round color="positive" icon="content_copy" @click="copySubcats"><q-tooltip class="bg-primary h-tt">{{message[lang].copy}}</q-tooltip></q-btn>
              <q-btn  flat size="sm" round color="positive" icon="content_paste" @click="pasteSubcats"><q-tooltip class="bg-primary h-tt">{{message[lang].paste}}</q-tooltip></q-btn>
              <q-btn  flat size="sm" round color="positive" icon="undo" @click="undoSubcats"><q-tooltip class="bg-primary h-tt">{{message[lang].undo}}</q-tooltip></q-btn>
            </div>
          </div>
          <div class="col-4 text-warning">
            {{subcat_message}}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Компетенции -->
      <div class="col-6 q-gutter-y-md q-pr-xs" >
        <div>
          <q-input
            :label="message[lang].cat_placeholder"
            v-model="cat_input"
            borderless
            style="padding-left:10px; border-radius: 25px !important;"
            class="body-0 bg-negative"
            :rules="[ val => val.length > 0 || parent.message[lang].requaried_field]"
          />
        </div>
        <div class="text-right">
          <q-btn :disable="!cat_input" flat color="primary" :label="message[lang].add_cat" icon="add" @click="addCat"/>
          <q-btn :disable="!cat_input" flat color="primary" :label="message[lang].edit_cat" icon="save" @click="editCat"/>
        </div>
        <q-card class="cat-card bg-negative">
                <q-card-section style="max-height: 50vh" class="scroll">
                  <div class="row body-0 q-py-md items-center" v-for="(item,index) in mCategories" :key="item.value"
                       :style="cat_selected==item.value?{'background-color': '#4DD2BC', 'cursor': 'pointer', 'border-bottom': '#eeeeee solid 1px'}:{'cursor': 'pointer', 'border-bottom': '#eeeeee solid 1px'}">
                      <div class="col-1">
                        <q-btn size="sm" flat rounded color="positive" icon="keyboard_arrow_up" @click="moveUpCat(item,index)"/>
                        <br>
                        <q-btn size="sm" flat rounded color="positive" icon="keyboard_arrow_down" @click="moveDownCat(item,index)"/>
                      </div>
                      <div class="col-9 text-left" @click="clickCat(item,index)">
                        {{item.text}}
                      </div>
                      <div class="col-2 text-right">
                        <q-btn size="sm" flat rounded color="warning" icon="clear" @click="remCat(item,index)"><q-tooltip class="bg-primary h-tt">{{message[lang].del_cat}}</q-tooltip></q-btn>
                        <q-btn size="sm" flat rounded color="primary" icon="drive_file_rename_outline" @click="edCat(item,index)"><q-tooltip class="bg-primary h-tt">{{message[lang].edit_cat}}</q-tooltip></q-btn>
                      </div>
                  </div>
                </q-card-section>
              </q-card>

      </div>
      <!-- Оценки -->
      <div class="col-6 q-gutter-y-md q-pl-xs" >

        <div>
          <q-input
            :label="message[lang].subcat_placeholder"
            v-model="subcat_input"
            borderless
            style="padding-left:10px; border-radius: 25px !important;"
            class="body-0 bg-negative"
            :disable="!cat_selected"
            :rules="[ val => val.length > 0 || parent.message[lang].requaried_field]"
          />
        </div>
        <div class="text-right">
          <q-btn :disable="!subcat_input" flat color="primary" :label="message[lang].add_cat" icon="add" @click="addSubCat"/>
          <q-btn :disable="!subcat_input" flat color="primary" :label="message[lang].edit_cat" icon="save" @click="editSubCat"/>
        </div>
        <q-card class="cat-card bg-negative">
          <q-card-section style="max-height: 50vh" class="scroll">
            <div class="row body-0 q-py-md items-center" v-for="(item,index) in mSubcategories[cat_selected]" :key="item.value"
                 :style="subcat_selected==item.value?{'background-color': '#4DD2BC', 'cursor': 'pointer', 'border-bottom': '#eeeeee solid 1px'}:{'cursor': 'pointer', 'border-bottom': '#eeeeee solid 1px'}">
              <div class="col-1">
                <q-btn size="sm" flat rounded color="positive" icon="keyboard_arrow_up" @click="moveUpSubCat(item,index)"/>
                <br>
                <q-btn size="sm" flat rounded color="positive" icon="keyboard_arrow_down" @click="moveDownSubCat(item,index)"/>
              </div>
              <div class="col-9 text-left" @click="clickSubCat(item,index)">
                {{item.text}}
              </div>
              <div class="col-2 text-right">
                <q-btn size="sm" flat rounded color="warning" icon="clear" @click="remSubCat(item,index)"><q-tooltip class="bg-primary h-tt">{{message[lang].del_cat}}</q-tooltip></q-btn>
                <q-btn size="sm" flat rounded color="primary" icon="drive_file_rename_outline" @click="edSubCat(item,index)"><q-tooltip class="bg-primary h-tt">{{message[lang].edit_cat}}</q-tooltip></q-btn>
              </div>
            </div>
          </q-card-section>
        </q-card>

      </div>
    </div>

    <div class="row justify-between">
      <div class="col-6">
        <div v-if="thisparent.isCompetenceItemValid({cats:this.mCategories, subcats:this.mSubcategories}) === 1 ">
          <q-btn rounded flat v-if="showSave" color="primary" :label="message[lang].collapse" icon="expand_less" @click="showSave=!showSave"/>
          <q-btn rounded flat v-else color="primary" :label="message[lang].showsave" icon="expand_more" @click="showSave=!showSave"/>
          <div class="q-gutter-y-sm" v-if="showSave">
            <q-input
              :label="message[lang].plc1"
              v-model="preset_name"
              borderless
              style="padding-left:10px; padding-right:5px; border-radius: 25px !important;"
              class="body-0 bg-negative"
            >
              <template v-slot:append>
                <q-btn round dense flat icon="save" color="primary" :disable="!statePresets" @click="savePreset"/>
              </template>
            </q-input>
            <div class="row items-center q-pa-sm" v-for="(item, index) in presets" :key="item.name" align-v="center" style="border-bottom: #eee solid 0.5px;">
              <div class="col-9 offset-1">
                {{item.name}}
              </div>
              <div class="col-2">
                <q-btn size="sm" flat round color="warning" icon="clear" @click="delPreset(item,index)"><q-tooltip class="bg-primary h-tt">{{message[lang].del_cat}}</q-tooltip></q-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 text-right q-gutter-xs">
        <q-btn
          :disable="!mCompetence || !scale"
          color="primary"
          rounded
          :label="mId?message[lang].save:message[lang].add_cat"
          icon="save"
          @click="cats_saved(false)"
        />
        <q-btn v-if="mId"
          :disable="!mCompetence || !scale"
          color="positive"
          rounded
          :label="message[lang].add_cat"
          icon="save"
          @click="cats_saved(true)"
        />
        <q-btn
               color="positive"
               round
               icon="clear"
               @click="resetCat"
        >
          <q-tooltip class="bg-primary h-tt">{{message[lang].reset_cat}}</q-tooltip>
        </q-btn>

      </div>
    </div>

  </div>
</template>
<script>
import { ref } from 'vue';
    export default {
        props:{
            title:String,
            title_cat:String,
            title_subcat:String,
            pCompetence:String,
            lang:String,
            parent:Object,
            thisparent:Object
        },
        data: function () {
            return {
                saving_profiles:false,
                loading_scales:false,
                loading_profiles:false,
                loading_comps:false,
                profiles:null,
                profile:null,
                isShowCats:true,
                presets_options:'',
                preset_selected_model:null,
                preset_selected:{cats:'', subcats:''},
                stateMessage:'',
                presets:'',
                preset_name:'',
                showSave:false,
                mSubcategories:{},
                mCategories:[],
                mCompetence:'',
                mId:'',
                isCatOrSubcat:'cat',
                cat_selected:ref(''),
                cat_index:'',
                cat_input:'',
                cat_message:'',
                subcat_selected:ref(''),
                subcat_index:'',
                subcat_input:'',
                subcat_message:'',
                edit_cat:'',
                edit_subcat:'',
                isShowInputCat:false,
                isShowInputSubCat:false,
                isShowSubcats:false,
                departments:null,
                users:null,
                input_user:'',
                dep_selected:0,
                users_selected_list:[],
                users_selected:[],
                approoverMode:false,
                comps_options:[],
                scores_options:[],
                competence:'',
                scale:'',
                message:{
                  ru:{
                    profiles_lb:"Профили",
                    profiles_plc:"Профиль содержит несколько компетенций",

                    reset_cat:"Очистить форму",
                    add_from_comps:"Выбрать компетенцию",
                    comp_etence:"Добавьте компетенцию вручную",
                    comps_plc:"Выберите компетенцию",
                    scale_plc:"Выбрать шкалу",
                    indi_cators:"Индикаторы",
                    requaried_field:"Требуется название",
                    click_for_enter:"Кликни для редактирования",
                    comps_asterisk:"Звездочкой отмечены компетенции сохраненные ранее в библиотеку, без звездочки - утвержденные компетенции",

                    del_preset_title:"Удалить набор компетенций?",
                    del_preset_mess:"Из библиотеки будет удален набор компетенций. Это ни как не повлияет на ранее созданные оценки.",
                        categories:"Компетенции",
                        cat_placeholder:"Введите название индикатора",
                        subcat_placeholder:"Введите название оценки",
                        cat_message_empty:"Введите не пустую компетенцию",
                        cat_message_double:"Такая компетенция уже есть",
                        subcat_message_empty:"Введите не пустую оценку",
                        subcat_message_double:"Такая оценка уже есть",
                        subcategories:"Оценки",
                        add_cat:"Добавить",
                        del_cat:"Удалить",
                        edit_cat:"Изменить",
                        del_cat_title:"Удалить компетенцию",
                        del_cat_mess:"Если компетенция будет удалена, она останется в Оценках, в которых была установлена.",
                        del_subcat_title:"Удалить оценку",
                        del_subcat_mess:"Если оценка будет удалена, она останется в Оценках, в которых была установлена.",
                        cancel:"Отмена",
                        select:"Выбрать",
                        save:"Записать",
                        showsave:"Сохранить в библиотеку",
                        plc1:"Имя набора",
                        preset_name:"Сохраните этот набор компетенций в библиотеку",
                        preset_name_is_allready:"Такое имя набора уже есть",
                        preset_name_is_empty:"Введите имя длиной больше трех символов",
                        collapse:"Свернуть",
                        add_from_preset:"Добавить компетенции из библиотеки",
                        choose_preset:"-- Выбрать набор --",
                        copy:"Скопировать все оценки в буфер",
                        paste:"Вставить/заменить все оценки",
                        undo:"Отменить вставленные оценки",
                        preset_plc:"Используйте библиотеку сохраненных ранее наборов компетенций",
                    },
                  en:{
                    reset_cat:"Clear form",
                    add_from_comps: "Select Competency",
                    comp_etence:"Add skill manually",
                    comps_plc:"Select a Skill",
                    scale_plc:"Select Scale",
                    indi_cators:"Indicators",
                    requaried_field: "Title required",
                    click_for_enter:"Click to edit",
                    comps_asterisk: "Asterisk indicates competencies previously saved to the library, no asterisk denotes approved competencies",

                    del_preset_title:"Delete a set of competencies?",
                    del_preset_mess:"A set of competencies will be deleted from the library. This will not affect the previously created estimates in any way.",
                        categories:"Competencies",
                        cat_placeholder:"Enter the name of the indicator",
                        subcat_placeholder:"Enter the name of the assessment",
                        cat_message_empty:"Enter non-empty competence",
                        cat_message_double:"This competence already exists",
                        subcat_message_empty:"Enter non-empty 180/360-degree feedback",
                        subcat_message_double:"This 180/360-degree feedback already exists",
                        subcategories:"180/360-degree feedback",
                        add_cat:"Add",
                        del_cat:"Delete",
                        edit_cat:"Edit",
                        del_cat_title:"Delete competence",
                        del_cat_mess:"If a competence is deleted, it remains on 180/360-degree feedback in which it was set.",
                        del_subcat_title:"Delete a score",
                        del_subcat_mess:"If a score is deleted, it remains on 180/360-degree feedback in which it was set.",
                        cancel:"Cancel",
                        select:"Select",
                        save:"Save",
                        showsave:"Save to library",
                        plc1:"Set name",
                        preset_name:"Save this set of competencies to library",
                        preset_name_is_allready:"This set name already exists",
                        preset_name_is_empty:"Enter a name at least 3-symbol-long",
                        collapse:"Collapse",
                        add_from_preset:"Add competencies from library",
                        choose_preset:"—Select a set --",
                        copy:"Copy all 180/360-degree feedback to clipboard",
                        paste:"Paste/replace all 180/360-degree feedback",
                        undo:"Undo pasted 180/360-degree feedback",
                        preset_plc:"Use library for previously saved competency sets",
                    },
                  ua:{
                    reset_cat:"Очистити форму",
                    add_from_comps:"Вибрати компетенцію",
                    comp_etence:"Додайте компетенцію вручну",
                    comps_plc:"Виберіть компетенцію",
                    scale_plc:"Вибрати шкалу",
                    indi_cators:"Індикатори",
                    requaried_field:"Потрібна назва",
                    click_for_enter:"Клікні для редагування",
                    comps_asterisk:"Зірочкою відзначені компетенції збережені раніше в бібліотеку, без зірочки - затверджені компетенції",

                    del_preset_title: "видалити набір компетенцій?",
                    del_preset_mess: "з бібліотеки буде видалено набір компетенцій. Це ніяк не вплине на раніше створені оцінки.",categories:"Компетенції",
                    cat_placeholder:"Введіть назву індикатора",
                    subcat_placeholder:"Введіть назву оцінки",
                    cat_message_empty:"Введіть не пусту компетенцію",
                    cat_message_double:"Така компетенція вже є",
                    subcat_message_empty:"Введіть не пусту оцінку",
                    subcat_message_double:"Така оцінка вже є",
                    subcategories:"Оцінки",
                    add_cat:"Додати",
                    del_cat:"Видалити",
                    edit_cat:"Змінити",
                    del_cat_title:"Видалити компетенцію",
                    del_cat_mess:"Якщо компетенція буде видалена, вона залишиться в Оцінках, в яких була встановлена.",
                    del_subcat_title:"Видалити оцінку",
                    del_subcat_mess:"Якщо оцінка буде видалена, вона залишиться в Оцінках, в яких була встановлена.",
                    cancel:"Відміна",
                    select:"Вибрати",
                    save:"Записати",
                    showsave:"Зберегти в бібліотеку",
                    plc1:"Ім'я набору",
                    preset_name:"Збережіть цей набір компетенцій в бібліотеку",
                    preset_name_is_allready:"Таке ім'я набору вже є",
                    preset_name_is_empty:"Введіть ім'я довжиною більше трьох символів",
                    collapse:"Згорнути",
                    add_from_preset:"Додати компетенції з бібліотеки",
                    choose_preset:"-- Вибрати набір --",
                    copy:"Скопіювати всі оцінки в буфер",
                    paste:"Вставити/замінити всі оцінки",
                    undo:"Скасувати вставлені оцінки",
                    preset_plc:"Використайте бібліотеку збережених раніше наборів компетенцій",
                  },
                  fr:{
                    reset_cat:"Effacer le formulaire",
                    add_from_comps: "Sélectionner une compétence",
                    comp_etence:"Ajouter une compétence manuellement",
                    comps_plc:"Sélectionnez une compétence",
                    scale_plc:"Sélectionner l'échelle",
                    indi_cators:"Indicateurs",
                    requaried_field: "Titre requis",
                    click_for_enter:"Cliquez pour éditer",
                    comps_asterisk: "L'astérisque indique les compétences précédemment enregistrées dans la bibliothèque, aucun astérisque ne désigne les compétences approuvées",
                    del_preset_title: "Supprimer un ensemble de compétences?",
                    del_preset_mess: "l'ensemble de compétences sera supprimé de la bibliothèque. Cela n'affectera pas les estimations précédemment créées.",
                    categories:"Compétences",
                    cat_placeholder:"Entrez le nom de l'indicateur",
                    subcat_placeholder:"Entrez le nom de l'évaluation",
                    cat_message_empty:"Ne pas laisser ce champ vide",
                    cat_message_double:"Cette compétence existe déjà",
                    subcat_message_empty:" Ne pas laisser ce champ vide ",
                    subcat_message_double:"Cette évaluation 180/360 existe déjà",
                    subcategories:"Évaluation 180/360",
                    add_cat:"Ajouter",
                    del_cat:"Supprimer",
                    edit_cat:"Éditer",
                    del_cat_title:"Supprimer cette  compétence",
                    del_cat_mess:"Si une compétence est supprimée, elle demeure accessible depuis l’Évaluation 180/360 associée",
                    del_subcat_title:"Supprimer l’évaluation",
                    del_subcat_mess:" Si une évaluation est supprimée, elle demeure accessible depuis l’Évaluation 180/360 associée.",
                    cancel:"Supprimer",
                    select:"Sélectionner",
                    save:"Enregistrer",
                    showsave:"Enregistrer dans la bibliothèque",
                    plc1:"Indiquer un nom",
                    preset_name:"Enregistrer cette série de compétences dans la librairie.",
                    preset_name_is_allready:"Cet intitulé de série compétences existe déjà.",
                    preset_name_is_empty:"Entrer un nom d’au moins 3 caractères.",
                    collapse:"Replier",
                    add_from_preset:"Ajouter des compétences de la bibliothèque ",
                    choose_preset:"—Choisir une série --",
                    copy:"Copier toutes les évaluations 180/360 to clipboard",
                    paste:"Copier/coller vos évaluations 180/360",
                    undo:"Annuler la copie de vos évaluation 180/360",
                    preset_plc:"Utiliser la bibliothèque pour les séries de compétences enregistrées",
                  },
                  it:{
                    reset_cat:"Cancella modulo",
                    add_from_comps: "Seleziona competenza",
                    comp_etence:"Aggiungi abilità manualmente",
                    comps_plc:"Seleziona un'abilità",
                    scale_plc:"Seleziona scala",
                    indi_cators:"Indicatori",
                    requaried_field: "Titolo richiesto",
                    click_for_enter:"Fai clic per modificare",
                    comps_asterisk: "L'asterisco indica le competenze precedentemente salvate nella libreria, nessun asterisco indica le competenze approvate",
                    del_preset_title: "Elimina un insieme di competenze?",
                    del_preset_mess: "Il set di competenze verrà rimosso dalla libreria. Ciò non influirà in alcun modo sulle stime create in precedenza.",
                    categories:"Competenze",
                    cat_placeholder:"Immettere il nome dell'indicatore",
                    subcat_placeholder:"Inserisci il nome della valutazione",
                    cat_message_empty:"Inserisci una competenza non vuota",
                    cat_message_double:"Questa competenza esiste già",
                    subcat_message_empty:"Inserisci un feedback a 180/360 gradi non vuoto",
                    subcat_message_double:"Questo feedback a 180/360 gradi esiste già",
                    subcategories:"Feedback a 180/360 gradi",
                    add_cat:"Aggiungi",
                    del_cat:"Cancella",
                    edit_cat:"Modifica",
                    del_cat_title:"Cancella compentenza",
                    del_cat_mess:"Se una competenza è eliminata, rimane sul feedback a 180/360 gradi in cui era impostata.",
                    del_subcat_title:"Cancella un punteggio",
                    del_subcat_mess:"Se un punteggio è eliminato, rimane sul feedback a 180/360 gradi in cui era impostato.",
                    cancel:"Cancella",
                    select:"Seleziona",
                    save:"Salva",
                    showsave:"Salva in libreria",
                    plc1:"Imposta nome",
                    preset_name:"Salva questo set di competenze in libreria",
                    preset_name_is_allready:"Questo nome di set esiste già",
                    preset_name_is_empty:"Inserisci un nome lungo almeno 3 caratteri",
                    collapse:"Collassa",
                    add_from_preset:"Aggiungi competenze dalla libreria",
                    choose_preset:"—Seleziona un set --",
                    copy:"Copia tutti i feedback a 180/360 gradi nella clipboard",
                    paste:"Incolla/sostituisci tutti i feedback a 180/360 gradi",
                    undo:"Annulla incolla feedback a 180/360 gradi ",
                    preset_plc:"Usa la libreria per set di competenza salvati in precedenza",
                  },
                  tr:{
                    reset_cat:"Formu temizle",
                    add_from_comps: "Yeterlilik Seç",
                    comp_etence:"Beceriyi manuel olarak ekle",
                    comps_plc:"Bir Beceri Seçin",
                    scale_plc:"Ölçek seçin",
                    indi_cators:"Göstergeler",
                    requared_field: "Başlık gerekli",
                    click_for_enter:"Düzenlemek için tıklayın",
                    comps_asterisk: "Yıldız işareti daha önce kitaplığa kaydedilmiş yetkinlikleri gösterir, yıldız işareti olmaması onaylanmış yetkinlikleri gösterir",
                    del_preset_title:"Yetkinlik kümesini kaldırmak mı?",
                    del_preset_mess:"Kitaplıktan yetkinlik seti kaldırılacak. Bu, önceden oluşturulan tahminleri hiçbir şekilde etkilemez.",
                    categories:"Yeterlilikleri",
                    cat_placeholder:"Göstergenin adını girin",
                    subcat_placeholder:"Değerlendirmenin adını girin",
                    cat_message_empty:"Boş olmayan yetkinliği girin",
                    cat_message_double:"Bu yeterlilik zaten var",
                    subcat_message_empty:"Boş olmayan 180/360 derecelik geri bildirim girin",
                    subcat_message_double:"Bu 180/360 derecelik geri bildirim zaten var",
                    subcategories:"180/360 derece geri bildirim",
                    add_cat:"Ekle",
                    del_cat:"Silmek",
                    edit_cat:"Düzenlemek",
                    del_cat_title:"Yetkiyi sil",
                    del_cat_mess:"Bir yeterlilik silinirse, ayarlandığı 180/360 derecelik geri bildirimde kalır.",
                    del_subcat_title:"Bir puanı sil",
                    del_subcat_mess:"Bir puan silinirse, ayarlandığı 180/360 derecelik geri bildirimde kalır.",
                    cancel:"İptal",
                    select:"Seçme",
                    save:"Kaydetmek",
                    showsave:"Kitaplığa kaydet",
                    plc1:"Adı ayarla",
                    preset_name:"Bu yetkinlik kümesini kitaplığa kaydedin",
                    preset_name_is_allready:"Bu küme adı zaten var",
                    preset_name_is_empty:"En az 3 sembol uzunluğunda bir ad girin",
                    collapse:"Yıkılmak",
                    add_from_preset:"Kitaplıktan yetkinlikler ekleyin",
                    choose_preset:"— Bir set seçin --",
                    copy:"Tüm 180/360 derecelik geri bildirimleri panoya kopyalayın",
                    paste:"180/360 derecelik geri bildirimlerin tümünü yapıştırın/değiştirin",
                    undo:"Yapıştırılan 180/360 derecelik geri bildirimi geri al",
                    preset_plc:"Önceden kaydedilmiş yetkinlik setleri için kitaplığı kullanın",
                  },
                  de:{
                    reset_cat:"Formular löschen",
                    add_from_comps: "Kompetenz auswählen",
                    comp_etence:"Skill manuell hinzufügen",
                    comps_plc:"Wählen Sie eine Fähigkeit aus",
                    scale_plc:"Waage auswählen",
                    indi_cators:"Indikatoren",
                    requaried_field: "Titel erforderlich",
                    click_for_enter:"Zum Bearbeiten klicken",
                    comps_asterisk: "Sternchen zeigt Kompetenzen an, die zuvor in der Bibliothek gespeichert wurden, kein Sternchen zeigt genehmigte Kompetenzen an",
                    del_preset_title:"Möchten Sie den Kompetenzsatz löschen?",
                    del_preset_mess:"Der Kompetenzsatz wird aus der Bibliothek entfernt. Dies hat keinen Einfluss auf die zuvor erstellten Schätzungen.",
                    categories:"Kompetenzen",
                    cat_placeholder:"Geben Sie den Namen des Indikators ein",
                    subcat_placeholder:"Geben Sie einen Namen für die Bewertung ein",
                    cat_message_empty:"Geben Sie eine nicht leere Kompetenz ein",
                    cat_message_double:"Diese Kompetenz ist bereits vorhanden",
                    subcat_message_empty:"Geben Sie nicht leeres 180/360-Grad-Feedback ein",
                    subcat_message_double:"Dieses 180/360-Grad-Feedback gibt es bereits",
                    subcategories:"180/360-Grad-Feedback",
                    add_cat:"Hinzufügen",
                    del_cat:"Löschen",
                    edit_cat:"Bearbeiten",
                    del_cat_title:"Kompetenz löschen",
                    del_cat_mess:"Wenn eine Kompetenz gelöscht wird, bleibt sie auf dem 180/360-Grad-Feedback, in dem sie festgelegt wurde.",
                    del_subcat_title:"Punktzahl löschen",
                    del_subcat_mess:"Wenn eine Punktzahl gelöscht wird, bleibt sie auf dem 180/360-Grad-Feedback, in dem sie festgelegt wurde.",
                    cancel:"Abbrechen",
                    select:"Auswählen",
                    save:"Speichern",
                    showsave:"In Bibliothek speichern",
                    plc1:"Name einsetzen",
                    preset_name:"Speichern Sie diese Sammlung von Kompetenzen in der Bibliothek",
                    preset_name_is_allready:"Dieser Setname existiert bereits",
                    preset_name_is_empty:"Geben Sie einen Namen ein, der mindestens 3 Zeichen lang ist",
                    collapse:"Zusammenbruch",
                    add_from_preset:"Kompetenzen aus der Bibliothek hinzufügen",
                    choose_preset:"— Wähle ein Set --",
                    copy:"Kopieren Sie alle 180/360-Grad-Feedbacks in die Zwischenablage",
                    paste:"Alle 180/360-Grad-Feedbacks einfügen/ersetzen",
                    undo:"Eingefügtes 180/360-Grad-Feedback rückgängig machen",
                    preset_plc:"Bibliothek für zuvor gespeicherte Kompetenzsätze verwenden",
                  },
                  pl:{
                    reset_cat:"Wyczyść formularz",
                    add_from_comps: "Wybierz kompetencję",
                    comp_etence:"Dodaj umiejętność ręcznie",
                    comps_plc:"Wybierz umiejętność",
                    scale_plc:"Wybierz skalę",
                    indi_cators:"Wskaźniki",
                    requared_field: "Wymagany tytuł",
                    click_for_enter:"Kliknij, aby edytować",
                    comps_asterisk: "Gwiazdka wskazuje kompetencje wcześniej zapisane w bibliotece, brak gwiazdki oznacza kompetencje zatwierdzone",
                    del_preset_title: "Usunąć zestaw kompetencji?",
                    del_preset_mess: "Zestaw kompetencji zostanie usunięty z biblioteki. Nie wpłynie to w żaden sposób na wcześniej utworzone oceny.",
                    categories:"Kompetencje",
                    cat_placeholder:"Wpisz nazwę wskaźnika",
                    subcat_placeholder:"Wpisz tytuł oceny",
                    cat_message_empty:"Wpisz niepustą kompetencję",
                    cat_message_double:"Ta kompetencja już istnieje",
                    subcat_message_empty:"Wpisz niepustą informację zwrotną 180/360 stopni",
                    subcat_message_double:"Ta informacja zwrotna 180/360 stopni już istnieje",
                    subcategories:"Informacja zwrotna 180/360 stopni",
                    add_cat:"Dodać",
                    del_cat:"Kasować",
                    edit_cat:"Edytować",
                    del_cat_title:"Usuń kompetencję",
                    del_cat_mess:"Jeśli kompetencja zostanie usunięta, pozostaje w informacji zwrotnej 180/360 stopni, w której została ustawiona.",
                    del_subcat_title:"Usuń partyturę",
                    del_subcat_mess:"Jeśli ocena zostanie usunięta, pozostaje ona na podstawie informacji zwrotnej 180/360 stopni, w której została ustawiona.",
                    cancel:"Anulować",
                    select:"Wybierz",
                    save:"Zapisać",
                    showsave:"Zapisz w bibliotece",
                    plc1:"Ustaw nazwę",
                    preset_name:"Zapisz ten zestaw kompetencji w bibliotece",
                    preset_name_is_allready:"Ta nazwa zestawu już istnieje",
                    preset_name_is_empty:"Wpisz nazwę przynajmniej 3-symbol-lo",
                    collapse:"Zawalić się",
                    add_from_preset:"Dodaj kompetencje z biblioteki",
                    choose_preset:"— Wybierz zestaw --",
                    copy:"Skopiuj wszystkie opinie 180/360 stopni do schowka",
                    paste:"Wklej/wymień wszystkie opłaty 180/360 stopni",
                    undo:"Cofnij wklejoną informację zwrotną 180/360 stopni",
                    preset_plc:"Użyj biblioteki dla wcześniej zapisanych zestawów kompetencji",
                  },
                  pt:{
                    reset_cat:"Limpar formulário",
                    add_from_comps: "Selecionar Competência",
                    comp_etence:"Adicionar habilidade manualmente",
                    comps_plc:"Selecione uma habilidade",
                    scale_plc:"Selecionar escala",
                    indi_cators:"Indicadores",
                    requared_field: "Título obrigatório",
                    click_for_enter:"Clique para editar",
                    comps_asterisk: "Asterisco indica competências previamente salvas na biblioteca, nenhum asterisco indica competências aprovadas",
                    del_preset_title: "Remover um conjunto de competências?",
                    del_preset_mess: "O conjunto de competências será removido da biblioteca. Isso não afetará as avaliações criadas anteriormente.",
                    categories:"Competências",
                    cat_placeholder:"Indique o nome do indicador",
                    subcat_placeholder:"Indique o nome da avaliação",
                    cat_message_empty:"Insira a competência não vazia",
                    cat_message_double:"Esta competência já existe",
                    subcat_message_empty:"Insira feedback não vazio de 180/360 graus",
                    subcat_message_double:"Este feedback de 180/360 graus já existe",
                    subcategories:"Feedback de 180/360 graus",
                    add_cat:"Adicionar",
                    del_cat:"Excluir",
                    edit_cat:"Editar",
                    del_cat_title:"Apagar competência",
                    del_cat_mess:"Se uma competência for excluída, ela permanece no feedback de 180/360 graus no qual foi definida.",
                    del_subcat_title:"Apagar uma pontuação",
                    del_subcat_mess:"Se uma pontuação for excluída, ela permanecerá no feedback de 180/360 graus no qual foi definida.",
                    cancel:"Cancelar",
                    select:"Selecione",
                    save:"Salve",
                    showsave:"Salvar na biblioteca",
                    plc1:"Nome do conjunto",
                    preset_name:"Salve este conjunto de competências na biblioteca",
                    preset_name_is_allready:"Este nome de conjunto já existe",
                    preset_name_is_empty:"Insira um nome com pelo menos 3 símbolos",
                    collapse:"Colapso",
                    add_from_preset:"Adicionar competências da biblioteca",
                    choose_preset:"— Selecione um conjunto --",
                    copy:"Copie todo o feedback de 180/360 graus para a área de transferência",
                    paste:"Cole / substitua todo o feedback de 180/360 graus",
                    undo:"Desfazer feedback colado de 180/360 graus",
                    preset_plc:"Use a biblioteca para conjuntos de competências salvos anteriormente",
                  },
                  es:{
                    reset_cat:"Borrar formulario",
                    add_from_comps: "Seleccionar Competencia",
                    comp_etence:"Añadir habilidad manualmente",
                    comps_plc:"Seleccione una Habilidad",
                    scale_plc:"Seleccionar escala",
                    indica_cators:"Indicadores",
                    requaried_field: "Título requerido",
                    click_for_enter:"Haga clic para editar",
                    comps_asterisk: "El asterisco indica competencias previamente guardadas en la biblioteca, ningún asterisco indica competencias aprobadas",
                    del_preset_title: "¿Eliminar un conjunto de competencias?",
                    del_preset_mess: "Se eliminará el conjunto de competencias de la biblioteca. Esto no afectará las estimaciones creadas previamente.",
                    categories:"Competencias",
                    cat_placeholder:"Introduzca el nombre del indicador",
                    subcat_placeholder:"Introduzca el nombre de la evaluación",
                    cat_message_empty:"Ingrese la competencia no vacía",
                    cat_message_double:"Esta competencia ya existe",
                    subcat_message_empty:"Ingrese comentarios no vacíos de 180/360 grados",
                    subcat_message_double:"Esta retroalimentación de 180/360 grados ya existe",
                    subcategories:"Retroalimentación de 180/360 grados",
                    add_cat:"Agregar",
                    del_cat:"Borrar",
                    edit_cat:"Editar",
                    del_cat_title:"Eliminar competencia",
                    del_cat_mess:"Si se elimina una competencia, permanece en la retroalimentación de 180/360 grados en la que se estableció.",
                    del_subcat_title:"Eliminar una partitura",
                    del_subcat_mess:"Si se elimina una puntuación, permanece en la retroalimentación de 180/360 grados en la que se estableció.",
                    cancel:"Cancelar",
                    select:"Seleccione",
                    save:"Ahorrar",
                    showsave:"Guardar en la biblioteca",
                    plc1:"Escoger un nombre",
                    preset_name:"Guarde este conjunto de competencias en la biblioteca",
                    preset_name_is_allready:"Este nombre de conjunto ya existe",
                    preset_name_is_empty:"Ingrese un nombre de al menos 3 símbolos de longitud",
                    collapse:"Colapso",
                    add_from_preset:"Agregar competencias de la biblioteca",
                    choose_preset:"— Seleccione un conjunto --",
                    copy:"Copie todos los comentarios de 180/360 grados al portapapeles",
                    paste:"Pegar / reemplazar todos los comentarios de 180/360 grados",
                    undo:"Deshacer la retroalimentación pegada de 180/360 grados",
                    preset_plc:"Usar la biblioteca para conjuntos de competencias previamente guardados",
                  },
                  vn:{
                    reset_cat:"Xóa biểu mẫu",
                    add_from_comps: "Chọn năng lực",
                    comp_etence:"Thêm kỹ năng thủ công",
                    comps_plc:"Chọn một kỹ năng",
                    scale_plc:"Chọn tỷ lệ",
                    indi_cators:"Chỉ số",
                    requaried_field: "Tiêu đề bắt buộc",
                    click_for_enter:"Nhấp để chỉnh sửa",
                    comps_asterisk: "Dấu hoa thị cho biết các năng lực đã được lưu vào thư viện trước đó, không có dấu hoa thị cho biết các năng lực đã được phê duyệt",
                    del_preset_title:"Xóa một bộ năng lực?",
                    del_preset_mess:"Một bộ năng lực sẽ được xóa khỏi thư viện. Đây sẽ không ảnh hưởng đến tạo ra trước đó ước tính trong bất kỳ cách nào.",
                    categories:"Năng lực",
                    cat_placeholder:"Nhập tên của chỉ báo",
                    subcat_placeholder:"Nhập tên của đánh giá",
                    cat_message_empty:"Nhập năng lực không trống",
                    cat_message_double:"Năng lực này đã tồn tại",
                    subcat_message_empty:"Nhập phản hồi 180/360 độ không trống",
                    subcat_message_double:"Phản hồi 180/360 độ này đã tồn tại",
                    subcategories:"Phản hồi 180/360 độ",
                    add_cat:"Thêm vào",
                    del_cat:"Xóa bỏ",
                    edit_cat:"Chỉnh sửa",
                    del_cat_title:"Xóa năng lực",
                    del_cat_mess:"Nếu năng lực bị xóa, năng lực đó vẫn dựa trên phản hồi 180/360 độ mà nó đã được thiết lập.",
                    del_subcat_title:"Xóa điểm",
                    del_subcat_mess:"Nếu điểm số bị xóa, điểm số đó vẫn ở phản hồi 180/360 độ mà nó đã được đặt.",
                    cancel:"Hủy bỏ",
                    select:"Lựa chọn",
                    save:"Cứu",
                    showsave:"Lưu vào thư viện",
                    plc1:"Đặt tên",
                    preset_name:"Lưu tập hợp năng lực này vào thư viện",
                    preset_name_is_allready:"Tên tập hợp này đã tồn tại",
                    preset_name_is_empty:"Nhập tên dài ít nhất 3 ký hiệu",
                    collapse:"Sự sụp đổ",
                    add_from_preset:"Thêm năng lực từ thư viện",
                    choose_preset:"— Chọn một tập hợp --",
                    copy:"Sao chép tất cả phản hồi 180/360 độ vào khay nhớ tạm",
                    paste:"Dán / thay thế tất cả phản hồi 180/360 độ",
                    undo:"Hoàn tác phản hồi 180/360 độ đã dán",
                    preset_plc:"Sử dụng thư viện cho các bộ năng lực đã lưu trước đó",
                  },

                },
            }
        },
        mounted: function(){
          console.log(':1: mounted',this.pCompetence );
          this.mSubcategories={};
          this.mCategories=[];
          this.mCompetence='';
          if(this.pCompetence) {
            if (this.pCompetence.subcats) {
              for (let i in this.pCompetence.subcats) {
                this.mSubcategories[i] = this.pCompetence.subcats[i];
              }
            }

            if (this.pCompetence.cats) {
              for (let i in this.pCompetence.cats) {
                this.mCategories.push(this.pCompetence.cats[i]);
              }
            }
            this.mCompetence = this.pCompetence.name;
            this.mId = this.pCompetence.id;
            // this.competence = {
            //   id: this.mId,
            //   name: this.mCompetence,
            //   indicators: this.mCategories,
            // }
            // this.scale = this.pCompetence.subcats
          }
            //
          this.loading_comps = true;
          this.loading_scales = true;
          this.parent.competenceGetList('competence', true).then(res=>{
            this.comps_options = res;
            this.loading_comps = false;
            this.loadPresets();
            console.log(':***:comps_options= ', this.comps_options);
            this.parent.competenceGetList('score', true).then(res=> {
                this.scores_options = res;
              this.loading_scales = false;
                console.log(':***:scores_options= ', this.scores_options);
              });
          });
          //Профили
          this.loading_profiles = true;
          this.parent.competenceGetList('profile').then(res=>{
            if(res && res.length > 0) {
              this.profiles = res.filter(item => item.published === 'Y');
            }
            this.loading_profiles = false;
            console.log(':***:profiles=',this.profiles);
          });
        },
        watch:{
          mCompetence: function(arg){
            console.log(':watch: mCompetence=', arg)
          }
        },
        computed:{
            statePresets(){
                let ret=true;
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.stateMessage='';
                if(String(this.preset_name).length < 3){
                    ret=false;
                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                  this.stateMessage=this.message[this.lang].preset_name_is_empty;
                }else if(this.presets){
                    ret = !this.presets.find((element, index, array)=>
                    { if(element.name == this.preset_name) return true; else return false }, this);
                    if(!ret){
                      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                      this.stateMessage=this.message[this.lang].preset_name_is_allready;
                    }
                }
                // console.log('::statePresets ',ret,this.preset_name);

                return ret;
            }
        },
        methods: {
          setProfiles() {
            console.log(':: setProfiles profile=', this.profile);
            if (this.profile && this.profile.comps) {
              this.saving_profiles = true;
              for (let item_comp of this.profile.comps) {
                let subcats = {};
                for (let ind of item_comp.indicators) {
                  subcats[ind.value] = item_comp.scale.indicators.map(el => {
                    return {value: el.value, text: el.name}
                  });
                }
                let competence = {
                  id: '',
                  name: item_comp.name,
                  cats: item_comp.indicators.map(el => {
                    return {value: el.value, text: el.value}
                  }),
                  subcats: subcats
                }

                this.$emit('competences_saved', competence);
              }
              setTimeout(() => {
                this.saving_profiles = false;
                BX24.scrollParentWindow (999, (arg)=>{
                  console.log('::**** scrolled down ',arg)
                })
              }, 1000);
            }
          },
          setIndicators(){
            console.log('::setIndicators competence=', this.competence);

            let oldCompetence = this.mCompetence;
            if(this.competence.asterisk){
              this.mCompetence=this.competence.label;
              this.mCategories = this.competence.value.cats;
              this.mSubcategories = this.competence.value.subcats;
              console.log(':: mSubcategories=',this.mSubcategories)
            }else {
              this.mCompetence = this.competence.name;
              this.mCategories = [];
              for (let item of this.competence.indicators) {
                this.mCategories.push({text: item.value, value: item.value})
              }
            }
            if(oldCompetence != this.mCompetence) {
              this.mId = null;
              this.scale = '';
            }
            console.log(':***: mCategories=', this.mCategories)
          },
          setScale(){
            console.log('::setScale scale=', this.scale);
            this.mSubcategories = {};
            for(let item_cat of this.competence.indicators){
              console.log('::item_cat=', item_cat);
              this.mSubcategories[item_cat.value] = [];
              for(let item_subcat of this.scale.indicators) {
                this.mSubcategories[item_cat.value].push({
                  value:item_subcat.name,
                  text:item_subcat.name
                });
              }
            }
            console.log(':***: mSubcategories=',this.mSubcategories)
            if(this.mCategories && this.mCategories[0]) {
              this.clickCat(this.mCategories[0], 0)
            }

          },

            copySubcats(){
                if(this.cat_selected && this.mSubcategories[this.cat_selected]) {
                    let subcats = this.mSubcategories[this.cat_selected];
                    window.localStorage.setItem('subcats', JSON.stringify(subcats));
                }
            },
            pasteSubcats(){
                if(this.cat_selected && this.mSubcategories[this.cat_selected]) {
                    let subcats_safe = this.mSubcategories[this.cat_selected];
                    window.localStorage.setItem('subcats_safe', JSON.stringify(subcats_safe));
                }
                if(this.cat_selected && this.mSubcategories) {

                    let subcats = window.localStorage.getItem('subcats');
                    if (subcats) {
                        this.mSubcategories[this.cat_selected] = JSON.parse(subcats);
                        this.isShowSubcats=false; this.$nextTick(() => {  this.isShowSubcats = true; });
                    }
                }
            },
            undoSubcats(){
                if(this.cat_selected && this.mSubcategories) {

                    let subcats_safe = window.localStorage.getItem('subcats_safe');
                    if (subcats_safe) {
                        this.mSubcategories[this.cat_selected] = JSON.parse(subcats_safe);
                        this.isShowSubcats=false; this.$nextTick(() => {  this.isShowSubcats = true; });
                    }
                }
            },
            delPreset(item,index){
                // console.log("::delCat");
                let $this = this;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_title=this.message[this.lang].del_preset_title+' "'+item.name+'"?';
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_message=this.message[this.lang].del_preset_mess;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_obj='';
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_ok = this.message[this.lang].del_cat;

              $this.parent.modal_filterFn = null;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.okModal =  ()=>{
                    $this.presets.splice(index,1);
                    $this.parent.appOptionSet('competences', JSON.stringify(this.presets));
                    $this.resetPresetOptions();
                    $this.parent.hideModal();
                };
                BX24.scrollParentWindow (0);
                this.parent.showModal();
            },
            loadPresets(){
                let $this=this;
                $this.parent.appOptionGet('competences').then((data)=>{
                    $this.presets = data.DETAIL_TEXT? JSON.parse(data.DETAIL_TEXT) : [];
                    $this.resetPresetOptions();

                },err=>{ $this.presets_options = ''; });
            },
            resetPresetOptions(){
              this.presets_options = [];//[{label:this.message[this.lang].choose_preset, value:{cats:'', subcats:''} }];
              for(let i in this.presets){
                  let item = this.presets[i];
                  // this.presets_options.push({label:item.name, value:item});
                  this.comps_options.push({value:item, name:"* "+item.name, asterisk:true, label:item.name});
              }
            },
            savePreset(evt){
                evt.preventDefault();
                this.presets = this.presets ? this.presets : [];
                this.presets.push({name:this.preset_name, cats:this.mCategories, subcats:this.mSubcategories});
                this.parent.appOptionSet('competences', JSON.stringify(this.presets));
                this.preset_name='';
                this.resetPresetOptions();
            },
            resetCat(){
              this.mId = '';
              this.mCompetence='';
              this.mCategories=[];
              this.mSubcategories={};
              this.cat_input='';
              this.cat_selected='';
            },
            cats_saved(isNew){
              console.log('::cats_saved isNew=',isNew)
              this.$emit('competences_saved',
                {
                  id:isNew?'':this.mId,
                  cats:this.mCategories,
                  subcats:this.mSubcategories,
                  name:this.mCompetence
                });
            },
            moveUpCat(item,index){
                // console.log('::moveUpCat index=',index);
                this.cat_message='';
                if(index>0) {
                    this.mCategories.splice(index, 1);
                    this.mCategories.splice(index-1, 0, item);
                    this.clickCat(item,index-1);
                }
            },
            moveDownCat(item,index){
                // console.log('::moveDownCat index=',index);
                this.cat_message='';
                if(index<Object.keys(this.mCategories).length-1) {
                    this.mCategories.splice(index, 1);
                    this.mCategories.splice(index+1, 0, item);
                    this.clickCat(item,index+1);
                }
            },
            clickCat(item,index){
              let $this = this;
              this.cat_selected = item.value;
              this.cat_input = item.text;
              this.cat_index = index;
              this.cat_message='';
              this.isShowInputCat = true;
              this.isShowSubcats = true;
              // console.log('::clickCat ',index,item, this.mSubcategories, this.cat_selected);
              // this.isShowCats = false;
              // this.$nextTick(function () {
              //     $this.isShowCats=true;
              // });
            },
            remCat(item,index){
                // console.log('::remCat ',index,item);
                this.clickCat(item, index);
                this.delCat();
            },
            edCat(item,index){
                // console.log('::remCat ',index,item);
                this.clickCat(item, index);
                // this.editCat();
            },
            //---
            moveUpSubCat(item,index){
                // console.log('::moveUpSubCat index=',index);
                this.subcat_message='';
                if(index>0) {
                    this.mSubcategories[this.cat_selected].splice(index, 1);
                    this.mSubcategories[this.cat_selected].splice(index-1, 0, item);
                    this.isShowSubcats = false;
                    this.$nextTick(function () {
                        this.isShowSubcats=true;
                    });
                    this.clickSubCat(item,index-1);
                }
            },
            moveDownSubCat(item,index){
                // console.log('::moveDownSubCat index=',index, item, this.mSubcategories );
                this.subcat_message='';
                let subcat=this.mSubcategories[this.cat_selected];
                if(index<Object.keys(subcat).length-1) {
                    subcat.splice(index, 1);
                    subcat.splice(index + 1, 0, item);
                    this.mSubcategories[this.cat_selected] = subcat;
                    this.isShowSubcats = false;
                    this.$nextTick(function () {
                        this.isShowSubcats=true;
                    });
                    this.clickSubCat(item,index+1);
                }
            },
            clickSubCat(item,index){
                // console.log('::clickSubCat ',index,item);
                this.subcat_selected = item.value;
                this.subcat_input = item.text;
                this.subcat_index = index;
                this.subcat_message='';
            },
            remSubCat(item,index){
                // console.log('::remSubCat ',index,item);
                this.clickSubCat(item, index);
                this.delSubCat();
            },
            edSubCat(item,index){
                this.clickSubCat(item, index);
            },
            addCat(){
                console.log("::addCat");
                this.cat_message='';
                if(this.cat_input && this.cat_input.trim().length > 0){
                    if(!this.mCategories.find((element, index, array)=>{ if(element.text === this.cat_input) return true; else return false }, this)){
                        // this.mCategories.push({value:this.cat_input, text:this.cat_input});
                        let item = {value:this.parent.myEx(this.cat_input), text:this.cat_input };
                        this.mCategories.splice(this.cat_index+1,0,item);
                        this.clickCat(item, this.cat_index+1);
                    }else{
                        // console.log('::allready in');
                        this.cat_message = this.message[this.lang].cat_message_double;
                    }
                }else{
                    // console.log(':: empty');
                    this.cat_message = this.message[this.lang].cat_message_empty;
                }
            },
            delCat(){
                // console.log("::delCat");
                let $this = this;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_title=this.message[this.lang].del_cat_title+' "'+this.cat_selected+'"?';
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_message=this.message[this.lang].del_cat_mess;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_obj='';
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_ok = this.message[this.lang].del_cat;
              $this.parent.modal_filterFn = null;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.okModal =  ()=>{
                    $this.cat_message='';
                    for(let i in $this.mCategories){
                        let item = $this.mCategories[i];
                        // console.log("::delCat", item.value, $this.cat_selected);

                        if(item.value === $this.cat_selected){
                            console.log("::delCat", item.value);
                            $this.mCategories.splice(i,1);
                            let newSubCats={};

                            for(let cat in $this.mSubcategories) {
                                console.log(":: cat=",cat, $this.mSubcategories[cat]);

                                if (cat != $this.cat_selected) {
                                    newSubCats[cat]=$this.mSubcategories[cat];
                                    console.log(":: +++ ");
                                }
                            }
                            $this.mSubcategories = newSubCats;
                            console.log("done ", $this.mSubcategories);

                            $this.cat_input='';
                            $this.cat_selected='';
                            break;
                        }
                    }
                    $this.parent.hideModal();
                };
                BX24.scrollParentWindow (0);
                this.parent.showModal();
            },
            editCat(){
                // console.log("::editCat ",this.cat_input, this.mCategories, this.mSubcategories);
                this.cat_message='';
                if(!this.cat_input || this.cat_input.trim().length === 0){
                    this.cat_message=this.message[this.lang].cat_message_empty;
                }else{
                    if(!this.mCategories.find((element, index, array)=>{
                        // console.log('::compare',index,element.value,this.cat_input);
                        if(element.text === this.cat_input) return true; else return false }, this)){

                        this.cat_message='';
                        for(var i in this.mCategories){
                            let item = this.mCategories[i];

                            if(item.value === this.cat_selected){
                                let newItem = {
                                    value:this.parent.myEx(this.cat_input),
                                    text:this.cat_input,
                                };
                                // console.log('::editCat item=',item,' new=',newItem, ' cat_selected',this.cat_selected);
                                this.mCategories.splice(parseInt(i),1,newItem);
                                let subcatKeys = Object.keys(this.mSubcategories);
                                let newSubcats={};
                                for(let j in subcatKeys){
                                    let subcatKey = subcatKeys[j];
                                    let subcatValue = this.mSubcategories[subcatKey];
                                    // console.log(':: subcatKey=',subcatKey,' subcatValue=',subcatValue);
                                    if(subcatKey ===  this.cat_selected){
                                        newSubcats[this.cat_input]=subcatValue;
                                    }else{
                                        newSubcats[subcatKey]=subcatValue;
                                    }
                                }
                                this.mSubcategories={};
                                for(let j in newSubcats){
                                    this.mSubcategories[j] = newSubcats[j];
                                }

                                this.cat_selected = this.cat_input;
                                this.cat_input='';

                                break;
                            }
                        }
                    }else{
                        // console.log('::allready in');
                        this.cat_message = this.message[this.lang].cat_message_double;
                    }
                }
            },

            catGetNewId(){
                let newId = 1;
                for(let i in this.mCategories){
                    newId = newId > this.mCategories[i].id ? newId : this.mCategories[i].id;
                }
                newId++;
                return newId;
            },
            addSubCat(){
                this.subcat_message='';
                var cat = this.cat_selected;
                if(cat && this.subcat_input && this.subcat_input.trim().length > 0){
                    if(!this.mSubcategories[cat])
                        this.mSubcategories[cat]=[];

                    if(!this.mSubcategories[cat].find((element, index, array)=>{ if(element.text === this.subcat_input) return true; else return false }, this)){

                        // console.log(cat);
                        let item = {value:this.parent.myEx(this.subcat_input), text:this.subcat_input};
                        // this.mSubcategories[cat].push( {value:this.subcat_input, text:this.subcat_input} );
                        this.mSubcategories[cat].splice(this.subcat_index+1, 0, item );
                        this.clickSubCat(item, this.subcat_index+1);
                    }else{
                        this.subcat_message = this.message[this.lang].subcat_message_double;
                    }
                }else{
                    // console.log(':: empty');
                    this.subcat_message = this.message[this.lang].subcat_message_empty;
                }
            },
            delSubCat(){
                // console.log("::delSubCat");
                let $this = this;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_title=this.message[this.lang].del_subcat_title+' "'+this.subcat_selected+'"?';
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_message=this.message[this.lang].del_subcat_mess;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_obj='';
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.modal_ok = this.message[this.lang].del_cat;
              $this.parent.modal_filterFn = null;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.okModal =  ()=>{
                    $this.delSubcatSilent();
                    $this.parent.hideModal();
                };
                BX24.scrollParentWindow (0);
                this.parent.showModal();
            },
            delSubcatSilent(){
                let $this = this;
                $this.subcat_message='';
                for(var i in $this.mSubcategories[$this.cat_selected]){
                    var item = $this.mSubcategories[$this.cat_selected][i];
                    if(item.value === $this.subcat_selected){
                        // console.log("::delSubCat", item.value);
                        $this.mSubcategories[$this.cat_selected].splice(i,1);
                        $this.subcat_input='';
                        $this.subcat_selected='';
                        $this.isShowSubcats = false;
                        $this.$nextTick(function () {
                            $this.isShowSubcats=true;
                        });
                        break;
                    }
                }
            },
            editSubCat(){
                // console.log("::editSubCat ",this.subcat_input);
                this.subcat_message ='';
                if(!this.subcat_input || this.subcat_input.trim().length === 0 ){
                    this.subcat_message=this.message[this.lang].subcat_message_empty;
                }else{
                    if(!this.mSubcategories[this.cat_selected].find((element, index, array)=>{
                        if(element.text === this.subcat_input) {
                            // console.log(':: finded ', element.value, this.subcat_input, element.value === this.subcat_input)
                            return true;
                        }
                        else return false }, this)){

                        this.subcat_message='';
                        for(var i in this.mSubcategories[this.cat_selected]){
                            var item = this.mSubcategories[this.cat_selected][i];
                            if(item.value === this.subcat_selected){
                                // console.log("::editSubCat", item.value);
                                this.mSubcategories[this.cat_selected].splice(i,1, {value:this.subcat_input, text:this.subcat_input});
                                this.subcat_input='';
                                this.isShowSubcats = false;
                                this.$nextTick(function () {
                                    this.isShowSubcats=true;
                                });
                                break;
                            }
                        }
                    }else{
                        // console.log('::allready in');
                        this.subcat_message = this.message[this.lang].subcat_message_double;
                    }
                }
          },


        },
        components: {

        }
    }

</script>

<style scoped>


</style>
