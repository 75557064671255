import script from "./Rating.vue?vue&type=script&setup=true&lang=js"
export * from "./Rating.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QRating from 'quasar/src/components/rating/QRating.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QRating,QBtn});
