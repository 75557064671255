<template>
  <div class="component_padding">
    
      <!-- Название и лого -->
        <div class="row">
          <div v-if="program?.flags?.logo" class="col-2">
            <q-img
              :src="program.flags.logo"
              spinner-size="2rem"
              spinner-color="positive"
              style="border: #dddddd 1px dashed; border-radius: 15px"
            />
          </div>
          <div class="col-10 q-px-sm text-accent h4 q-gutter-y-sm">
            
            <!--Программа-->
            <div class="row">
              <div class="col-2 text-positive h4">
                {{ message[lang].the_program }}
              </div>
              <div class="col-10 text-accent h4">
                  {{ program.name }}
              </div>
            </div>
            
            <!--Раздел каталога-->
              <div v-if="program?.flags?.cat" class="row">
                <div class="col-2 text-positive h4">
                  {{ message[lang].categories }}
                </div>
                <div class="col-10 text-accent h4">
                    {{ parent.categories[lang].find(c => c.value == program.flags.cat)?.text }}
                </div>
              </div>

              <!--Тематика-->
              <div v-if="program?.flags?.thematics" class="row">
                <div class="col-2 text-positive h4">
                  {{ message[lang].thematics }}
                </div>
                <div class="col-10 text-accent h4">
                   {{ program.flags.thematics }}
                </div>
              </div>
              
              <!--  Цель      -->
              <div v-if="program?.flags?.target" class="row">
                <div class="col-2 text-positive h4">
                  {{ message[lang].target_placeholder }}
                </div>
                <div class="col-10 text-accent h4">
                   {{ program.flags.target }}
                </div>
              </div>

              <!-- Длительность --->
              <div v-if="program?.flags?.duration" class="row">
                <div class="col-2 text-positive h4">
                  {{ message[lang].duration }}
                </div>
                <div class="col-10 text-accent h4">
                   {{ program.flags.duration }}
                </div>
              </div>
              <!--  Описание      -->
              <div v-if="program?.description" class="row">
                <div class="col-2 text-positive h4">
                  {{ message[lang].program_description }}
                </div>
                <div class="col-10 text-accent h4" style="white-space: pre-wrap;">
                   {{ program.description }}
                </div>
              </div>

          </div>
          
        </div>
        
    <!--  Модули      -->
      <div class="q-pa-sm h4 text-positive">
        {{ message[lang].progrm_modules }}
      </div>
      <!-- Список модулей -->
      <q-list separator >
        <q-item
          v-for="(itemRow, indexRow) in program.modules"
          :key="indexRow"
          style="border-bottom: #dddddd solid 1px" >
            <q-item-section class="col-1 gt-sm">
              {{ indexRow + 1 }}
            </q-item-section>
            <q-item-section >
              <div class="q-gutter-x-xs">
                
                    <q-chip
                      v-for="(item, index) in itemRow"
                      :key="index"
                      color="primary"
                      outline
                    >
                      <q-avatar text-color="primary">
                          {{ item.duration ? item.duration : 0 }}
                          <q-tooltip class="bg-primary h-tt">{{
                            message[lang].mod_duration
                          }}</q-tooltip>
                      </q-avatar>
                      {{ showItemName(item.name, 25) }}
                    </q-chip>
              </div>
            </q-item-section>
        </q-item>
      </q-list>
      <!-- Руководитель -->
      <div class="text-accent  q-pa-sm">
          <!-- {{ message[lang].add_main_auditor }}:  -->
          <q-btn  padding="none" :icon-right="auditor_main?'edit':'add'" size="sm" :loading="isAuditorsLoading" flat rounded color="accent" :label="message[lang].add_main_auditor" @click="auditorAdd">
              <q-tooltip class="bg-primary h-tt">{{message[lang].add_main_auditor_info}}</q-tooltip>
          </q-btn>
          <q-chip v-if="auditor_main" :color="auditor_main.color" outline size="sm">
              {{auditor_main.name}}
          </q-chip>
      </div>

      <div class="text-accent h5 q-pa-sm">
        <q-icon name="info" />
        {{ message[lang].the_program_info }}
      </div>
      
    <!-- Пройти -->
    <div class="row justify-center q-my-md">
      <div class="col-10 offset-2 q-gutter-x-md">
        <!-- Сохранить -->
        <q-btn
          size="md"
          elevated
          rounded
          color="primary"
          :label="message[lang].go_to_program"
          @click="toSchedProgram"
          :disabled="!auditor_main"
          :loading="loading"
        >
         <q-tooltip v-if="!auditor_main" class="bg-primary h-tt">{{message[lang].program_view_btn}}</q-tooltip> 
        </q-btn>
        <!-- Закрыть -->
        <q-btn
          :disabled="loading"
          size="md"
          elevated
          rounded
          color="positive"
          :label="message[lang].add_evt_ok"
          @click="closeViewProgram"
        />
      </div>
    </div>


  </div>

</template>

<script>
import { messageObj } from "src/helpers/message";
import dayjs from "dayjs";

export default {
  name: "ListOfPrograms",
  props: {
    parent: Object,
    lang: String,
    back: String,
    itemProgram: Object,
  },
  data() {
    return {
      loading:false,
      program: {
        id: 0,
        name: "",
        description: "",
        modules: [],
        type: "S",
        flags: {},
        ACTIVE: "N",
      },
      auditor_main: null,
      isAuditorsLoading: false,

      message: messageObj,
    };
  },
  computed: {

  },
  mounted() {
    console.log(":: mounted programView =", this.itemProgram);
    if (this.itemProgram) {
      this.program = {
        id: this.itemProgram.ID,
        name: this.parent.restoreQuotes(this.itemProgram.NAME),
        description: this.parent.restoreQuotes(this.itemProgram.DETAIL_TEXT),
        modules: this.parent.getProgramModules( this.itemProgram.PROPERTY_VALUES.modules ),
        type: this.itemProgram.PROPERTY_VALUES.type,
        flags: this.parent.isJSON(this.itemProgram.PROPERTY_VALUES.flags)
          ? JSON.parse(this.itemProgram.PROPERTY_VALUES.flags)
          : this.itemProgram.PROPERTY_VALUES.flags?this.itemProgram.PROPERTY_VALUES.flags:{},
        ACTIVE: this.itemProgram.ACTIVE,
      };
      this.program.flags.target = this.parent.restoreQuotes(this.program.flags.target);
      this.program.flags.thematics = this.parent.restoreQuotes(this.program.flags.thematics);


      console.log(':: mounted programView program=', this.program);

      for (let i = 0; i < this.program.modules.length; i++) {
        this.program.modules[i].name = this.parent.restoreQuotes(
          this.program.modules[i].name
        );
      }
      
      this.loading = true;
      this.loadSchedule().then((res) => {
        console.log(":1:loadSchedule res=", res);
        if(res && res[0]){
          let schedule = this.parent.isJSON(res[0].DETAIL_TEXT)?JSON.parse(res[0].DETAIL_TEXT):res[0].DETAIL_TEXT;
          console.log(":2:loadSchedule schedule=", schedule);
          this.auditor_main = schedule.auditor_main;
          this.program.shedule_id = res[0].ID; 
          this.program.results = schedule.results;  
        }
        console.log(":: mounted program =", this.program);
        this.loading = false;
      })
    }
  },
  emits: ['emitgoback'],
  methods: {
    toSchedProgram(){
      console.log(':1: toSchedProgram program=', this.program);
      this.loading = true;
      this.doSaveSchedule().then((res) => {
          console.log(":2:toSchedProgram res=", res);
          this.setUserSchedules().then((res) => {
            console.log(":3:toSchedProgram res=", res);
            //сохраним с results
            this.doSaveSchedule().then((res) => {
              console.log(":4:toSchedProgram res=", res);
              this.$emit('toSchedProgram', this.itemProgram);
              this.loading = false;
            });
          })
          
        });
    },
    closeViewProgram(){
      this.$emit('closeViewProgram');
    },
    showItemName(name, ln) {
      return name && name.length > 25 ? name.substr(0, ln) + "..." : name;
    },
    auditorAdd(){
      this.parent.usersAdd([], true).then((res) => {
        console.log("::userAdd res=", res);
        this.auditor_main = {
          id: res[0].id,
          name: res[0].name,
          color: "primary",
        };
      });
    },
     doSaveSchedule(){
        console.log('::saveSchedule program=', this.program);
        let $this = this;
        let auditors = [];
        this.schedule = {
          auditor_main: this.auditor_main,
          auditors: [this.auditor_main],
          modules: this.program.modules,
          is_tasks:1,
          program_id: this.program.id,
          program_type: this.program.type,
          results: this.program.results||[],
          published: 'Y',
          ACTIVE: 'Y',
          students:[{
            id: this.parent.user_current.ID,
            value: this.parent.user_current.ID,
            name: this.parent.user_current.NAME+' '+this.parent.user_current.LAST_NAME
          }],
          id:this.program.shedule_id 

        };
        
        this.calcModuleDates();
        
        console.log('::saveSchedule', this.schedule);

        let method = this.schedule.id?'entityItemUpdate':'entityItemAdd';
        let params = {
            'ENTITY': 'uni_progscheds',
            'NAME': this.schedule.program_id,
            'DETAIL_PICTURE': this.parent.user_current.ID, //для самоназначения по паре NAME-DETAIL_PICTURE будем проверять сделанное назначение
            'PREVIEW_TEXT': JSON.stringify([this.schedule.auditor_main.id, ...auditors]),
            'DETAIL_TEXT': JSON.stringify(this.schedule),
            'ACTIVE': this.schedule.ACTIVE,
            'DATE_ACTIVE_FROM': this.schedule.period.from,
            'DATE_ACTIVE_TO': this.schedule.period.to,
            'STUFF':JSON.stringify(this.schedule.results)
        };
        if(this.program.shedule_id ){
            params['ID'] = this.program.shedule_id ;
        }
        console.log('::saveSchedule params', params);
        return new Promise(function (resolve, reject) {
            let url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });
            $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
              console.log('::saveSchedule res=', res);
              if (res.result) {
                if(!$this.schedule.id){
                    $this.schedule.id = res.result;
                }
                resolve(res);
              }else{
                  resolve(false);
              }
            }, "json");
        })
    },
    setUserSchedules(){
      let $this = this;
      let program = this.program;
      let students_id = this.schedule.students.map(item=>item.id);
      console.log(':*: setUserSchedules program_id=', this.schedule.program_id, program);
      return new Promise(function (resolve, reject) {
        let arBatch={};
        let arBatch2={};
        $this.schedule.results = $this.schedule.results || [];
        // console.log(':*: schedule.results', $this.schedule.results);
        let modules = $this.addPassedParameter(program.modules);
        // let modules = $this.listModules.map(el=>{ return {mid:el.id, mname:el.name, mtype:el.type, passed:'0'}}); //Y-прошел, N-не прошел, null-неизвестно
        // console.log(':*: modules_id', modules_id);
        // Добавляем назначения
        for(let uid of students_id){
            /**
             * Если в schedule.results нет записи
             * [{uid:id, usched:id, task:id}, ]
             * то делаем назначение
             */
            if(!$this?.program?.results || !$this.program.results.find(el=>el.uid==uid&&el.usid)){
                let params = {
                    'ENTITY': 'uni_uprogscheds',
                    'NAME': parseInt(uid),
                    'PREVIEW_TEXT': $this.schedule.id,
                    'DETAIL_TEXT': JSON.stringify(modules),
                    'ACTIVE': 'Y',
                    'DATE_ACTIVE_FROM': $this.schedule.period.from,
                    'DATE_ACTIVE_TO': $this.schedule.period.to,
                    'STUFF': $this.schedule.program_id,
                    'PROPERTY_VALUES': {
                        flags: JSON.stringify(program.flags)
                    }

                };
                arBatch['usched_' + uid] = ['entityItemAdd', params];
            }
        }

        console.log('::callBatch arBatch', arBatch);
        if(Object.keys(arBatch).length>0){
            $this.parent.callBatch(arBatch, (res) => {
                /**
                 * Каждая строка - это id назначение юзера с ключом id назначения
                 */
                console.log('::callBatch res=', res);
                for(let key in res){
                    let usched_id = res[key];
                    let [tmp, uid] = key.split('_');
                   
                    $this.schedule.results.push({uid:uid, usid:usched_id}); //id записи назначения юзеру

                    /**
                     * Юзеру c uid, для каждого модуля программы добавляем задачу
                     *
                     */
                    let arBatchNotify = {};
                    const link = `[url=${$this.parent.appURL}?programs=${program.id}]${program.name}[/url]`;
                    let message = $this.message[$this.lang].program_message+ `<br><a target="_blank" href=${$this.parent.appURL}?programs=${program.id}>${program.name}</a>`;
                    
                    $this.parent.notifyLK(uid, message, $this.parent.user_current);

                }
                //всплывашка про сделанные назначения
                $this.showing_save_btn = true;
                setTimeout(() => {
                    $this.showing_save_btn = false;
                }, 3000);

                resolve(arBatch2);
            });
          }else{
              resolve(false);
          }


      });

    },
    addPassedParameter(arr) {
      return arr.map((item) => {
          if (Array.isArray(item)) {
              return this.addPassedParameter(item);
          } else {
              return {mid:item.id, mname:item.name, mtype:item.type, passed:'0', pt:item.pt};
          }
      });
    },
    loadSchedule(){
      const $this = this;
      return new Promise((resolve, reject) => {
        let params = {
          ENTITY: 'uni_progscheds',
          FILTER: { '1': 
            {
              LOGIC: "AND",
              NAME: $this.program.id,
              DETAIL_PICTURE: $this.parent.user_current.ID,
            } 
          },
          LIMIT: 1,
        };

        let url = $this.parent.bx24Init({ AJAX_ACTION: "entityItemGet", params: params });
        $this.parent.b24POST( url,  $this.parent.bx24params, function (res) {
            let items = res?.result;
            resolve(items);
          }, "json" );
        
      })

    },

    calcModuleDates(){
      this.schedule = this.schedule||{};
      let shift = 0;
      let start = dayjs().add(1, "day"); //Начинать завтра
      this.schedule.period={from:start.format("YYYY-MM-DD"), to:start.format("YYYY-MM-DD")}

      for(let i in this.program.modules){

        for(let item of this.program.modules[i]){
            let duration = parseInt(item.duration) || 0;
            item.period = item.period || {};
            item.period.from = start.add(shift, "day").format("YYYY-MM-DD");
            item.period.to = start.add(shift + duration, "day").format("YYYY-MM-DD 23:59:59");
            this.schedule.period.to = item.period.to;
            //Если конец задачи выпадает на выходной, перенести его на рабочий день
            let adshift=this.weekdays(item.period.to);
            if(adshift){
                item.period.to = dayjs(item.period.to).add(adshift, "day").format("YYYY-MM-DD 23:59:59");
                shift += adshift;
            }
            shift += duration;
            // console.log(':: calcModuleDates item=',item);
        }

      }
    },
    weekdays(date) {
      const dayOfWeek = dayjs(date).day();
      if(dayOfWeek === 0){
          return 1;
      }else if(dayOfWeek === 6){
          return 2;
      }else{
          return 0;
      }
    },
    
  },
  components: {

  },
};
</script>

<style scoped></style>
