<template>
  <div class="q-pa-none">
    <q-card class="bg-primary text-negative h4" style="width: 500px">
        <q-card-section>
          <div>{{message[lang].sub_acc_title}}</div>
        </q-card-section>
        <q-card-section class="bg-negative text-positive">
          <div class="row">
            <div class="col-8">
                <q-input
                    :disable="isLoginDisable"
                    v-model="login"
                    :rules="[ val => val.length > 0 || message[lang].requaried_field]"
                    @update:model-value="putLogin"
                    debounce="700"
                    :label="message[lang].sub_acc_login"
                    dense
                />
            </div>
            <div class="col-12 h5 text-primary" v-if="login_message">{{login_message}}</div>

            <div class="col-8" v-show="showPassword">
                <q-input
                    v-model="password"
                    type="password"
                    :rules="[ val => val.length > 0 || message[lang].requaried_field]"
                    :label="message[lang].sub_acc_password"
                    @update:model-value="validPassword"
                    dense
                />

            </div>
            <div class="col-8" v-show="showPassword2">
                <q-input
                    v-model="password2"
                    type="password"
                    :rules="[ val => val.length > 0 || message[lang].requaried_field]"
                    :label="message[lang].sub_acc_password2"
                    @update:model-value="validPassword"
                    dense
                />
            </div>
          </div>
          <!-- Реквизиты -->
          <div class="row" v-if="showRecvisits">
            <div class="col-12">{{message[lang].sub_acc_recv}}</div>
            <div class="col-8">
                <q-input v-model="first_name" :label="message[lang].sub_acc_first_name" dense/>
            </div>
            <div class="col-8">
                <q-input v-model="middle_name" :label="message[lang].sub_acc_middle_name" dense/>
            </div>
            <div class="col-8">
                <q-input v-model="last_name" :label="message[lang].sub_acc_last_name" dense/>
            </div>
            <div class="col-8">
                <q-input v-model="position" :label="message[lang].sub_acc_position" dense/>
            </div>
          </div>
          <div class="q-pa-md text-warning">
            <div>{{ err_message }}</div>
            <div>{{ err_message2 }}</div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="bg-negative q-pa-md">
          <q-btn v-if="showRecvisits" :loading="loading" :disabled="!validPassword || !validRecvisits" rounded no-caps unelevated :label="message[lang].sub_acc_save" color="primary" @click="saveAccount"/>
          <q-btn v-else-if="showPassword" :loading="loading" rounded no-caps unelevated :label="message[lang].sub_acc_ok" color="primary" @click="tryLogin"/>
          <q-btn v-if="validAuth && !showRecvisits" rounded no-caps unelevated :label="message[lang].sub_acc_recv" color="primary" @click="showRecvisits=true; showPassword2=true "/>
          <!-- <q-btn v-if="validAuth" rounded no-caps unelevated :label="message[lang].sub_acc_recv" color="positive" @click="delAccount"/> -->
        </q-card-actions>
      </q-card>
  </div>

</template>

<script>
import { Loading } from "quasar";
import {messageObj} from "src/helpers/message";
import ListOfMeth360Group from "./ListOfMeth360Group.vue";

export default {
  name: "SubAccountLogin",
  props:{
    flags:Object,
    parent:Object,
    lang:String,
  },
  data() {
    return {
      loading:false,
      user:{},
      login:'',
      password:'',
      password2:'',
      position:'',
      first_name:'',
      last_name:'',
      middle_name:'',
      showPassword:false,
      showPassword2:false,
      showRecvisits:false,
      isLoginDisable:false,
      login_message:'',
      err_message:'',
      err_message2:'',
      message: messageObj
    }
  },
  computed: {
    validPassword(){
      //Пароль не может быть пустым
      if(this.showPassword2 && (!this.password || this.password !== this.password2) ){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.err_message = this.message[this.lang].sub_acc_passwords_not_match;
        return false;
      }else{
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.err_message = '';
      }
      return true;
    },
    validRecvisits(){
      if(this.last_name && this.first_name){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.err_message2 = '';
        return true;
      }else{
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.err_message2 = this.message[this.lang].sub_acc_valid_recv;
      }
      return false;
    },
    validAuth(){
      return this.password && this.parent.myEx(this.password)==this.user.password;
    }
  },
  methods: {
    putLogin(){
      let $this = this;
      this.err_message = '';
      this.err_message2 = '';
      this.login_message = '';
      this.login = this.parent.replaceQuotesBySpace(this.login,'');
      this.getLogin(this.login).then((res)=>{
        if(res && res.result && res.result[0]){
          //Есть такой логин
          let item = res.result[0];
          if(this.parent.isJSON(item.DETAIL_TEXT)){
            this.user = JSON.parse(item.DETAIL_TEXT);
            this.user.first_name = this.parent.restoreQuotes(this.user.first_name);
            this.user.last_name = this.parent.restoreQuotes(this.user.last_name);
            this.user.middle_name = this.parent.restoreQuotes(this.user.middle_name);
            this.user.position = this.parent.restoreQuotes(this.user.position);
            if(!item.PREVIEW_TEXT){
              //Есть такой логин, но нет пароля - значит его завели модераторы
              //и при первом входе он должен ввести свои реквизиты
              this.showPassword2 = true;
              this.showRecvisits = true;
            }else{
              this.showPassword2 = false;
              this.showRecvisits = false;
            }
          }else if(!item.PREVIEW_TEXT){
              //Может не быть реквизитов если это регистрация модератором
              //с пустым паролем
              this.showPassword2 = true;
              this.showRecvisits = true;
          }
          //логин существует, менять нельзя
          this.isLoginDisable = true;
          this.user.native_id = this.parent.user_current.ID; //item.STUFF
          this.user.ID = item.ID;
          this.user.login = item.NAME;
          this.user.password = item.PREVIEW_TEXT;
          this.password2 = this.password;
          this.first_name = this.user.first_name||'';
          this.last_name = this.user.last_name||'';
          this.middle_name = this.user.middle_name||'';
          this.position = this.user.position||'';
          this.showPassword = true;
        }else if(this.flags.self_sign_up==='Y'){
          if(this.parent.subacTarif.total < this.parent.subacTarif.limit){
            //СамоРегистрация
            this.showPassword2 = true;
            this.showPassword = true;
            this.showRecvisits = true;
            this.login_message = this.message[this.lang].sub_acc_login_not_found;
          }else{
            $this.parent.notify_title = this.message[this.lang].sub_acc_login_not_found;
            $this.parent.notify_text=this.message[this.lang].sub_alert;
            $this.parent.notify_warning = true;
            $this.parent.notify_persistent = false;
            $this.parent.notify_no_btn = false;
            $this.parent.notify = true;
          }
        }else{
          this.login_message = this.message[this.lang].sub_acc_login_not_found;
        }
      })

    },
    saveAccount(){
      this.loading = true;
      this.doSaveAccount().then((res)=>{
        this.loading = false;
        this.$emit('sub_acc_login', res);
      })

    },
    doSaveAccount(){
      console.log('::doSaveAccount');
      let $this = this;

      let recvisits = {
        'first_name':this.parent.replaceQuotes(this.first_name),
        'middle_name':this.parent.replaceQuotes(this.middle_name),
        'last_name':this.parent.replaceQuotes(this.last_name),
        'position':this.parent.replaceQuotes(this.position),
      };

      let method = this.user.ID?'entityItemUpdate':'entityItemAdd';
      let params = {
          'ENTITY': 'uni_subaccounts',
          'NAME': this.login,
          'PREVIEW_TEXT': this.parent.myEx(this.password),
          'DETAIL_TEXT': JSON.stringify(recvisits),
          'ACTIVE': 'Y',
          'STUFF': this.parent.user_current.ID //user.native_id
      };
      if(this.user.ID){
          params['ID'] = this.user.ID;
      }
      let url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });
      return new Promise(function (resolve, reject) {
        $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
          console.log('::doSaveAccount res=', res);
          if(res && res.result){
            $this.user = {
              'first_name': $this.first_name,
              'middle_name': $this.middle_name,
              'last_name': $this.last_name,
              'position': $this.position,
              'native_id': $this.parent.user_current.ID
            }

            if(!$this.user.ID){
              $this.user.ID = parseInt(res.result);
            }
            resolve($this.user);
          }else{
            reject(false)
          }

        });
      });

    },
    tryLogin(){
      console.log('::tryLogin', this.parent.myEx(this.password));
      this.err_message = '';
      if(this.validAuth){
        this.$emit('sub_acc_login', this.user);
      }else{
        this.err_message = this.message[this.lang].sub_acc_password_fail;
      }
    },
    getLogin(login){
      const $this = this;
      return new Promise(function (resolve, reject) {
        let params = {
            ENTITY: 'uni_subaccounts',
            FILTER: {NAME: login},
        };
        let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });
        $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
          resolve(res)
        });

      });

    }

  }
}
</script>
