<template>
  <div>
    <!-- сжатый список комментариев -->
    <div v-if="mode==='raw'">
      <div v-if="usercomments.length">
        <div class="positive h5">{{message[lang].title}}</div>
        <div v-for="(ucom, index) in usercomments" :key="ucom.id">
          <div class="row items-center">
            <div class="col-3 h5" :class="ucom.deleted?'text-positive':'text-accent'">
                {{ucom.TEXT.user.name}} {{ucom.TEXT.time}}
            </div>
            <div class="col-7 h5" :class="ucom.deleted?'text-positive':'text-accent'">
              
              <span v-if="!ucom.deleted || parent.isModer" v-html="ucom.TEXT.text"></span>
              <span v-else>{{message[lang].comment_deleted}}</span>
            </div>
            <div class="col-2 text-center cursor-pointer h5">
              <q-chip
                v-if="ucom.id && !ucom.deleted"
                flat
                size="xs"
                clickable
                color="negative"
                :text-color="isLikedComment(ucom.id,index)?'primary':'positive'"
                :icon="isLikedComment(ucom.id,index)||ucom.likes?'thumb_up':'thumb_down'"
                @click="likeComment(ucom.id, index)"
              >
                <q-tooltip v-if="ucom.likes" class="bg-primary h-tt">{{ucom.likes}}</q-tooltip>
              </q-chip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Нормальный список -->
    <div v-else class="bg-dark text-accent" style="padding-left: 15px; padding-bottom: 15px;">
      <div v-if="mode==='list'" class="positive h4">{{message[lang].title}}</div>
      <div v-if="!loading">
        <!-- Список комментов       -->
        <div v-if="mode==='list' && usercomments?.length>0" class="q-gutter-md" style="width: 100%; max-width: 700px">
          <q-scroll-area :style="`height: ${usercomments.length*100>heght?heght:usercomments.length*100}px;`" >
          <q-card flat :bordered="!ucom.deleted" v-for="(ucom, index) in usercomments" :key="ucom.id" :class="ucom.deleted?'bg-positive':'bg-negative'">
            <q-card-section>
              <div class="row items-center">
                <div class="col-1">
                  <q-avatar v-if="ucom.TEXT.user.photo && !ucom.deleted">
                    <q-img :src="ucom.TEXT.user.photo"  />
                  </q-avatar>
                  <q-avatar v-else color="positive" text-color="negative" icon="perm_identity" />
                </div>
                <div class="col-9" :class="ucom.deleted?'text-positive':'text-accent'">
                  <b>{{ucom.TEXT.user.name}}</b> {{ucom.TEXT.time}}
                  <div v-if="!ucom.deleted || parent.isModer" v-html="ucom.TEXT.text"></div>
                  <div v-else>{{message[lang].comment_deleted}}</div>
                </div>
                <div class="col-2 text-center cursor-pointer">
                  <q-chip
                    v-if="ucom.id && !ucom.deleted"
                    flat
                    size="sm"
                    clickable
                    color="negative"
                    :text-color="isLikedComment(ucom.id,index)?'primary':'positive'"
                    :icon="isLikedComment(ucom.id,index)||ucom.likes?'thumb_up':'thumb_down'"
                    @click="likeComment(ucom.id, index)"
                  >
                    <q-tooltip v-if="ucom.likes" class="bg-primary h-tt">{{ucom.likes}}</q-tooltip>
                  </q-chip>
                  <span v-if="!ucom.deleted"  icon="quickreply" style="text-decoration: underline" class="text-primary text-right"  @click="replyForComment(ucom)">{{message[lang].reply}}</span>
                  <div v-if="ucom.deleted && parent.isModer" class="text-warning">
                    {{message[lang].comment_deleted}}
                  </div>
                  <q-btn :loading="!ucom.id" v-if="!ucom.deleted && (parent.isModer || ucom.TEXT.user.id==parent.user_current.ID)" flat round icon="clear" color="accent" @click="deleteComment(ucom, index)"/>
                  <q-btn :loading="!ucom.id" v-else-if="ucom.deleted && (parent.isModer || ucom.TEXT.user.id==parent.user_current.ID)" flat round icon="redo" color="accent" @click="deleteComment(ucom, index, 'undelete')"/>

                </div>
              </div>
            </q-card-section>
            </q-card>
          </q-scroll-area>

        </div>
        <!--  Написать коммент       -->
        <div class="row items-center q-mt-lg">
          <div class="col-2 text-center" v-if="mode==='list'">
            <q-avatar v-if="user_current.PERSONAL_PHOTO && !hide_fio">
              <q-img :src="user_current.PERSONAL_PHOTO"  />
            </q-avatar>
            <q-avatar v-else color="accent" text-color="negative" icon="perm_identity" />
            <div v-if="!hide_fio" class="text-positive h5 q-mx-sm">{{user_current.NAME}} </div>
            <div v-else class="text-positive h5 q-mx-sm">...</div>
          </div>
          <div class="col-10 text-accent">
            <q-editor ref="comment" v-model="newcomment" min-height="5rem" />
            <div class="row justify-between items-center q-my-md">
              <div class="col-4">
                <q-btn :disabled="mode==='list' && (!newcomment || !nrows)" rounded color="primary" :label="message[lang].save" @click="addComment"/>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import dayjs from "dayjs";

    export default {
        props:{
          lang:String,
          entityId:String,
          user_current:Object,
          parent:Object,
          mode:{
            type: String,
            default: 'list'
          },
          heght:{
            type: Number,
            default: 700
          },
          hide_fio:Boolean
        },
        data: function () {
            return {
              nrows:1,
              newcomment:'',
              usercomments:[],
              loading:true,
              pdfData:null,
              // emoji_face:"😀 😇 😈 😎 😐 😑 😕 😗 😙 😛 😟 😦 😧 😬 😮 😯 😴 😶 😁 😂 😃 😄 😅 😆 😉 😊 😋 😌 😍 😏 😒 😓 😔 😖 😘 😚 😜 😝 😞 😠 😡 😢 😣 😤 😥 😨 😩 😪 😫 😭 😰 😱 😲 😳 😵 😷 🙅 🙆 🙇 🙈 🙉 🙊 🙋 🙌 🙍 🙎 🙏 🗿 👤 👦 👧 👨 👩 👪 👫 👮 👯 👰 👱 👲 👳 👴 👵 👶 👷 👸 👹 👺 👻 👼 👽 👾 👿 💀 💁 💂 💃 💆",
              // emoji_face_arr:[],
              // emoji_face_show: false,
              // emoji_animals:"😸 😹 😺 😻 😼 😽 😾 😿 🙀 🐌 🐍 🐎 🐑 🐒 🐔 🐗 🐘 🐙 🐚 🐛 🐜 🐝 🐞 🐟 🐠 🐡 🐢 🐣 🐤 🐥 🐦 🐧 🐨 🐩 🐫 🐬 🐭 🐮 🐯 🐰 🐱 🐲 🐳 🐴 🐵 🐶 🐷 🐸 🐹 🐺 🐻 🐼 🐽 🐾 🐀 🐁 🐂 🐃 🐄 🐅 🐆 🐇 🐈 🐉 🐊 🐋 🐏 🐐 🐓 🐕 🐖 🐪",
              // emoji_animals_arr:[],
              // emoji_animals_show:false,
              // emoji_fauna:"🌱 🌴 🌵 🌷 🌸 🌹 🌺 🌻 🌼 🌽 🌾 🌿 🍀 🍁 🍂 🍃 🍄 🍅 🍆 🍇 🍈 🍉 🍊 🍌 🍍 🍎 🍏 🍑 🍒 🍓 🍔 🍕 🍖 🍗 🍘 🍙 🍚 🍛 🍜 🍝 🍞 🍟 🍠 🍡 🍢 🍣 🍤 🍥 🍦 🍧 🍨 🍩 🍪 🍫 🍬 🍭 🍮 🍯 🍰 🍱 🍲 🍳 🍴 🍵 🍶 🍷 🍸 🍹 🍺 🍻",
              // emoji_fauna_arr:[],
              // emoji_fauna_show:false,
              comments_id:[],

              message:{
                ru:{
                  title:"Комментарии",
                  save:"Записать",
                  reply:"Ответить",
                  like:"Нравится",
                  comment_deleted:"Комментарий удален"
                },
                en:{
                  title:"Comments",
                  save:"Record",
                  reply:"Reply",
                  like:"Like",
                  comment_deleted:"Comment deleted"
                },
                ua:{
                  title:"Коментарі",
                  save:"Записати",
                  reply:"Відповісти",
                  like:"Подобається",
                  comment_deleted:"Коментар видалено"
                },
                fr:{
                  title:"Commentaires",
                  save:"Enregistrer",
                  reply: "répondre",
                  like: "J'aime",
                  comment_deleted:"Commentaire supprimé"
                },
                it:{
                  title:"Commenti",
                  save:"Salva",
                  reply:"rispondi",
                  like:"Mi piace",
                  comment_deleted:"Commento cancellato"
                },
                de:{
                  title:"Kommentare",
                  save:"Brennen",
                  reply:"Antworten",
                  like:"Gefällt mir",
                  comment_deleted:"Kommentar gelöscht"
                },
                pl:{
                  title:"Komentarze",
                  save:"Nagraj",
                  reply:"Odpowiedz",
                  like: "Lubię to",
                  comment_deleted: "komentarz usunięty"
                },
                es:{
                  title: "Comentarios",
                  save: "Grabar",
                  reply: "Responder",
                  like:"me gusta",
                  comment_deleted: "comentario eliminado"
                },
                pt:{
                  title: "comentários",
                  save: "gravar",
                  reply:"responder",
                  like: "gosto",
                  comment_deleted: "comentário removido"
                },
                tr:{
                  title:"Yorumlar",
                  save:"Yaz",
                  reply:"Cevap ver",
                  like:"Beğen",
                  comment_deleted:"Yorum silindi"
                },
                vn:{
                  title:"Bình luận",
                  save:"Ghi Lại",
                  reply:"Trả lời",
                  like:"Thích",
                  comment_deleted: "Đã Xóa Bình luận"
                },
              },
            }
        },
        mounted: function(){
          // console.log('::Comments mounted entityId=', this.entityId, this.user_current);
          this.getComments().then(res=>{
            this.usercomments = res;
            // console.log('::Comments mounted entityId=', this.entityId, res);
            this.loading = false;
          });
          // this.emoji_face_arr = this.emoji_face.split(' ');
          // this.emoji_animals_arr = this.emoji_animals.split(' ');
          // this.emoji_fauna_arr = this.emoji_fauna.split(' ');
        },
        watch:{

        },
        methods: {
          getEmoji(ind, emoji_arr){
            this.newcomment += emoji_arr[ind];
            // this.$refs.comment.runCmd('paste',emoji_arr[ind]);
          },
          addComment(){
            // if(this.mode=='mode360'){
            //   this.$emit('save_btn', 'save');
            // }
            var user = {
              id: this.user_current.ID,
              name: this.hide_fio?'...':this.user_current.NAME + ' ' + this.user_current.LAST_NAME,
              photo: this.hide_fio?'':this.user_current.PERSONAL_PHOTO
            };
            this.$emit('save_btn', {comment:this.parent.removeTags(this.newcomment), user:user});
            if(this.newcomment) {
              var $this = this;
              var rightNow = new Date();
              var timestamp = Date.now() / 1000 | 0;
              var timeNow = dayjs().format('YYYY-MM-DD HH:mm:ss'); //rightNow.toISOString().slice(0, 19).replace(/T/g, " ");
              //var timeNow = rightNow.toISOString().slice(0,10).replace(/-/g,"");

              var text = {id: timestamp, user: user, time: timeNow, text: this.newcomment};
              var new_comment = {id: 0, TEXT: text};
              // console.log('::newComment',text);
              $this.usercomments.push(new_comment);
              // //send
              // console.log('::save new_comment ', new_comment)
              //
              // this.saveComments($this.usercomments);
              //
              this.saveComments([new_comment]);
              this.newcomment = '';
              this.nrows = 1;
            }
          },
          cancelComment(){
            this.newcomment='';
            this.nrows=1;
            this.deleteComments();
          },
          // emojiDecode(str){
          //   // let arr = [...str];
          //   let isReplace=false;
          //   let arr = str.split('#f#')
          //   console.log(':#f#: emojiDecode arr=',arr)
          //
          //   // for(let ch of arr){
          //   //   if(ch.startsWith('#f#')){
          //   //     let tmp = ch.split('#f#');
          //   //     console.log(':#f#: emojiDecode tmp=',tmp)
          //   //   }
          //   // }
          //   return str;
          // },
          // emojiEncode(str){
          //   console.log(str);
          //   let arr = [...str];
          //   let isReplace=false;
          //   for(let ch of arr){
          //     console.log(ch)
          //     let index = this.emoji_face_arr.indexOf(ch);
          //     if(index>=0){
          //       let del = arr.splice(index,1,`#f#${index}#f#`)
          //       console.log(':: found index=', index, del, arr);
          //       isReplace=true;
          //     }
          //   }
          //   if(isReplace){
          //     str=arr.join('');
          //   }
          //   console.log(str);
          //   return str;
          // },
          saveComments(comments){
            let arBatch={};
            for(let item of comments){
              let uniItem={
                'ENTITY': 'uni_comments',
                'NAME': this.entityId,
                'DETAIL_TEXT': JSON.stringify(item),
              }
              if(parseInt(item.id)){
                uniItem.ID = parseInt(item.id);
                arBatch['upd_comments_'+item.TEXT.id] = ['entityItemUpdate', uniItem];
              }else{
                arBatch['add_comments_'+item.TEXT.id] = ['entityItemAdd', uniItem];
                //Баллы за коммент
                this.parent.savePoints('M', this.parent.calculateChecksum(item.TEXT.id));
              }
              // console.log(':1:saveComments uniItem=',uniItem)
            }
            this.parent.callBatchIB(arBatch).then(res=>{
              console.log(':2:saveComments callBatchIB res=',res)
              //найти последний коммент и записать ему id
              for(let key in arBatch){
                if(res.result && res.result[key]){
                  let new_id = res.result[key];
                  let [upd, com, item_TEXT_id] = key.split('_')
                  let foundIndex = this.usercomments.findIndex(el=>{return el.TEXT.id == item_TEXT_id});
                  // console.log('::saveComments foundIndex=',foundIndex, item_TEXT_id, this.usercomments)
                  if(foundIndex>=0){
                    this.usercomments[foundIndex].id = new_id;
                  }
                }
              }

            });
          },
          // deleteComment(id, index){
          //   console.log('::deleteComment ', id);
          //   let arBatch={};
          //   let uniItem = {
          //     'ENTITY': 'uni_comments',
          //     'ID': id
          //   }
          //   arBatch['del_comments_'+id] = ['entity.item.delete', uniItem];
          //   this.parent.callBatch(arBatch);
          //   this.usercomments.splice(index,1);
          // },
          deleteComment(item, index, isUndelete){
            // console.log('::deleteComment ', item);
            item.deleted=isUndelete?'':'Y';
            this.usercomments.splice(index,1, item);
            this.saveComments([item]);
          },
          deleteComments(){
            console.log('::deleteComments ',this.comments_id);
            let arBatch={};
            for(let id of this.comments_id) {
              let uniItem = {
                'ENTITY': 'uni_comments',
                'ID': id
              }
              arBatch['del_comments_'+id] = ['entityItemDel', uniItem];
            }
            this.parent.callBatch(arBatch);
          },
          getComments(){
            let $this = this;
            let list=[];
            let nn=0;
            let params = {
              ENTITY: 'uni_comments',
              SORT: {ID: 'ASC'},
              FILTER: {'NAME': ''+this.entityId },
              LIMIT: 1000
            };

            return new Promise(resolve=> {
              let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });
              $this.parent.b24POST(url, $this.parent.bx24params, function (res) {

                let data = res.result;
                  for(let i in data){
                    try {
                      if($this.parent.isJSON(data[i].DETAIL_TEXT)) {
                        let obj = JSON.parse(data[i].DETAIL_TEXT);
                        obj.id = data[i].ID;
                        list.push(obj);
                      }
                    }catch (e) {
                      $this.comments_id.push(data[i].ID);
                      console.error(e);
                    }
                  }
                    // console.log('::getComments list=', list)
                    resolve(list);
              }, "json");
            });
          },
          isLikedComment(id,index){
            var islike=false;
            var comm = this.usercomments[index];
            var userlikes = comm['ar_likes'] && Object.keys(comm['ar_likes']).length!==-1 ? comm['ar_likes']:false;
            if(userlikes){
              islike=userlikes.indexOf(this.user_current['ID']) !== -1;
            }
            return islike;
          },
          likeComment(id, index){
            // console.log(':1243:likeComment '+id, index);
            var user_id = this.user_current['ID'];
            var comment = this.usercomments[index];
            var userlikes = comment['ar_likes']?comment['ar_likes']:[];
            // console.log(userlikes, user_id, userlikes.indexOf(user_id) );

            var idx = userlikes.indexOf(user_id); //ищем лайк от текущего юзера

            if( idx === -1 ){ //нет лайка, нпдо добавить
              var likes = this.usercomments[index]['likes'];
              likes = likes?parseInt(likes)+1:1;
              this.usercomments[index]['likes']=likes;

              if(!this.usercomments[index]['ar_likes'])
                this.usercomments[index]['ar_likes']=[];

              this.usercomments [index]['ar_likes'].push(user_id);

            }else {
              // console.log('allready liked');
              userlikes.splice(idx,1); //удалим лайк юзера из списка лайков

              this.usercomments[index]['ar_likes'] = userlikes;

              var likes = this.usercomments[index]['likes']; //сумма лайков
              likes = likes?parseInt(likes)-1:0; //отнимем 1
              likes = likes<0?0:likes;
              this.usercomments[index]['likes']=likes; //запишем обратно

            }
            // this.showComments=false;
            // this.$nextTick(function () {
            //   this.showComments=true; // => 'обновлено'
            //   // console.log(':: Обновлено', this.showComments);
            // });

            this.saveComments([this.usercomments[index]]);


          },
          replyForComment(ucom){
            this.newcomment+=' <div style="color:#6699FF"><i><b>'+
              ucom.TEXT.user.name+' :: '+ucom.TEXT.text.substr(0,100)+
              '... </b></i></div><div>---</div> ';

            // var div = document.getElementById('q-app');
            // div.scrollTop = div.scrollHeight - div.clientHeight;
            BX24.scrollParentWindow (100000, function(){
              console.log('::scrolled to bottom')
            });
          },
        },
        components: {

        }
    }
</script>

<style scoped>

</style>

