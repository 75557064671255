<template>
  <div>
      <q-file
        clearable
        v-model="listFiles"
        :label="uploadsubtitle[lang]"
        :hint="uploadtitle[lang]"
        @update:model-value="fileListChange"
        accept=".udata, .xls, .xlsx, application/vnd.ms-excel"
        borderless
        multiple
        use-chips
        class="bg-negative"
        style="padding-left:15px; border-radius: 25px !important;"
      >
        <template v-slot:prepend>
          <q-img src="img/secondary/doc/file.svg" style="height: 40px; width: 40px;"/>
        </template>
        <template v-slot:after v-if="listFiles && Object.keys(listFiles).length>0">
          <q-btn
            color="primary"
            dense
            icon="cloud_upload"
            round
            @click="upload"
            :disable="!listFiles||Object.keys(listFiles).length==0"
            :loading="isLoading"
          />
        </template>
      </q-file>
    <div class="row justify-between">
      <div class="col-4 positive h5">
        <q-toggle
          v-model="set_published"
          color="primary"
          :label="message[lang].set_published"
        />
      </div>
      <div class="col-6 text-right">
        <a class="text-positive body-2" target="_blank" :href="'./assets/lesson_import_example_'+lang+'.xlsx'">{{message[lang].obrazec_xlsx}}</a>
      </div>
    </div>

  </div>
</template>
<script>
// import fileUpload from './FileUpload.vue';
import XLSX from 'xlsx';
import dayjs from "dayjs";

export default {
    props:{
      lang:String,
      parent:Object
    },
    data: function () {
        return {
          set_published:false,
            countLoaded:0,
            maxCount:100000,
            alertMessage:'',
            isLoading:false,
            listFiles:'',
            uploadtitle:{
              ru:"Для загрузки уроков можно использовать файлы ранее выгруженные в формате .udata или .xlsx, шаблон  можно скачать справа под кнопкой",
              en:"To upload lessons, you can use previously uploaded .udata or .xlsx files. Template is available on the right under the button",
              ua:"Для завантаження уроків можна використовувати файли раніше вивантажені в форматі .udata або .xlsx, шаблон можна завантажити справа під кнопкою",
              fr:"Pour télécharger des leçons, vous pouvez utiliser des fichiers .udata ou .xlsx existants. Les templates sont accessibles à droite, sous le bouton",
              it:"Per caricare le lezioni è possibile usare file .udata o .xlsx precedentemente caricati. Il template è disponibile sulla destra sotto il pulsante",
              tr:"Ders yüklemek için önceden yüklenmiş .udata veya .xlsx dosyalarını kullanabilirsiniz. Şablon, düğmenin sağ tarafında bulunur",
              de:"Zum Hochladen von Lektionen können Sie zuvor hochgeladene .udata- oder .xlsx-Dateien verwenden. Vorlage ist rechts unter der Schaltfläche verfügbar",
              pl:"Aby przesłać lekcje, możesz użyć wcześniej przesłanych plików .udata lub .xlsx. Szablon jest dostępny po prawej stronie pod przyciskiem",
              pt:"Para fazer upload de aulas, você pode usar arquivos .udata ou .xlsx carregados anteriormente. O modelo está disponível à direita sob o botão",
              es:"Para cargar lecciones, puede usar archivos .udata o .xlsx cargados previamente. La plantilla está disponible a la derecha debajo del botón",
              vn:"Để tải bài học lên, bạn có thể sử dụng tệp .udata hoặc .xlsx đã tải lên trước đó. Mẫu có sẵn ở bên phải dưới nút",

            },
            uploadsubtitle:{
              ru:"Перетащите сюда файлы в формате .udata или .xlsx или кликните здесь для выбора файлов на диске.",
              en:"Drag your .udata or .xlsx files here, or click here to upload files from your disc.",
              ua:"Перетягніть сюди файли в форматі .udata або .xlsx або клікніть тут для вибору файлів на диску.",
              fr:"Faites glisser ici vos fichiers .udata ou .xlsx, ou cliquer ici pour télécharger les fichiers depuis votre PC.",
              it:"Trascina qui i tuoi file .udata o .xlsx, o fai click qui per caricare file dal tuo disco.",
              tr:".udata veya .xlsx dosyalarınızı buraya sürükleyin veya diskinizden dosya yüklemek için burayı tıklayın.",
              de:" Ziehen Sie Ihre .udata- oder .xlsx-Dateien hierher oder klicken Sie hier, um Dateien von Ihrer Disc hochzuladen.",
              pl:"Przeciągnij tutaj swoje pliki .udata lub .xlsx albo kliknij tutaj, aby przesłać pliki z dysku.",
              pt:"Arraste seus arquivos .udata ou .xlsx aqui, ou clique aqui para fazer upload de arquivos de seu disco.",
              es:"Arrastre sus archivos .udata o .xlsx aquí, o haga clic aquí para cargar archivos desde su disco.",
              vn:"Kéo tệp .udata hoặc .xlsx của bạn vào đây hoặc nhấp vào đây để tải tệp lên từ đĩa của bạn.",
            },

            message:{
              ru:{
                set_published:"Публиковать сразу",
                accept:"Неверный тип файла",
                max_file_size:"Размер файла не должен превышать 200Мб",
                upload:"Загрузить",
                obrazec_xlsx:"Скачать образец xlsx-файла для импорта",
                },
              en:{
                set_published:"Publish immediately",
                  accept: "Invalid file type",
                  max_file_size: "The file size should not exceed 200Mb",
                  upload:"Upload",
                  obrazec_xlsx:"Download a sample xlsx file for importing",
                },
              ua:{
                set_published:"Публікувати відразу",
                accept: "Неправильний тип файлу",
                max_file_size: "Розмір файлу не повинен перевищувати 200Мб",
                upload:"Завантажити",
                obrazec_xlsx:"Завантажити зразок xlsx-файлу для імпорту",
              },
              fr:{
                set_published:"Publier immédiatement",
                accept: "Type de fichier Incorrect",
                max_file_size: "La taille du fichier ne doit pas dépasser 200 Mo",
                Télécharger:"Télécharger",
                obrazec_xlsx:"Télécharger un modèle de fichier xlsx pour préparer votre importation",
              },
              it:{
                set_published:"Pubblica subito",
                accept: "Tipo di file non valido",
                max_file_size: "La dimensione del file non deve superare i 200 MB",
                upload:"Carica",
                obrazec_xlsx:"Scarica un file xlsx di esempio per l’importazione",
              },
              tr:{
                set_published:"Hemen yayınla",
                accept: "Geçersiz dosya türü",
                max_file_size: "Dosya boyutu 200Mb'ı geçmemelidir",
                upload:"Yüklemek",
                obrazec_xlsx:"İçe aktarmak için örnek bir xlsx dosyası indirin",
              },
              de:{
                set_published:"Sofort veröffentlichen",
                accept: "ungültiger Dateityp",
                max_file_size: "Die Dateigröße sollte 200 MB nicht überschreiten",
                upload:"Hochladen",
                obrazec_xlsx:"Laden Sie eine xlsx-Beispieldatei zum Importieren herunter",
              },
              pl:{
                set_published:"Opublikuj natychmiast",
                accept: "Nieprawidłowy typ pliku",
                max_file_size: "Rozmiar pliku nie powinien przekraczać 200 MB",
                upload:"Wgrywać",
                obrazec_xlsx:"Pobierz przykładowy plik xlsx do importu",
              },
              pt:{
                set_published:"Publicar imediatamente",
                accept: "Tipo de arquivo inválido",
                max_file_size: "O tamanho do arquivo não deve exceder 200Mb",
                upload:"Envio",
                obrazec_xlsx:"Baixe um arquivo xlsx de amostra para importação",
              },
              es:{
                set_published:"Publicar inmediatamente",
                accept: "Tipo de archivo invalido",
                max_file_size: "El tamaño del archivo no debe exceder los 200Mb.",
                upload:"Subir",
                obrazec_xlsx:"Descargue un archivo xlsx de muestra para importar",
              },
              vn:{
                set_published:"Xuất bản ngay lập tức",
                accept: "Loại tệp không hợp lệ",
                max_file_size: "Kích thước tệp không được vượt quá 200Mb",
                upload:"Tải lên",
                obrazec_xlsx:"Tải xuống tệp xlsx mẫu để nhập",
              },

            },
        }
    },
    mounted: function(){
        this.countLoaded=0;
        this.maxCount=this.parent.getMaxCountByTarif();
    },
     watch:{
        // lang: function(){
        //     console.log(':: lang',lang);
        // },
    },
    methods: {
      onRejectedOne (rejectedEntries) {
        console.log('::onRejectedOne ', rejectedEntries)
        if(rejectedEntries[0]) {

          // eslint-disable-next-line vue/no-mutating-props
          this.parent.notify_text='';
          for(let item of rejectedEntries){
            if(item.failedPropValidation == 'max-file-size') {
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify_text += item.file.name + ': ' + this.message[this.lang].max_file_size
            }else if(item.failedPropValidation == 'accept') {
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify_text = item.file.name + ': ' + this.message[this.lang].accept
            }

          }
          if(this.parent.notify_text) {
            // eslint-disable-next-line vue/no-mutating-props
            this.parent.notify = true;
          }
        }
      },
        fileListChange(listFiles){
            // console.log(':: fileListChange', listFiles);
            this.listFiles = listFiles;
        },
        upload(){
            console.log(':: upload', [...this.listFiles]);
          // eslint-disable-next-line vue/no-mutating-props
          this.isLoading=true;
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isShowImport=false;
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isImportLoading=true;

          if(Object.keys(this.listFiles).length > 0) {
              let item = this.listFiles.pop();
              this.parent.readFileFromDisk(item).then(data=> {
                console.log(':: data=',data);
                let b64data = data.data.substr(data.data.indexOf('base64,') + 7);
                let b64data2 = atob(b64data);
                if (data.name.indexOf('.xls') !== -1) {
                  console.log(':: read to xls');
                  let workbook = XLSX.read(b64data2, {type: "binary"});
                  // console.log(':: workbook=', workbook);
                  let first_sheet_name = workbook.SheetNames[0];
                  /* Get worksheet */
                  let worksheet = workbook.Sheets[first_sheet_name];
                  let lesson = {DETAIL_TEXT: '', PROPERTY_VALUES: {}};
                  let lessons = [];
                  let row_type = '';
                  let isEnd = false;
                  let emptyNums = 0;
                  let columns = ["A", "B", "C", "D"];
                  let link_id = 1;
                  for (let j = 1; j < 10010; j++) {
                    if (isEnd) {
                      // console.log(':**** isEnd *****: BREAK lesson=',lesson);
                      if (lesson.NAME) {
                        //  console.log(':: countLoaded=', this.countLoaded, '<',this.maxCount, lesson);
                        if (this.countLoaded < this.maxCount) {
                            // console.log(':**** isEnd *****: PUSH');
                            lessons.push(lesson);
                            this.countLoaded++;
                          }
                      }
                      break;
                    }

                    for (let i in columns) {
                      let col = columns[i];
                      let row = j;
                      let address_of_cell = col + row;
                      let cell = worksheet[address_of_cell];
                      /* Get the value */
                      let value = (cell ? cell.v : undefined);

                      // console.log(':'+address_of_cell+': value=|%s|', value);

                      console.log(col,':',value, (col === 'A' && value === 'LESSON'));
                      if (col === 'A' && value && value.startsWith('#')) {
                        //это комментарий
                        break;
                      } else if (col === 'A' && value === 'LESSON') {
                        if (lesson.NAME) {
                          console.log(':: countLoaded=', this.countLoaded, '<',this.maxCount, lesson);

                          if (this.countLoaded < this.maxCount) {
                            console.log(':*********: PUSH');
                            lessons.push(lesson);
                            this.countLoaded++;
                          } else {
                            isEnd = true;
                            break;
                          }
                        }
                        row_type = 'lesson';
                        lesson = {NAME:'', DETAIL_TEXT: '', DETAIL_PICTURE:'', PROPERTY_VALUES: {youtube:{},flags:{}, files:[] } };
                        emptyNums = 0;
                        break;
                      } else if (col === 'A' && value) {
                        row_type = value;
                        // break;
                      }
                      else if (col === 'B' && !value) {
                        emptyNums++;
                        if (emptyNums > 5 || row > 10000) {
                          isEnd = true;
                          if (lesson.NAME) {
                            if (this.countLoaded < this.maxCount) {
                              lessons.push(lesson);
                              this.countLoaded++;
                            } else {
                              isEnd = true;
                              break;
                            }
                          }

                        }
                        break;
                      }
                      // console.log(':'+address_of_cell+': row_type=', row_type, ' value=',value);
                      if (row_type === 'lesson') {
                        if (col === 'B') {
                          lesson.NAME = value;
                        } else if (col === 'C') {
                          lesson.DETAIL_TEXT = value;
                        }else if (col === 'D') {
                          lesson.DETAIL_PICTURE = value;
                        }
                      }
                      else if (( row_type === 'files' || row_type === 'flags') && col === 'B' && value) {
                        lesson.PROPERTY_VALUES[row_type] = value;
                      }
                      else if (row_type === 'youtube' && value) {
                        if(col === 'B') {
                          lesson.PROPERTY_VALUES[row_type].id = value;
                        }else if(col === 'C'){
                          if (value.startsWith('http')) {
                            let url = document.createElement('a');
                            url.href = value;
                            // https://youtu.be/_lT8JMyJGNs
                            // https://www.youtube.com/watch?v=_j3pGmiKvxU&feature=emb_logo
                            if (url.pathname === "/watch") {
                              value = url.search.split('&')[0].split('=')[1];
                            } else {
                              value = url.pathname.substr(1);
                            }
                            // //console.log('::this.videoId= ',url, url.href, url.pathname, this.videoId);
                          }

                          lesson.PROPERTY_VALUES[row_type].provider = value;
                          lesson.PROPERTY_VALUES[row_type] = JSON.stringify(lesson.PROPERTY_VALUES[row_type]);
                          // console.log(':: ', row_type, col, lesson.PROPERTY_VALUES[row_type])
                        }

                      }
                      else if (row_type.startsWith('link') && value ) {
                        if (col === 'B') {
                          lesson.PROPERTY_VALUES[row_type] = {label: '', href: ''};
                          lesson.PROPERTY_VALUES[row_type].label = value;
                        } else if (col === 'C') {
                          lesson.PROPERTY_VALUES[row_type].href = value;
                          lesson.PROPERTY_VALUES[row_type] = JSON.stringify(lesson.PROPERTY_VALUES[row_type]);
                        }
                      }
                    }
                    // console.log(':: lesson = ', lesson);

                  }
                  // console.log(':: lesson loaded from xls ', [...lessons]);
                  this.doSaveLesson(lessons);
                }
                else {
                  let json = decodeURIComponent(escape(atob(b64data2)));
                  // console.log(':: json=', json);
                  let lesson = JSON.parse(json);
                  // console.log(':: lesson=', lesson, JSON.parse(lesson));
                  //загрузим файлы теста
                  if (lesson.files) {
                    console.log(':: lesson.files=', lesson.files);
                    let count = Object.keys(lesson.files).length;
                    for (let j in lesson.files) {
                      let item = lesson.files[j];
                      // console.log(':: item=', item);
                      this.parent.saveFile(item).then(file_id => {
                        // console.log(':: ------ count=%i old_id=%i new_id=%i', count, item.ID, file_id);
                        if (lesson.PROPERTY_VALUES.files) {
                          lesson.PROPERTY_VALUES.files = lesson.PROPERTY_VALUES.files.replace('"I":' + item.ID, '"I":' + file_id);
                        }
                        count--;
                        if (count <= 0) {
                          // выполнились все
                          lesson.files = null;
                          if (this.countLoaded < this.maxCount) {
                            this.doSaveLesson([lesson]);
                            this.countLoaded++;
                          } else {
                            this.listFiles = [];
                            this.upload();
                          }
                        }

                      }, err => {
                        // console.log(err)
                      });

                    }
                  } else {
                    if (this.countLoaded < this.maxCount) {
                      this.doSaveLesson([lesson]);
                      this.countLoaded++;
                    } else {
                      this.parent.tarifAlert('lessons').then(isOk => {
                      });
                      this.listFiles = [];
                      this.upload();
                    }
                  }
                }
              });
            }else{
              this.isLoading=false;
              this.$emit('importLessons', true)
            }

        },
        doSaveLesson(lessons){

            let $this = this;
            if(lessons && Object.keys(lessons).length > 0) {
                let lesson = lessons.pop();
                console.log(':: doSavelesson lesson=', lesson);
                this.parent.getLessonByName(lesson.NAME).then(res=>{
                    // console.log('::getlessonByName res=',res);

                    let method = 'entityItemAdd';
                    let id=0;
                    if(res.result && res.result[0] && res.result[0].ID){
                        id = res.result[0].ID;
                        method = 'entityItemUpdate';
                    }

                    let params = {
                        'ENTITY': 'uni_lessons',
                        'PREVIEW_TEXT': $this.parent.categoria,
                        'NAME': lesson.NAME,
                        // 'DETAIL_TEXT': lesson.DETAIL_TEXT,
                        'DATE_CREATE': dayjs().format('DD.MM.YYYY'),
                        'ACTIVE':$this.set_published?'Y':'N',
                        'DATE_ACTIVE_FROM':'',
                        'PROPERTY_VALUES': lesson.PROPERTY_VALUES
                    };
                    if(id){
                        params.ID=id;
                    }
                    console.log(':1:doSaveLesson params=', params);
                    // BX24.callMethod(method, params, function (res) {
                  var url = this.parent.bx24Init({ AJAX_ACTION: method, params:params });//.then(params=> {
                  $this.parent.bx24params.DETAIL_TEXT = lesson.DETAIL_TEXT;
                  $this.parent.bx24params.DETAIL_PICTURE = lesson.DETAIL_PICTURE;
                  $this.parent.b24POST(url, this.parent.bx24params, function (res) {
                    console.log(':2:doSaveLesson res= ',res);
                    // if (res.answer.error) {
                    //         console.error(res.answer.error_description);
                    //     } else {
                            if (res.result) {
                                let lesson_id = res.result;
                                if(id){
                                    console.log('lesson updated! id=', id);
                                }else {
                                    console.log('New lesson added! id=', lesson_id);
                                }
                            }
                        // }
                        $this.doSaveLesson(lessons)
                    });

                });
            }else{
                $this.upload();
            }
        },
    },
    components: {
     // fileUpload
    }
}
</script>

<style scoped>


 </style>

