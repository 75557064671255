<template>
  <span>
<!--  <q-spinner-oval v-if="isLoading"  color="positive" size="30px"/>-->
  <q-btn v-if="id"
         flat
         round
         color="positive"
         :icon="isClone?'file_copy':'file_download'"
         @click="exportTest"
         :loading="isLoading"
  >
    <q-tooltip class="bg-primary h-tt">{{isClone?message[lang].clone:message[lang].export}}</q-tooltip>
  </q-btn>
    <q-spinner v-else-if="list && isLoading"
               color="positive"
               class="q-mt-md"
               size="2em"
    />
  <q-img v-else-if="list" style="cursor: pointer; height: 30px; width: 30px; margin-top:16px" @click="exportListTests" src="~assets/secondary/doc/export.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].export}}</q-tooltip></q-img>
</span>
</template>
<script>
    export default {
        props:{
          lang:String,
          id:String,
          list:Object,
          parent:Object,
          isClone:Boolean
        },
        data: function () {
            return {
                isLoading:false,
                files:[],
                test:'',
                jsonList:'',
                message:{
                    ru:{
                        export:"Экспорт",
                      clone:"Создать копию",
                    },
                    en:{
                        export:"Export",
                      clone: "Create a copy",
                    },
                  ua:{
                    export:"Експорт",
                    clone: "створити копію",
                  },
                  fr:{
                    export:"Export",
                    clone: "Créer une copie",
                  },
                  it:{
                    export:"Esporta",
                    clone: "crea una copia",
                  },
                  tr:{
                    export:"İhracat",
                    clone:"Kopya oluştur",
                  },
                  de:{
                    export:"Export",
                    clone:"Kopie erstellen",
                  },
                  pl:{
                    export:"Eksport",
                    clone: "Utwórz kopię",
                  },
                  pt:{
                    export:"Exportar",
                    clone: "criar uma cópia",
                  },
                  es:{
                    export:"Exportar",
                    clone: "Crear una copia",
                  },
                  vn:{
                    export:"Xuất khẩu",
                    clone: "Tạo một bản sao",
                  },

                },
            }
        },
        mounted: function(){

        },
        methods: {
            exportListTests(){
                this.isLoading = true;
                this.jsonList=[];
                this.exportTest();
            },
            exportTest() {
                let $this = this;
                // console.log('::exportTest ', this.id, this.list);
                if(this.id) {
                    this.isLoading = true;
                }
                let test_id = this.id ? parseInt(this.id) : 0;
                if (this.list && Object.keys(this.list).length > 0) {
                  // eslint-disable-next-line vue/no-mutating-props
                    let item = this.list.pop();
                    test_id = parseInt(item.ID);
                }
                //
                if(test_id){
                    this.parent.getTestById(test_id).then(test => {
                        // console.log('::test=', test);
                        //проверим на наличие файлов в ответах
                      let answers='';
                      let questions='';
                      if(this.parent.isJSON(test.DETAIL_TEXT)) {
                        const tmp = JSON.parse(test.DETAIL_TEXT);
                        questions = tmp.questions;
                        answers = tmp.answers;
                      }else if(this.parent.isJSON(test.PROPERTY_VALUES.answers)){
                        answers = JSON.parse(test.PROPERTY_VALUES.answers);
                      }
                        let files_id = [];
                        let files = [];
                        if (answers) {
                            // let answers = JSON.parse(test.PROPERTY_VALUES.answers);
                            // console.log(':: answers=', answers);
                            for (let i in answers) {
                                let answer = answers[i];
                                for (let j in answer) {
                                    let question = answer[j];
                                    if (question.file) {
                                        files_id.push(question.file);
                                    }
                                }
                            }
                        }

                        if (Object.keys(files_id).length > 0) {
                            this.loadFiles(test_id, this.id, files_id, test, files);
                        } else {
                            let json = JSON.stringify(test);

                            if(this.id) {
                              if(this.isClone){
                                this.$emit('cloneExpTest', {id: test_id, data: json});
                              }else {
                                this.$emit('saveExpTest', {id: test_id, data: json});
                              }
                              this.isLoading = false;
                            }else{
                                this.jsonList.push({id: test_id, data: json});
                                this.exportTest();
                            }
                        }
                    }, err => {
                        console.error(':: err=', err);
                    });
                }else{
                    this.isLoading = false;
                    this.$emit('saveExpList', this.jsonList);
                }
            },
            loadFiles(test_id, isLoadingToFalse, files_id, test, files){
                // console.log('::loadFiles test_id=',test_id);
                let $this = this;
                if (Object.keys(files_id).length > 0) {
                    let file_id = files_id.pop();
                    $this.parent.loadFile(file_id).then(file=>{
                        // console.log(':: test_id=%s file_id=%s', test_id, file_id);
                        files.push( {ID:file.ID, NAME:file.NAME, DETAIL_TEXT:file.DETAIL_TEXT} );
                        $this.loadFiles(test_id, isLoadingToFalse, files_id, test, files);
                    }, err=>console.error(err));
                } else {
                    test.files=files;
                    let json=JSON.stringify(test);
                    // console.log(':end: files loaded test_id=', test_id);
                    if(isLoadingToFalse) {
                      if(this.isClone){
                        this.$emit('cloneExpTest', {id: test_id, data: json});
                      }else {
                        this.$emit('saveExpTest', {id: test_id, data: json});
                      }
                      this.isLoading = false;
                    }else{
                        this.jsonList.push({id: test_id, data: json});
                        this.exportTest();
                    }
                }
            },


        },
        components: {

        }
    }
</script>

<style scoped>


</style>

