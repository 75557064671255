<template>
  <div style="padding-left: 5px; padding-bottom: 100px;">
    <div v-if="parent.isMobile" class="h4 q-ml-md" style="margin-top: -20px">
      {{message[lang].title}}
      <q-btn flat round color="secondary" icon="refresh" @click="loadTests(true)"></q-btn>
      <q-btn flat round color="positive" :icon="isListViewTest?'dashboard':'list'" @click="isListViewTest=!isListViewTest"></q-btn>
    </div>
    <!-- /isMobile-->
<!--    <div v-else class="row items-center justify-between q-mt-sm" style="margin-top: -10px">-->
    <div v-else class="row items-center justify-between q-mt-sm">
        <div class="col-6 text-left q-mb-2">
        <div class="row items-center justify-between">
          <div class="col-8 h4">
            {{message[lang].title}}
            <q-btn flat round color="secondary" icon="refresh" @click="loadTests(true)">
              <q-tooltip class="bg-primary h-tt">{{message[lang].refresh}}</q-tooltip>
            </q-btn>
            <q-btn flat round color="positive" :icon="isListViewTest?'dashboard':'list'" @click="isListViewTest=!isListViewTest">
              <q-tooltip v-if="isListViewTest" class="bg-primary h-tt">{{messageObj[lang].view_cards}}</q-tooltip>
              <q-tooltip v-else class="bg-primary h-tt">{{messageObj[lang].view_list}}</q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
      <div v-if="parent.isModer || parent.isAdmin" class="col-6 text-right q-gutter-md" style="padding-right: 10px">
        <q-btn padding="xs" :loading="isLoading" size="lg" flat round color="secondary" icon="add_circle_outline" @click="addNewTest"><q-tooltip class="bg-primary h-tt">{{message[lang].add}}</q-tooltip></q-btn>
        <q-btn padding="xs" :loading="isImportLoading" size="lg" flat round color="positive" icon="file_upload" @click="importTestTogle"><q-tooltip class="bg-primary h-tt">{{message[lang].import}}</q-tooltip></q-btn>

<!--        <q-img  v-if="!isLoading" style="height: 30px; width: 30px; cursor: pointer" @click="addNewTest" src="~assets/secondary/doc/add.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].add}}</q-tooltip></q-img>-->
<!--        <q-img  v-else  style="height: 30px; width: 30px;" src="~assets/secondary/doc/add_gray.svg"/>-->
<!--        <q-spinner-oval v-if="isImportLoading"  color="text-positive" size="22px"/>-->
<!--        <q-img v-else style="cursor: pointer; height: 30px; width: 30px;" @click="importTestTogle" src="~assets/secondary/doc/import.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].import}}</q-tooltip></q-img>-->
<!--        <test-export v-if="parent.listTests" v-bind:lang="lang"  v-bind:id="0" v-bind:list="[...fltListTests]" v-bind:parent="parent" @saveExpList="saveExpList" ></test-export>-->

        <!--  Линк для скачивания в десктопном приложении    -->
        <div v-if="file_download_loading">
          <q-spinner-oval color="positive" size="30px"/>
        </div>
        <div class="text-positive h4" v-else-if="file_download_link">
          {{message[lang].download}}: <span class="text-right text-primary"  v-html="file_download_link"/>
        </div>
      </div>
    </div>

    <div class="row justify-end" v-if="test_tags_opt && test_tags_opt.length>0">
      <div class="col-3">
        <q-select
          ref="TagsSelect"
          debounce="500" v-model="test_tags_find"
          :options="flt_test_tags_opt"
          :label="message[lang].tags"
          use-input use-chips
          @input-value="filterTags"
        />
      </div>
    </div>

    <div class="row justify-center" v-if="isShowImport">
      <div class="col-8">
        <test-import v-bind:lang="lang" v-bind:parent="parent"  @importTests="importTests" ></test-import>
      </div>
    </div>

    <div v-if="isListViewTest">
      <q-list separator>
        <q-item clickable v-ripple v-for="(item,index) in fltListTests" :key="item.ID">
          <q-item-section avatar v-if="item.DETAIL_PICTURE">
            <q-avatar rounded>
              <q-img
                class="rounded-borders"
                :src="item.DETAIL_PICTURE"
                spinner-size="2rem"
                spinner-color="positive"
              />
            </q-avatar>
          </q-item-section>

          <q-item-section @click="viewTest(item)">
            <q-item-label class="text-positive h5">
              <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)">{{item.ID}}</span>
              <span v-if="!razdel">{{parent.showCategoria(item.PREVIEW_TEXT)}}</span>
            </q-item-label>
            <q-item-label class="text-positive h4" lines="2">{{item.NAME}}</q-item-label>
            <div v-if="item.CLOSED && (parent.isAdmin || parent.isModer)" class="text-left">
              <q-separator/>
              <div class="text-positive h5">--{{message[lang].closed_groups}}---</div>
              <div v-for="groupItem in item.CLOSED" :key="groupItem.group_id">
                <a class="text-positive h5" target="_blank" :href="'https://'+parent.portal+'/workgroups/group/'+groupItem.group_id+'/general/'">
                  {{groupItem.group_name}}
                </a>
              </div>
            </div>
          </q-item-section>

          <q-item-section  side top>
           <rating 
              v-bind:parent="parent" 
              v-bind:lang="lang"
              v-bind:entityId="'rt_'+item.ID"
              v-bind:icon="'star'"
              v-bind:icon_half="'star_half'"
              v-bind:color="'yellow-13'"
              v-bind:size="'1em'"

              />
            <q-item-label class="text-positive h5">
              <q-icon name="date_range" :color="availPeriods(item)?'positive':'warning'" size="sm"/>
              {{formatDate(item)}}
            </q-item-label>
            <div class="q-gutter-xs" v-if="parent.isModer" align="right">
              <q-icon :name="item.ACTIVE === 'Y'?'done_all':'remove_done'" :color="item.ACTIVE === 'Y'?'secondary':'warning'" size="sm">
                <q-tooltip v-if="item.ACTIVE === 'Y'" class="bg-primary h-tt">{{message[lang].published}}</q-tooltip>
                <q-tooltip v-else class="bg-warning h-tt">{{message[lang].notpublished}}</q-tooltip>
              </q-icon>
              <q-icon v-if="item.HIDE" name="hide_source" color="positive" size="sm">
                <q-tooltip class="bg-primary h-tt">{{parent.message[lang].hide_cat}}</q-tooltip>
              </q-icon>
              <q-btn flat round color="positive" icon="visibility" @click="viewTest(item)">
                <q-tooltip class="bg-primary h-tt">{{message[lang].test}}</q-tooltip>
              </q-btn>
              <q-btn flat round color="positive" icon="edit" @click="editTest(item)">
                <q-tooltip class="bg-primary h-tt">{{message[lang].edit}}</q-tooltip>
              </q-btn>
              <q-btn flat round color="positive" icon="delete_outline" @click="showModalDel(item,index)">
                <q-tooltip class="bg-primary h-tt">{{message[lang].remove}}</q-tooltip>
              </q-btn>
              <test-export  v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" @saveExpTest="saveExpTest" ></test-export>
              <test-export  v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" v-bind:is-clone="true" @cloneExpTest="cloneExpTest" ></test-export>
            </div>
          </q-item-section>

        </q-item>
      </q-list>
    </div>
    <!--Карточки тестов-->
    <div v-else class="q-pa-md row items-start q-gutter-md">
      <q-card class="cours-card q-pa-sm" v-for="(item,index) in fltListTests" :key="item.ID">
        <q-card-section>
          <div class="row justify-between items-center">
            <div class="col-8 q-gutter-x-md">
              <q-btn unelevated color="secondary" @click="viewTest(item)">
                {{message[lang].test}}
                <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)">_{{item.ID}}</span>
              </q-btn>
              <q-img v-if="item.ACTIVE === 'Y'" style="cursor: pointer; height: 26px; width: 26px; margin-left: 10px"  src="img/card/cours/secondary/doc/published.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].published}}</q-tooltip></q-img>
              <q-img v-else style="cursor: pointer; height: 26px; width: 26px; margin-left: 10px"  src="img/card/cours/secondary/doc/draft.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].notpublished}}</q-tooltip></q-img>
              <q-icon v-if="parent.isModer && item.HIDE" name="hide_source" color="positive" size="md">
                <q-tooltip class="bg-primary h-tt">{{parent.message[lang].hide_cat}}</q-tooltip>
              </q-icon>
            </div>
            <div class="col-4 text-right q-gutter-x-sm">
              <q-icon name="date_range" :color="availPeriods(item)?'positive':'warning'" size="sm"/>
              {{formatDate(item)}}
              <q-badge v-if="testComments['test_'+item.ID]" rounded  color="secondary" :label="message[lang].comments +testComments['test_'+item.ID]"/>
            </div>
            <div class="col-12 positive h5 q-pt-xs">{{parent.showCategoria(item.PREVIEW_TEXT)}}</div>
          </div>
        </q-card-section>

        <q-card-section class="cursor-pointer" horizontal @click="viewTest(item)">
          <q-card-section v-if="item.DETAIL_PICTURE" class="col-5 flex flex-center">
            <q-img
              class="rounded-borders"
              :src="item.DETAIL_PICTURE"
              spinner-size="2rem"
              spinner-color="positive"
            />
          </q-card-section>

          <q-card-section class="q-pa-sm text-positive h4">
            {{item.NAME}}
          </q-card-section>

        </q-card-section>

        <q-card-section class="q-pa-sm text-positive h4">
            <rating 
              v-bind:parent="parent" 
              v-bind:lang="lang"
              v-bind:entityId="'rt_'+item.ID"
              v-bind:icon="'star'"
              v-bind:icon_half="'star_half'"
              v-bind:color="'yellow-13'"
              v-bind:size="'1em'"

              />
        </q-card-section>

        <q-separator/>

        <q-card-actions v-if="parent.isModer || parent.isAdmin" align="right">
          <q-btn size="sm" flat round color="positive" icon="visibility" @click="viewTest(item)">
            <q-tooltip class="bg-primary h-tt">{{message[lang].test}}</q-tooltip>
          </q-btn>
          <q-btn v-if="parent.isModer || parent.isAdmin" flat round color="positive" icon="edit" @click="editTest(item)">
            <q-tooltip class="bg-primary h-tt">{{message[lang].edit}}</q-tooltip>
          </q-btn>
          <q-btn v-if="parent.isModer || parent.isAdmin" flat round color="positive" icon="delete_outline" @click="showModalDel(item,index)">
            <q-tooltip class="bg-primary h-tt">{{message[lang].remove}}</q-tooltip>
          </q-btn>
          <test-export v-if="parent.isModer || parent.isAdmin"  v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" @saveExpTest="saveExpTest" ></test-export>
          <test-export v-if="parent.isModer || parent.isAdmin"  v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" v-bind:is-clone="true" @cloneExpTest="cloneExpTest" ></test-export>
        </q-card-actions>

        <q-card-section v-if="item.CLOSED && (parent.isAdmin || parent.isModer)" class="text-left">
          <q-separator/>
          <div class="text-positive h4">--{{message[lang].closed_groups}}---</div>
          <div v-for="groupItem in item.CLOSED" :key="groupItem.group_id">
            <a class="positive h4" target="_blank" :href="'https://'+parent.portal+'/workgroups/group/'+groupItem.group_id+'/general/'">
              {{groupItem.group_name}}
            </a>
          </div>
        </q-card-section>
      </q-card>
    </div>

    <div v-if="parent.justSearched && Object.keys(parent.listTests).length === 0">
      {{message[lang].notfound}}
    </div>
    <div class="text-center" v-else-if="isMore">
      <q-btn v-if="isListViewTest" @click="getTestsVsClosed(true)" round flat color="positive" icon="keyboard_arrow_down" />
      <q-btn v-else @click="getTestsVsClosed(true)" class="glossy" size="lg" round color="primary" icon="keyboard_arrow_down" />
    </div>
    <div v-if="isLoading" class="text-center"><q-spinner-oval color="accent" size="48px"/></div>


  </div>

</template>
<script>
    import dayjs from 'dayjs';
    import testExport from "./testExport.vue";
    import testImport from "./testImport.vue";
    import {saveAs} from "file-saver";
    import XLSX from 'xlsx';
    import {messageObj} from "src/helpers/message";
    import rating from "src/components/Rating.vue";

    export default {
        props:{
            parent:Object,
            lang:String,
            categoria:String,
            back:String,
        },
        data: function () {
            return {
              isListViewTest:false,
              testComments:{},
              flt_test_tags_opt:[],
              test_tags_find:null,
              test_tags_opt:'',
              file_download_link:'',
              file_download_loading:false,
              start:0,
              total:0,
              count:15,

              maxCount:3,
              goback:'',
              isLoading:false,
              isImportLoading:false,
              isShowImport:false,
              // listTests:[],
              pics_tests:[],
              test_id:0,
              test_idx:-1,
              lastCategoria:-1,
              expFormat_selected:'xlsx',
              level_options: {
                  ru: [
                      {text: 'легкий', value: 'low'},
                      {text: 'средний', value: 'moderate'},
                      {text: 'сложный', value: 'high'},
                  ],
                  en: [
                      {text: 'low', value: 'low'},
                      {text: 'moderate', value: 'moderate'},
                      {text: 'high', value: 'high'},
                  ],
                ua: [
                  {text: 'легкий', value: 'low'},
                  {text: 'середній', value: 'moderate'},
                  {text: 'складний', value: 'high'},
                ],
                fr: [
                  {text: 'faible', value: 'low'},
                  {text: 'moyen', value: 'moderate'},
                  {text: 'fort', value: 'high'},
                ],
                it: [
                  {text: 'basso', value: 'low'},
                  {text: 'moderato', value: 'moderate'},
                  {text: 'alto', value: 'high'},
                ],
                tr: [
                  {text: 'düşük', value: 'low'},
                  {text: 'orta', value: 'moderate'},
                  {text: 'yüksek', value: 'high'},
                ],
                de: [
                  {text: 'niedrig', value: 'low'},
                  {text: 'mäßig', value: 'moderate'},
                  {text: 'hoch', value: 'high'},
                ],
                pl: [
                  {text: 'niska', value: 'low'},
                  {text: 'umiarkowany', value: 'moderate'},
                  {text: 'wysoka', value: 'high'},
                ],
                pt: [
                  {text: 'baixo', value: 'low'},
                  {text: 'moderado', value: 'moderate'},
                  {text: 'alto', value: 'high'},
                ],
                es: [
                  {text: 'bajo', value: 'low'},
                  {text: 'moderado', value: 'moderate'},
                  {text: 'alto', value: 'high'},
                ],
                vn: [
                  {text: 'thấp', value: 'low'},
                  {text: 'vừa phải', value: 'moderate'},
                  {text: 'cao', value: 'high'},
                ],

              },
              typeTest_options: {
                  ru: [
                      {text: 'Итоговый', value: 'final'},
                      {text: 'Промежуточный', value: 'midpoint'},
                  ],
                  en: [
                      {text: 'Final', value: 'final'},
                      {text: 'Midpoint', value: 'midpoint'},
                  ],
                ua: [
                  {text: 'підсумковий', value: 'final'},
                  {text: 'проміжний', value: 'midpoint'},
                ],
                fr:[
                  {text: 'Final', value: 'final'},
                  {text:'Moyenne', value: 'midpoint'},
                ],
                it: [
                  {text: 'Finale', value: 'final'},
                  {text: 'Intermedio', value: 'midpoint'},
                ],
                tr: [
                  {text: 'Son', value: 'final'},
                  {text: 'Orta nokta', value: 'midpoint'},
                ],
                de: [
                  {text: 'Finale', value: 'final'},
                  {text: 'Mittelpunkt', value: 'midpoint'},
                ],
                pl: [
                  {text: 'Finał', value: 'final'},
                  {text: 'Środek', value: 'midpoint'},
                ],
                pt: [
                  {text: 'Final', value: 'final'},
                  {text: 'Ponto médio', value: 'midpoint'},
                ],
                es: [
                  {text: 'Final', value: 'final'},
                  {text: 'Punto medio', value: 'midpoint'},
                ],
                vn: [
                  {text: 'Cuối cùng', value: 'final'},
                  {text: 'Điểm giữa', value: 'midpoint'},
                ],

              },
              typeQuest_options: {
                ru: [
                  {text: 'Выбор', value: 'qa'},
                  {text: 'Последовательность', value: 'seq'},
                  {text: 'Соответствие', value: 'eqw'},
                  {text: 'Открытый', value: 'opn'},
                  {text: 'Подстановка', value: 'sub'}
                ],
                en: [
                  {text: 'Option', value: 'qa'},
                  {text: 'Sequencing', value: 'seq'},
                  {text: 'Matching', value: 'eqw'},
                  {text: 'Open', value: 'opn'},
                  {text: 'Substitution', value: 'sub'}
                ],
                ua: [
                  {text: 'вибір', value: 'qa'},
                  {text: 'послідовність', value: 'seq'},
                  {text: 'відповідність', value: 'eqw'},
                  {text: 'Відкритий', value: 'opn'},
                  {text: 'Підстановка', value: 'sub'}
                ],
                fr:[
                  {text: 'Choix multiple', value: 'qa'},
                  {text: 'Séquence', value: 'seq'},
                  {text: 'Correspondance', value: 'eqw'},
                  {text: 'Ouvert', value: 'opn'},
                  {text: 'Substitution', value:'sub'}
                ],
                it: [
                  {text: 'Opzione', value: 'qa'},
                  {text: 'Sequenziamento', value: 'seq'},
                  {text: 'Matching', value: 'eqw'},
                  {text: 'Aperto', value: 'opn'},
                  {text: 'Sostituzione', value:'sub'}
                ],
                tr: [
                  {text: 'Seçenek', value: 'qa'},
                  {text: 'Sıralama', value: 'seq'},
                  {text: 'Eşleştirme', value: 'eqw'},
                  {text: 'Açık', value: 'opn'},
                  {text: 'Ikame', value: 'sub'}
                ],
                de: [
                  {text: 'Möglichkeit', value: 'qa'},
                  {text: 'Sequenzierung', value: 'seq'},
                  {text: 'Passende', value: 'eqw'},
                  {text: 'Offen', value: 'opn'},
                  {text: 'Substitution', value: 'sub'}
                ],
                pl: [
                  {text: 'Opcja', value: 'qa'},
                  {text: 'Sekwencjonowanie', value: 'seq'},
                  {text: 'Dopasowanie', value: 'eqw'},
                  {text: 'Otwarty', value: 'opn'},
                  {text: 'Podstawienie', value: 'sub'}
                ],
                pt: [
                  {text: 'Opção', value: 'qa'},
                  {text: 'Sequenciamento', value: 'seq'},
                  {text: 'Coincidindo', value: 'eqw'},
                  {text: 'Abrir', value: 'opn'},
                  {text: 'Substituição', value:'sub'}
                ],
                es: [
                  {text: 'Opción', value: 'qa'},
                  {text: 'Secuenciación', value: 'seq'},
                  {text: 'Pareo', value: 'eqw'},
                  {text: 'Abierto', value: 'opn'},
                  {text: 'Sustitución', value:'sub'}
                ],
                vn: [
                  {text: 'Lựa chọn', value: 'qa'},
                  {text: 'Giải trình tự', value: 'seq'},
                  {text: 'Phù hợp', value: 'eqw'},
                  {text: 'Mở ra', value: 'opn'},
                  {text: 'Thay', value: 'sub'}
                ],

              },
              message: {
                  ru: {
                    QUEST_BLOCK_NAME:"Название блока",
                    QUEST_BLOCK_ID:"N блолка",
                    QUEST_BLOCK_COUNT:"Кол-вл вопросов",
                    QUEST_BLOCK_RAND:"Случайно",

                    TEST_FLAGS:"Служебные отметки",
                    LOGO_PICTURE:"Логотип",
                    TEST_BLOCKS:"Блоки",
                    QUEST_BLOCK:"Блок",

                    comments:"К.",
                    direct_link:"Прямая ссылка",
                    copy:"Копия",
                    tags:"Тэги",
                    download:"Скачать",
                    SHOW_RESULTS: "Показывать результаты",
                    NO_PREV: "Запретить возвращаться и менять ответ",
                    edit: "Редактировать",
                    refresh: "Обновить список",
                    date_pub: "Дата публикации",
                    status: "Статус",
                    goback: "Вернуться",
                    title: "Список тестов",
                    loading: "Загрузка",
                    remove: "Удалить",
                    add: "Добавить тест",
                    test: "Тест",
                    confirm_del: "Подтвердите удаление",
                    cancel: "Отмена",
                    ok: "Да",
                    notpublished: "Черновик",
                    published: "Опубликован",
                    notfound: "Ничего не найдено",
                    attention: "Удаление невозможно! Сначала удалите тест из курсов",
                    alert_understood: "Понятно",
                    import: "Импорт",
                    testview: "Пройти",
                    testedit: "Редактировать",
                    testdel: "Удалить",
                    NAME: "Название теста",
                    THEMATICS: "Тематика",
                    DESCRIPTION: "Описание",
                    INTERVAL: "Интервал тестирования в днях",
                    TODISPLAY: "Сколько вопросов задавать",
                    LEVEL: "уровень теста",
                    SCORE: "Количество правильных ответов для положительной оценки",
                    TIME: "Время на тест",
                    TYPE: "Тип теста",
                    QUEST_TEXT: "Текст вопроса",
                    QUEST_TYPE: "Тип вопроса",
                    ANSW_TEXT: "Текст ответа",
                    right: 'правильный',
                    closed_groups: "Закрытые группы",
                    QUEST_WEIGHT: "Вес вопроса"
                  },
                  en: {
                    QUEST_BLOCK_NAME:"Block name",
                    QUEST_BLOCK_ID:"N block",
                    QUEST_BLOCK_COUNT:"Qty",
                    QUEST_BLOCK_RAND:"Random",
                    TEST_FLAGS:"Service Flags",
                    LOGO_PICTURE:"Logo",
                    TEST_BLOCKS:"Blocks",
                    QUEST_BLOCK:"Block",
                    comments:"C.",
                    direct_link:"Direct link",
                    copy:"Copy",
                    tags:"Tags",
                    download:"Download",
                    SHOW_RESULTS: "Show results",
                    NO_PREV: "Prohibit returning and changing the answer",
                    edit: "Edit",
                    refresh: "Refresh the list",
                    date_pub: "Date of publication",
                    status: "Status",
                    goback: "Back",
                    title: "Test list",
                    loading: "Loading",
                    remove: "Remove",
                    add: "Add a test",
                    test: "Test",
                    confirm_del: "Confirm deletion",
                    cancel: "Cancel",
                    ok: "OK",
                    notpublished: "Draft",
                    published: "Published",
                    notfound: "Nothing found",
                    attention: "Couldn’t delete! Please remove the test from courses first",
                    alert_understood: "Got it",
                    import: "Import",
                    testview: "Complete",
                    testedit: "Edit",
                    testdel: "Delete",
                    NAME: "Test name",
                    THEMATICS: "Subject",
                    DESCRIPTION: "Description",
                    INTERVAL: "Testing interval, days",
                    TODISPLAY: "How many questions to display",
                    LEVEL: "test level",
                    SCORE: "Number of correct answers for positive assessment",
                    TIME: "Testing time",
                    TYPE: "Test type",
                    QUEST_TEXT: "Question",
                    QUEST_TYPE: "Question type",
                    ANSW_TEXT: "Answer",
                    right: 'correct',
                    closed_groups: "Closed classes",
                    QUEST_WEIGHT: "Question weight"
                  },
                  ua: {
                    QUEST_BLOCK_NAME:"Назва блоку",
                    QUEST_BLOCK_ID:"N блоку",
                    QUEST_BLOCK_COUNT:"У питаннях",
                    QUEST_BLOCK_RAND:"Випадково",
                    TEST_FLAGS:"Службові позначки",
                    LOGO_PICTURE:"Логотип",
                    TEST_BLOCKS:"Блоки",
                    QUEST_BLOCK: "Блок",
                    comments:"K.",
                    direct_link:"Пряме посилання",
                    copy: "копія",
                    tags: "теги",
                    download:"Завантажити",
                    SHOW_RESULTS: "показувати результати",
                    NO_PREV: "заборонити повертатися і змінювати відповідь",
                    edit: "Редагувати",
                    refresh: "Оновити список",
                    date_pub: "Дата публикації",
                    status: "Статус",
                    goback: "Повернутися",
                    title: "Список тестів",
                    loading: "Завантаження",
                    remove: "Видалити",
                    add: "Додати тест",
                    test: "Тест",
                    confirm_del: "Підтвердіть видалення",
                    cancel: "Відміна",
                    ok: "Так",
                    notpublished: "Чернетка",
                    published: "Опублікований",
                    notfound: "Нічого не знайдено",
                    attention: "Видалення неможливо! Спочатку видаліть тест з курсів",
                    alert_understood: "Зрозуміло",
                    import: "Імпорт",
                    testview: "Пройти",
                    testedit: "Редагувати",
                    testdel: "Видалити",
                    NAME: "Назва тесту",
                    THEMATICS: "Тематика",
                    DESCRIPTION: "Опис",
                    INTERVAL: "Інтервал тестування в днях",
                    TODISPLAY: "Скільки питань задавати",
                    LEVEL: "Рівень тесту",
                    SCORE: "Кількість правильних відповідей для позитивної оцінки",
                    TIME: "Час на тест",
                    TYPE: "Тип тесту",
                    QUEST_TEXT: "Текст питання",
                    QUEST_TYPE: "Тип питання",
                    ANSW_TEXT: "Текст відповіді",
                    right: 'правильний',
                    closed_groups: "Закриті груп",
                    QUEST_WEIGHT: "Вага питання"
                  },
                  fr: {
                    QUEST_BLOCK_NAME:"Nom du bloc",
                    QUEST_BLOCK_ID:"Bloc N",
                    QUEST_BLOCK_COUNT:"Quantité",
                    QUEST_BLOCK_RAND:"Aléatoire",
                    TEST_FLAGS: "Drapeaux de service",
                    LOGO_PICTURE:"Logo",
                    TEST_BLOCKS:"Blocs",
                    QUEST_BLOCK:"Bloquer",
                    comments:"C.",
                    direct_link:"Lien direct",
                    copy: "Copie",
                    tags: "Tags",
                    download: "Télécharger",
                    SHOW_RESULTS: "Afficher les résultats",
                    NO_PREV: "Ne pas revenir en arrière et changer de réponse",
                    edit: "Modifier",
                    refresh: "Actualiser la liste",
                    date_pub: "Date de publication",
                    status: "Statut",
                    goback: "Retour",
                    title: "Liste des tests",
                    loading: "Chargement",
                    remove: "Supprimer",
                    add: "Ajouter un test",
                    test: "Test",
                    confirm_del: "Confirmer la suppression",
                    cancel: "Annuler",
                    ok: "OK",
                    notpublished: "Brouillon",
                    published: "Publié",
                    notfound: "Pas de résultat",
                    attention: "Suppression impossible: merci de retirer le test des enseignements auxquels il est lié",
                    alert_understood: "Compris",
                    import: "Importer",
                    testview: "Termoner",
                    testedit: "Éditer",
                    testdel: "Supprimer",
                    NAME: "Nom du test",
                    THEMATICS: "Sujet",
                    DESCRIPTION: "Description",
                    INTERVAL: "Intervalle des tests, en jours",
                    TODISPLAY: "Nombre de questions à afficher",
                    LEVEL: "niveau du test ",
                    SCORE: "Nombre de réponses positives pour réussir l’exercice",
                    TIME: "Durée du test",
                    TYPE: "Type du test",
                    QUEST_TEXT: "Question",
                    QUEST_TYPE: "Type de la question",
                    ANSW_TEXT: "Réponse",
                    right: 'correct',
                    closed_groups: "Classes fermées",
                    QUEST_WEIGHT: "Coefficient de la question"
                  },
                  it: {
                    QUEST_BLOCK_NAME:"Nome blocco",
                    QUEST_BLOCK_ID:"N blocco",
                    QUEST_BLOCK_COUNT:"Qtà",
                    QUEST_BLOCK_RAND:"Casuale",
                    TEST_FLAGS:"Flag di servizio",
                    LOGO_PICTURE:"Logo",
                    TEST_BLOCKS:"Blocchi",
                    QUEST_BLOCK:"Blocca",
                    comments:"C.",
                    direct_link:"Collegamento diretto",
                    copy:"Copia",
                    tags:"Tag",
                    download:"Scarica",
                    SHOW_RESULTS: "Mostra risultati",
                    NO_PREV: "Impedisci il ritorno e la modifica della risposta",
                    edit: "Modifica",
                    refresh: "Aggiorna elenco",
                    date_pub: "Data di pubblicazione",
                    status: "Status",
                    goback: "Indietro",
                    title: "Lista test",
                    loading: "Caricamento",
                    remove: "Rimuovi",
                    add: "Aggiungi un test",
                    test: "Test",
                    confirm_del: "Conferma eliminazione",
                    cancel: "Cancella",
                    ok: "OK",
                    notpublished: "Bozza",
                    published: "Pubblicato",
                    notfound: "Nessun risultato",
                    attention: "Impossibile eliminare! Rimuovere prima il test dal corso",
                    alert_understood: "D’accordo",
                    import: "Importa",
                    testview: "Completa",
                    testedit: "Modifica",
                    testdel: "Elimina",
                    NAME: "Nome test",
                    THEMATICS: "Materia",
                    DESCRIPTION: "Descrizione",
                    INTERVAL: "Intervallo di test, giorni",
                    TODISPLAY: "Quante domande da mostrare",
                    LEVEL: "livello test ",
                    SCORE: "Numero di risposte corrette per una valutazione positiva",
                    TIME: "Tempo di test",
                    TYPE: "Tipo di test",
                    QUEST_TEXT: "Domanda",
                    QUEST_TYPE: "Tipo domanda",
                    ANSW_TEXT: "Risposta",
                    right: 'corretta',
                    closed_groups: "Classi chiuse",
                    QUEST_WEIGHT: "Peso domanda"
                  },
                  tr: {
                    QUEST_BLOCK_NAME:"Blok adı",
                    QUEST_BLOCK_ID:"N blok",
                    QUEST_BLOCK_COUNT:"Adet",
                    QUEST_BLOCK_RAND:"Rastgele",
                    TEST_FLAGS:"Hizmet Bayrakları",
                    LOGO_PICTURE:"Logo",
                    TEST_BLOCKS:"Bloklar",
                    QUEST_BLOCK:"Engelle",
                    comments:"C.",
                    direct_link:"Doğrudan bağlantı",
                    copy:"Kopya",
                    tags:"Etiketler",
                    download:"İndir",
                    SHOW_RESULTS: "Sonuçları göster",
                    NO_PREV: "Geri dönmeyi ve yanıtı değiştirmeyi yasakla",
                    edit: "Düzenle",
                    refresh: "Listeyi yenile",
                    date_pub: "Yayın tarihi",
                    status: "Durum",
                    goback: "Geri dön",
                    title: "Test listesi",
                    loading: "Yükleme",
                    remove: "Kaldır",
                    add: "Test ekle",
                    test: "Test",
                    confirm_del: "Silme işlemini onayla",
                    cancel: "İptal",
                    ok: "Evet",
                    notpublished: "Taslak",
                    published: "Yayınlandı",
                    notfound: "Hiçbir şey bulunamadı",
                    attention: "Silme mümkün değil! Önce testi derslerden kaldırın",
                    alert_understood: "Anlaşıldı",
                    import: "İçe aktarmak",
                    testview: "Geçmek",
                    testedit: "Düzenle",
                    testdel: "Sil",
                    NAME: "Testin adı",
                    THEMATICS: "Konular",
                    DESCRIPTION: "Açıklama",
                    INTERVAL: "Gün cinsinden test aralığı",
                    TODISPLAY: "Sorulacak kaç soru var",
                    LEVEL: "Test seviyesi",
                    SCORE: "Olumlu bir skor için doğru cevapların sayısı",
                    TIME: "Test zamanı",
                    TYPE: "Test türü",
                    QUEST_TEXT: "Soru Metni",
                    QUEST_TYPE: "Soru türü",
                    ANSW_TEXT: "Yanıt metni",
                    right: 'doğru',
                    closed_groups: "Kapalı gruplar",
                    QUEST_WEIGHT: "Sorunun ağırlığı"
                  },
                  de: {
                    QUEST_BLOCK_NAME:"Blockname",
                    QUEST_BLOCK_ID:"N-Block",
                    QUEST_BLOCK_COUNT:"Menge",
                    QUEST_BLOCK_RAND:"Zufällig",
                    TEST_FLAGS:"Service-Flags",
                    LOGO_PICTURE:"Logo",
                    TEST_BLOCKS:"Blöcke",
                    QUEST_BLOCK:"Blockieren",
                    comments:"C.",
                    direct_link:"Directe link",
                    copy:"Kopie",
                    tags:"Tags",
                    download:"Herunterladen",
                    SHOW_RESULTS: "Ergebnisse anzeigen",
                    NO_PREV: "Verbieten, zurückzukehren und die Antwort zu ändern",
                    edit: "Bearbeiten",
                    refresh: "Liste aktualisieren",
                    date_pub: "Veröffentlichungsdatum",
                    status: "Status",
                    goback: "Zurück",
                    title: "Testliste",
                    loading: "Laden",
                    remove: "Entfernen",
                    add: "Test hinzufügen",
                    test: "Test",
                    confirm_del: "löschen Bestätigen",
                    cancel: "Abbrechen",
                    ok: "Ja",
                    notpublished: "Entwurf",
                    published: "Veröffentlicht",
                    notfound: "Nichts gefunden",
                    attention: "Löschen ist nicht möglich! Entfernen Sie zuerst den Test aus den Kursen",
                    alert_understood: "es ist Klar",
                    import: "Importieren",
                    testview: "Übergeben",
                    testedit: "Bearbeiten",
                    testdel: "Löschen",
                    NAME: "Name des Tests",
                    THEMATICS: "Thema",
                    DESCRIPTION: "Beschreibung",
                    INTERVAL: "Testintervall in Tagen",
                    TODISPLAY: "Wie viele Fragen stellen",
                    LEVEL: "Test Level",
                    SCORE: "Anzahl der richtigen Antworten für eine positive Bewertung",
                    TIME: "Zeit zum Testen",
                    TYPE: "Testtyp",
                    QUEST_TEXT: "Fragetext",
                    QUEST_TYPE: "Fragetyp",
                    ANSW_TEXT: "Antworttext",
                    right: 'richtig',
                    closed_groups: "Geschlossene Gruppen",
                    QUEST_WEIGHT: "Gewicht der Frage"
                  },
                  pl: {
                    QUEST_BLOCK_NAME:"Nazwa bloku",
                    QUEST_BLOCK_ID:"Blok N",
                    QUEST_BLOCK_COUNT:"Ilość",
                    QUEST_BLOCK_RAND: "Losowo",
                    TEST_FLAGS:"Flagi usług",
                    LOGO_PICTURE:"Logo",
                    TEST_BLOCKS:"Bloki",
                    QUEST_BLOCK:"Zablokuj",
                    comments:"K.",
                    direct_link:"Bezpośredni link",
                    copy:"Kopia",
                    tags:"Tagi",
                    download:"Pobierz",
                    SHOW_RESULTS: "Pokaż wyniki",
                    NO_PREV: "zakaz powrotu i zmiany odpowiedzi",
                    edit:"Edytuj",
                    refresh: "Odśwież listę",
                    date_pub: "Data publikacji",
                    status:"Status",
                    goback:"powrót",
                    title:"lista testów",
                    loading:"loading",
                    remove:"Usuń",
                    add: "Dodaj test",
                    test:"test",
                    confirm_del: "Potwierdź usunięcie",
                    cancel:"Anuluj",
                    ok:"tak",
                    notpublished:"szkic",
                    published:"published",
                    notfound :" nic nie znaleziono",
                    attention: "usunięcie jest niemożliwe! Najpierw usuń test z kursów",
                    alert_understood: "jasne",
                    import:"import",
                    testview:"Pass",
                    testedit:"Edytuj",
                    testdel:"Usuń",
                    NAME:"nazwa testu",
                    THEMATICS:"tematyka",
                    DESCRIPTION:"Opis",
                    INTERVAL: "interwał testowania w dniach",
                    TODISPLAY: "ile pytań zadać",
                    LEVEL:"Poziom testu",
                    SCORE :" liczba poprawnych odpowiedzi do oceny pozytywnej",
                    TIME: "Czas na test",
                    TYPE:"typ testu",
                    QUEST_TEXT :" tekst pytania",
                    QUEST_TYPE:"Typ pytania",
                    ANSW_TEXT: "tekst odpowiedzi",
                    right: 'właściwy',
                    closed_groups: "grupy zamknięte",
                    QUEST_WEIGHT :" waga pytania"
                  },
                  pt: {
                    QUEST_BLOCK_NAME:"Nome do bloco",
                    QUEST_BLOCK_ID:"N bloco",
                    QUEST_BLOCK_COUNT:"Quantidade",
                    QUEST_BLOCK_RAND:"Aleatório",
                    TEST_FLAGS:"Sinalizadores de serviço",
                    LOGO_PICTURE:"Logo",
                    TEST_BLOCKS:"Blocos",
                    QUEST_BLOCK:"Bloquear",
                    comments:"C.",
                    direct_link:"Link direto",
                    copy:"cópia",
                    tags:"Tags",
                    download:"Baixar",
                    SHOW_RESULTS: "mostrar resultados",
                    NO_PREV: "não permitir voltar e alterar a resposta",
                    edit: "Editar",
                    refresh: "Atualizar lista",
                    date_pub: "data de publicação",
                    status:"Status",
                    goback: "voltar",
                    title: "lista de testes",
                    loading: "Carregamento",
                    remove:"Remover",
                    add: "Adicionar um teste",
                    test: "Teste",
                    confirm_del: "Confirmar exclusão",
                    cancel:"cancelamento",
                    ok:"sim",
                    notpublished:"rascunho",
                    published:"publicado",
                    notfound: "nada encontrado",
                    attention: "não é possível excluir! Primeiro, remova o teste dos cursos",
                    alert_understood:"claro",
                    import:"Importar",
                    testview: "passar",
                    testedit: "editar",
                    testdel: "apagar",
                    NAME: "Nome do teste",
                    THEMATICS:"tema",
                    DESCRIPTION: "Descrição",
                    Intervalo: "Intervalo de teste em dias",
                    TODISPLAY: "quantas perguntas fazer",
                    LEVEL: "Nível de teste",
                    SCORE: "número de respostas corretas para uma pontuação positiva",
                    TIME: "hora do teste",
                    TYPE: "Tipo do teste",
                    QUEST_TEXT :" texto da pergunta",
                    QUEST_TYPE:"tipo de pergunta",
                    ANSW_TEXT: "texto da resposta",
                    right:'certo',
                    closed_groups: "grupos fechados",
                    QUEST_WEIGHT:"Peso da pergunta"
                  },
                  es: {
                    QUEST_BLOCK_NAME:"Nombre del bloque",
                    QUEST_BLOCK_ID:"Bloque N",
                    QUEST_BLOCK_COUNT:"Cantidad",
                    QUEST_BLOCK_RAND:"Aleatorio",
                    TEST_FLAGS:"Indicadores de servicio",
                    LOGO_PICTURE:"Logotipo",
                    TEST_BLOCKS:"Bloques",
                    QUEST_BLOCK:"Bloquear",
                    comments:"C.",
                    direct_link:"Enlace directo",
                    copy:"Copia",
                    tags:"Etiquetas",
                    download:"Descargar",
                    SHOW_RESULTS: "Mostrar resultados",
                    NO_PREV: "Prohibir volver y cambiar la respuesta",
                    editar:"Editar",
                    refresh: "Actualizar lista",
                    date_pub: "fecha de publicación",
                    status: "Estado",
                    goback:"Volver",
                    title: "lista de pruebas",
                    loading:"Carga",
                    remove:"Eliminar",
                    add: "Añadir prueba",
                    test:"Prueba",
                    confirm_del: "Confirmar eliminación",
                    cancel: "Cancelar",
                    ok: "Sí",
                    notpublished:"Borrador",
                    published:"Publicado",
                    notfound :" no se encontró nada",
                    attention: "¡la Eliminación es imposible! Primero elimine la prueba de los cursos",
                    alert_understood:"Claro",
                    import:"Importar",
                    testview: "Pasar",
                    testedit:"Editar",
                    testdel: "Eliminar",
                    NAME: "nombre de la prueba",
                    THEMATICS:"Temas",
                    DESCRIPTION: "Descripción",
                    INTERVAL: "intervalo de prueba en días",
                    TODISPLAY: "Cuántas preguntas hacer",
                    LEVEL: "nivel de prueba",
                    SCORE: "Número de respuestas correctas para una evaluación positiva",
                    TIME: "Tiempo de prueba",
                    TYPE: "tipo de prueba",
                    QUEST_TEXT: "texto de la pregunta",
                    QUEST_TYPE: "tipo de pregunta",
                    ANSW_TEXT: "texto de respuesta",
                    right: 'correcto',
                    closed_groups: "grupos Cerrados",
                    QUEST_WEIGHT: "peso de la pregunta"
                  },
                  vn: {
                    QUEST_BLOCK_NAME:"Tên khối",
                    QUEST_BLOCK_ID:"Khối N",
                    QUEST_BLOCK_COUNT:"Số lượng",
                    QUEST_BLOCK_RAND:"Ngẫu nhiên",
                    TEST_FLAGS:"Cờ dịch vụ",
                    LOGO_PICTURE:"Biểu trưng",
                    TEST_BLOCKS:"Khối",
                    QUEST_BLOCK:"Chặn",
                    comments:"C.",
                    direct_link:"Liên kết trực tiếp",
                    copy: "Sao chép",
                    tags:"Loại",
                    download:"Tải",
                    SHOW_RESULTS: "kết quả Show",
                    NO_PREV: "Cấm trở về và thay đổi các câu trả lời",
                    edit: "chỉnh Sửa",
                    refresh: "Bản danh Sách",
                    date_pub: "Ngày xuất Bản",
                    status: "tình Trạng",
                    goback: "quay trở Lại",
                    title: "kiểm Tra danh Sách",
                    loading: "Lên",
                    remove: "Sạch",
                    add: "Thêm Thử nghiệm",
                    test: "kiểm Tra",
                    confirm_del: "Xác nhận xóa",
                    cancel: "Bỏ",
                    ok: "Vâng",
                    notpublished: "Thảo",
                    published: "Bố",
                    notfound: "Không thấy",
                    attention: "Xóa là không thể! Đầu tiên bỏ cuộc thử nghiệm từ các khóa học",
                    alert_understood: "Rõ ràng",
                    import: "Nhập",
                    testview: "Vượt qua",
                    testedit: "chỉnh Sửa",
                    testdel: "Sạch",
                    NAME: "kiểm tra Tên",
                    THEMATICS: "chủ Đề",
                    DESCRIPTION: "mô Tả",
                    INTERVAL: "kiểm tra Khoảng thời gian trong ngày",
                    TODISPLAY: "làm thế Nào để đặt câu hỏi",
                    LEVEL: "kiểm tra mức độ",
                    SCORE: "Số câu trả lời chính xác cho tích cực đánh giá",
                    TIME: "Thời gian để thử nghiệm",
                    TYPE: "Thử nghiệm loại",
                    QUEST_TEXT: "văn bản câu Hỏi",
                    QUEST_TYPE: "câu Hỏi loại",
                    ANSW_TEXT: "văn bản trả Lời",
                    right: 'sửa chữa',
                    closed_groups: "Đóng cửa nhóm",
                    QUEST_WEIGHT: "câu Hỏi trọng lượng"
        },
                },
              messageObj:messageObj
            }
        },
        mounted: function(){
          this.goback = this.back ? this.back : 'studentProfile';
          this.parent.userOption('get', 'isListViewTest').then(res=>{
            this.isListViewTest =  res === "false" ? false : Boolean(res);;
          });
          this.parent.checkPulse('test list').then(res=>{
            if(!this.parent.listTests || !this.parent.justSearched && Object.keys(this.parent.listTests).length === 0) {
              this.maxCount=this.parent.getMaxCountByTarif('tests');
              this.loadTests();
            }else{
              this.checkComments().then(res=>{
                this.testComments = res;
              })
            }

            this.parent.appOptionGet('test_tags').then((data)=>{
              this.test_tags_opt = JSON.parse(data.DETAIL_TEXT);
              this.test_tags_opt=this.test_tags_opt.sort(function compare(a, b) {
                if (a.toLowerCase() > b.toLowerCase()) { return 1; }
                if (a.toLowerCase() < b.toLowerCase()) { return -1; }
                return 0;
              });
              this.flt_test_tags_opt = this.test_tags_opt;
              //
            },err=>{
              console.log(err);
            });

            this.parent.userOption('get', 'expformat').then(expFormat_selected=>{
              this.expFormat_selected = expFormat_selected ? expFormat_selected : "xlsx";
            });
            
          },err=>{
            this.$emit('goback', 'studentProfile');
          });

        },
        watch:{
          isListViewTest:function(arg){
            this.parent.userOption('set', 'isListViewTest', arg);
          },
          categoria:function(){
              // console.log(':: categoria ', this.lastCategoria, this.parent.listTests);
                // if(this.lastCategoria !== -1 || !this.parent.listTests || Object.keys(this.parent.listTests).length === 0) {
              // if(this.lastCategoria !== -1 && this.lastCategoria != this.categoria) {
                this.loadTests();
              // }
              // this.lastCategoria = this.categoria;
            },
          test_tags_find:function (arg){
            let $this=this;
            console.log(':watch:test_tags_find arg= ',arg);
            this.getTests();
          },
          search:function(arg){
            console.log(':: search ',arg);

          }
        },
        computed:{
          razdel(){
            return parseInt(this.parent.categoria) ? this.parent.categoria : ''
          },
          isMore(){
            return this.parent.listTests && this.total && !this.isLoading && Object.keys(this.parent.listTests).length >= this.count;
          },
            fltListTests(){
                let ret=false;
                if(!this.parent.listTests){
                    ret=false;
                }else{
                    ret = this.parent.listTests.filter((test, index) => {
                        // console.log('::fltListTests ', test.ID, test.ACTIVE, test.CLOSED);
                        return test && (test.ACTIVE === 'Y' && !test.CLOSED && !test.HIDE  || this.parent.isModer);
                    });
                }
                return ret;
            }
        },
        methods: {
          // getDirectLink(id){
          //   const link = `${this.parent.appURL}?test=${id}`;
          //   console.log('::getDirectLink ',link);
          //   navigator.clipboard.writeText(link);
          // },
          availPeriods(item){
            // console.log('::availPeriods ',item);
            let ret = true;
            if(!item.PROPERTY_VALUES.flags) return true;
            if(this.parent.isJSON(item.PROPERTY_VALUES.flags)){
              item.PROPERTY_VALUES.flags = JSON.parse(item.PROPERTY_VALUES.flags);
            }
            if(item.PROPERTY_VALUES.flags.avail_period && item.PROPERTY_VALUES.flags.avail_days && item.PROPERTY_VALUES.flags.avail_days.length>0){
              let today=dayjs().format('YYYY-MM-DD');
              ret = false;
              for(let test of item.PROPERTY_VALUES.flags.avail_days){
                if(today >= test.from && today <= test.to){
                  ret = true;
                  break;
                }
              }
            }
            return ret;
          },
          filterTags (val) {
            // console.log('::filterTags val=',val,this.test_tags_opt)
            const needle = val?val.toLowerCase():'';
            this.flt_test_tags_opt = this.test_tags_opt.filter(v => {return !val || v.toLowerCase().indexOf(needle) > -1})
            // console.log('::filterTags flt_test_tags_opt=',this.flt_test_tags_opt)
            this.$refs.TagsSelect.refresh();
            this.$refs.TagsSelect.showPopup();
          },
          getTestsVsClosed(more){
            this.parent.getIdsTestCourseInGroup('test').then(list=>{
              this.getTests(list, more);
            },err=>console.error(err));
          },
            loadTests(isClearCache){
              this.maxCount=this.parent.getMaxCountByTarif('tests');
              // this.getTests();
              this.getTestsVsClosed();

            },
            importTests(tests){
                // console.log('::importTests ',tests);
                this.isShowImport = false;
                window.localStorage.setItem('tests', '');
                window.localStorage.setItem('listTests', '');
                this.parent.clearCountCache('tests');
                // eslint-disable-next-line vue/no-mutating-props
                this.parent.listTests = null;
                // eslint-disable-next-line vue/no-mutating-props
                this.parent.isLoading=false;
                // eslint-disable-next-line vue/no-mutating-props
                this.parent.isImportLoading=false;
                window.pics_tests='';
                this.getTestsVsClosed();
            },
            importTestTogle(){
                this.isImportLoading = true;
                this.parent.tarifAlert('tests').then(isOk=>{
                    this.isImportLoading = false;
                    if(!isOk) {
                        console.error(':: Tests Exceeded the tariff limit');
                    }else{
                        this.isShowImport=true;
                    }
                });

            },
            fetchFileToBase64(url){
              return new Promise(resolve => {
                if(url) {
                  let ext = url.split('.').at(-1);
                  let random_file_name = Math.floor(Math.random() * 10000) + '.' + ext;
                  fetch(url)
                    .then(response => response.arrayBuffer())
                    .then(arrayBuffer => {
                      const file = new Blob([arrayBuffer], {type: 'image/' + ext});
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = function () {
                        const base64 = reader.result;
                        resolve([random_file_name, base64]);
                      };
                    });
                }else{
                  resolve(null);
                }
              });

            },
            cloneExpTest(test, back, list) {
              // console.log(':1:cloneExpLesson ',test);
              let $this=this;
              if(this.parent.isJSON(test.data)){
                let item = JSON.parse(test.data);

                // this.fetchFileToBase64(item.DETAIL_PICTURE).then(arrPic=> {
                //   console.log(':2:cloneExpTest arrPic=', arrPic);
                //   console.log(':3:cloneExpTest item=', item);

                  let method = 'entityItemAdd';
                  let params = {
                    'ENTITY': 'uni_tests',
                    'PREVIEW_TEXT': item.PREVIEW_TEXT,
                    'NAME': item.NAME + '_' + this.message[this.lang].copy,
                    // 'DETAIL_TEXT': item.DETAIL_TEXT,
                    // 'DETAIL_PICTURE': null,//arrPic,
                    'DATE_CREATE': dayjs().format('DD.MM.YYYY'),
                    'ACTIVE': 'N',
                    'DATE_ACTIVE_FROM': '',
                    'PROPERTY_VALUES': item.PROPERTY_VALUES
                  };
                 
                var url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });//.then(params=> {
                $this.parent.bx24params.DETAIL_TEXT = item.DETAIL_TEXT;
                $this.parent.bx24params.DETAIL_PICTURE = item.DETAIL_PICTURE;
                $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                  $this.getTests();
                      // if (res.answer.result) {
                      //   let lesson_id = res.answer.result;
                      // }
                    // }
                  }, "json");
                // });
                /////
              }

            },
            saveExpTest(test, back, list){
                // console.log('::saveExpTest ',test);

                if(this.expFormat_selected === 'udata') {
                    let fname = 'test_' + test.id + '.udata';
                  // if(navigator.userAgent.search(/BitrixDesktop/g)>=0){
                  //   let b64Data = btoa(unescape(encodeURIComponent(test.data)));
                  //   this.file_download_loading=true;
                  //   this.parent.getFileLinkPureB64(fname, btoa(b64Data)).then(link=>{
                  //     console.log(':1: file_download_link=', this.file_download_link);
                  //     this.file_download_link = link;
                  //     this.file_download_loading=false;
                  //   });
                  // }else {
                    let b64Data = btoa(unescape(encodeURIComponent(test.data)));
                    saveAs(new Blob([this.parent.s2ab(b64Data)],
                      {type: "application/octet-stream"}), fname);
                  // }
                    if(back){
                        back(list);
                    }
                }else{
                    this.saveTestAsXlsx(test, back, list);
                }
            },
            saveExpList(list){
                // console.log('::saveExpList ',list);
                if(list && Object.keys(list).length > 0){
                    let test = list.pop();
                    this.saveExpTest(test, this.saveExpList, list);
                }
                // else{
                //     console.log('::saveExpList success');
                // }

            },
            saveTestAsXlsx(test, back, list){
                let forXlsx=[];
                let test_id = parseInt(test.id);
                let data = JSON.parse(test.data);
                console.log(':: saveTestAsXlsx test=', data);

                let level_value = data.PROPERTY_VALUES.level;
                let level=this.level_options[this.lang].find((element, index, array)=>
                    { if(element.value == level_value) return true; else return false }, this);
                // console.log('::saveTestAsXlsx level_value=',level_value, level);
                level = level ? level.text : level_value;

                let type_value = data.PROPERTY_VALUES.type;
                let type=this.typeTest_options[this.lang].find((element, index, array)=>
                    { if(element.value == type_value) return true; else return false }, this);
                // console.log('::saveTestAsXlsx type_value=',type_value, type);
                type = type? type.text : type_value;

              let show_results_value = data.PROPERTY_VALUES.show_results?data.PROPERTY_VALUES.show_results:'';
              let show_results_text = this.parent.show_results[this.lang].find((element, index, array) => {
                if (element.value == show_results_value) { return true; } else { return false; }
              }, this);
              show_results_text = show_results_text ? show_results_text.text : this.parent.show_results[this.lang][0].text;


              let no_prev_value = data.PROPERTY_VALUES.no_prev?data.PROPERTY_VALUES.no_prev:'N';
              let no_prev_text = this.parent.YesNo[this.lang].find((element, index, array) => {
                if (element.value == no_prev_value) { return true; } else { return false; }
              }, this);
              no_prev_text = no_prev_text ? no_prev_text.text : this.parent.YesNo[this.lang][0].text;

              let flags = data.PROPERTY_VALUES.flags ? data.PROPERTY_VALUES.flags : '';
              let blocks = data.PROPERTY_VALUES.blocks ? data.PROPERTY_VALUES.blocks : '';

              forXlsx.push({
                  TAG:'TEST',
                  NAME:this.message[this.lang].NAME,
                  THEMATICS:this.message[this.lang].THEMATICS,
                  DESCRIPTION:this.message[this.lang].DESCRIPTION,
                  INTERVAL:this.message[this.lang].INTERVAL,
                  TODISPLAY:this.message[this.lang].TODISPLAY,
                  LEVEL:this.message[this.lang].LEVEL,
                  SCORE:this.message[this.lang].SCORE,
                  TIME:this.message[this.lang].TIME,
                  TYPE:this.message[this.lang].TYPE,
                  SHOW_RESULTS:this.message[this.lang].SHOW_RESULTS,
                  NO_PREV:this.message[this.lang].NO_PREV,
                  PICTURE:this.message[this.lang].LOGO_PICTURE,
                  TEST_FLAGS:this.message[this.lang].TEST_FLAGS,
                  // TEST_BLOCKS:this.message[this.lang].TEST_BLOCKS
                });
                forXlsx.push({
                  TAG:'',
                  NAME:data.NAME,
                  THEMATICS:data.PROPERTY_VALUES.thematics,
                  DESCRIPTION:data.PROPERTY_VALUES.description,
                  INTERVAL:data.PROPERTY_VALUES.interval,
                  TODISPLAY:data.PROPERTY_VALUES.todisplay,
                  LEVEL:level,
                  SCORE:data.PROPERTY_VALUES.score,
                  TIME:data.PROPERTY_VALUES.time,
                  TYPE:type,
                  SHOW_RESULTS:show_results_text,
                  NO_PREV:no_prev_text,
                  PICTURE:data.DETAIL_PICTURE,
                  TEST_FLAGS: flags,
                  // TEST_BLOCKS:blocks
                });

              let answers='';
              let questions='';

              let detail_text = this.parent.showBase64Text(data.DETAIL_TEXT);
              if(this.parent.isJSON(detail_text)){
                const tmp=JSON.parse(detail_text);
                questions = tmp.questions;
                answers = tmp.answers;
              }else if(this.parent.isJSON(data.PROPERTY_VALUES.answers)){
                // Это для совместимости с предыдущими версиями можно убрать после 2024
                answers = JSON.parse(data.PROPERTY_VALUES.answers);
                // console.log(':: answers=', answers);
                if (this.parent.isJSON(data.PROPERTY_VALUES.questions)) {
                  questions = JSON.parse(data.PROPERTY_VALUES.questions);
                }
              }

              if(this.parent.isJSON(blocks)){
                blocks = JSON.parse(blocks);
                forXlsx.push({
                  TAG: 'BLOCKS',
                  NAME: this.message[this.lang].QUEST_BLOCK_NAME,
                  THEMATICS: this.message[this.lang].QUEST_BLOCK_ID,
                  DESCRIPTION: this.message[this.lang].QUEST_BLOCK_COUNT,
                  INTERVAL: this.message[this.lang].QUEST_BLOCK_RAND
                });
                for(let item of blocks){
                  forXlsx.push({
                    TAG: '',
                    NAME: item.name,
                    THEMATICS: item.id,
                    DESCRIPTION: item.count,
                    INTERVAL: item.rand
                  });
                }
                forXlsx.push({});
              }

                if(questions){
                    for(let i in questions){
                        let quest=questions[i];
                        forXlsx.push({
                          TAG: 'QUEST',
                          NAME: this.message[this.lang].QUEST_TEXT,
                          THEMATICS: this.message[this.lang].QUEST_TYPE,
                          DESCRIPTION: this.message[this.lang].QUEST_WEIGHT,
                          INTERVAL: this.message[this.lang].QUEST_BLOCK
                        });
                        let type_quest=this.typeQuest_options[this.lang].find((element, index, array)=>
                            { if(element.value == quest.type) return true; else return false }, this);
                        type_quest = type_quest ? type_quest.text : quest.type;

                        forXlsx.push({
                          TAG: '',
                          NAME: this.parent.showBase64Text(quest.text),
                          THEMATICS: type_quest,
                          DESCRIPTION:quest.weight,
                          INTERVAL:quest.block
                        });
                        forXlsx.push({
                            TAG: 'ANSW',
                            NAME: this.message[this.lang].ANSW_TEXT,
                        });

                        if(answers && answers[quest.value]){
                            for(let j in answers[quest.value]){
                                let answ=answers[quest.value][j];
                                let item  = {
                                    TAG: '',
                                    NAME: answ.text,
                                };
                                if(quest.type === 'qa'){
                                    item.THEMATICS = answ.right?this.message[this.lang].right : '';
                                }else if(quest.type === 'eqw'){
                                    item.THEMATICS = answ.text2;
                                }
                                forXlsx.push(item);
                            }
                        }

                    }


                }

                let Head =[];
                Head.push([[]]);

                let ws = XLSX.utils.aoa_to_sheet(Head);
                let wscols = [
                    {wch:10},
                    {wch:40},
                    {wch:10},
                    {wch:10},
                    {wch:10},
                    {wch:10},
                    {wch:10},
                    {wch:10},
                    {wch:10},
                    {wch:10},
                ];
                ws['!cols'] = wscols;

                XLSX.utils.sheet_add_json(ws, forXlsx, {
                    skipHeader:true,
                    origin:0
                });

                let fname = 'test_'+test_id+'.xlsx';
                let wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'test');
              
                let wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
                saveAs(new Blob([this.parent.s2ab(wbout)],
                  {type: "application/octet-stream"}), fname);
                if(back){
                    back(list);
                }
            },
            
            viewTest(test){
              // eslint-disable-next-line vue/no-mutating-props
                this.parent.backScreen=this.parent.currentScreen;
                this.$emit('testView', test);
            },
            editTest(test){
                this.$emit('testEdit', test);
            },
            showModalDel(test, index) {
              let $this=this;
                // Надо проверить все курсы и если в них есть этот тест вывести предупреждение со  списком курсов
                this.isLoading=true;
                this.parent.checkCourses(test.ID, 'test').then(res=>{
                    this.isLoading=false;
                    // console.log(':: tests: ',res);
                    if(!res || Object.keys(res).length === 0){
                      this.test_id = parseInt(test.ID);
                      this.test_idx = index;
                      $this.parent.do_confirm = this.delTest;
                      $this.parent.title_confirm = this.message[this.lang].remove+' '+this.message[this.lang].test;
                      $this.parent.message_confirm = this.message[this.lang].confirm_del +': '+
                        this.message[this.lang].test +'№'+ this.test_id+' '+ test.NAME;
                      $this.parent.modal_ok=null;
                      $this.parent.modal_cancel=null;
                      $this.parent.confirm=true;
                    }else{
                      $this.parent.notify_text = '';
                      for(let i in res){
                        $this.parent.notify_text += res[i].course_id+' '+res[i].course_name;
                      }
                      $this.parent.notify_title = this.message[this.lang].attention;
                      $this.parent.notify_ok = this.message[this.lang].alert_understood;
                      $this.parent.notify=true;
                    }

                }, err=>console.error(err));
                //
            },
            delTest(){
              let $this = this;
              window.localStorage.setItem('listTests', '');
              window.localStorage.setItem('tests', '');
              window.localStorage.setItem('grp_tests', '');
              $this.parent.clearCountCache('tests');
              $this.parent.confirm=false;
              $this.parent.listTests.splice($this.test_idx,1);
              console.log(':1:delTest ',this.test_id)
              $this.isLoading=true;
              let params = {
                ENTITY: 'uni_tests',
                ID: this.test_id
              }
              let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemDel', params:params });//.then(params=> {
              // console.log(':1.1:delTest $this.parent.bx24params=', $this.parent.bx24params);
              $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                $this.isLoading=false;
                  // console.log(':2:delTest res=', res);
              }, "json");
            },
            hideModalDel(){
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify=false;
            },
            formatDate(item){
                let d = item.DATE_ACTIVE_FROM ? item.DATE_ACTIVE_FROM : item.DATE_CREATE;
                let fd = dayjs(d).format('DD.MM.YYYY');
                return fd;
            },
            addNewTest(){
                this.$emit('testsList', 'add');
                this.parent.clearCountCache('tests');
            },
            // goback(){
            //     this.$emit('testsList', 'back');
            // },
            getTests(closedGroups, more){
              let cat = this.razdel;//parseInt(this.parent.categoria) ? this.parent.categoria : '';
              this.maxCount=this.parent.getMaxCountByTarif('tests');
              let $this = this;
              let filter = {};
              if(cat) {
                filter = {
                  '1': {
                    LOGIC: "AND",
                    PREVIEW_TEXT: '' + cat
                  }
                };
              }else{
                filter = {
                  '1':{LOGIC: "AND", '<ID':'9999999'}
                }
              }

              if(this.test_tags_find){
                filter={'1':{
                    LOGIC: 'AND',
                    'PROPERTY_THEMATICS':`%${this.test_tags_find}%`
                  }
                };
              }

              if(more===true){
                let last_id = '9999999';
                if($this.parent.listTests && Object.keys($this.parent.listTests).length > 0){
                  last_id = $this.parent.listTests[Object.keys($this.parent.listTests).length-1].ID;
                }
                if(!filter['1']){
                  filter['1']={};
                }
                filter['1']['<ID'] = last_id;
              }else{
                // eslint-disable-next-line vue/no-mutating-props
                $this.parent.listTests=[];
              }
              if(!this.parent.isModer && !this.parent.isAdmin){
                filter['1'].ACTIVE = 'Y';
              }
              // console.log('::getTests more,filter=',more, filter);

                // eslint-disable-next-line vue/no-mutating-props
              // $this.parent.listTests =[];
              // console.log(':start: getTests', JSON.parse(JSON.stringify($this.parent.listTests)));
              let params = {
                ENTITY: 'uni_tests',
                SORT: {ID: 'DESC'},
                FILTER: filter,
                LIMIT:$this.count
              };
              console.log(':***: getTests params=', params);

              this.isLoading=true;
              let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
              $this.parent.b24POST(url, $this.parent.bx24params, function (result) {
                $this.isLoading=false;
                let arResult = result.result;
                      let isQuota = false;
                      $this.total = false;
                        if (arResult) {
                            let closed=false;
                            for (let i in arResult) {
                              let test = arResult[i];
                              $this.total = true;

                                isQuota = $this.maxCount > Object.keys($this.parent.listTests).length;
                                // console.log(':: isQuota=',isQuota, $this.maxCount, Object.keys($this.parent.listTests).length)
                                if(isQuota && parseInt(i)<$this.count) {
                                  //для тестов, которые назначены в группах добавим эти группы к тесту для показа в карточке
                                  if (closedGroups) {
                                    closed = [];
                                    for (let j in closedGroups) {
                                      if (closedGroups[j].id == test.ID) {
                                        closed.push(closedGroups[j]);
                                      }
                                    }
                                    if (Object.keys(closed).length == 0) {
                                      closed = false;
                                    }
                                  }
                                  let flags = $this.parent.isJSON(test.PROPERTY_VALUES.flags)?JSON.parse(test.PROPERTY_VALUES.flags):test.PROPERTY_VALUES.flags;
                                  let hide_extra = flags.hide_extra && ($this.parent.user_current.USER_TYPE === 'extranet' || $this.parent.isModer);
                                  let hide_dep = flags.hide_dep && ($this.parent.arraysHaveNoCommonElements($this.parent.user_current.UF_DEPARTMENT, flags.hidden_deps) || $this.parent.isModer);
                                  // console.log(':+++: ',hide_dep,' > ', flags.hide_dep, $this.parent.user_current.UF_DEPARTMENT, flags.hidden_deps);
                                  // eslint-disable-next-line vue/no-mutating-props
                                  $this.parent.listTests.push({
                                    ID: test.ID,
                                    PREVIEW_TEXT: test.PREVIEW_TEXT,
                                    NAME: test.NAME,
                                    ACTIVE: test.ACTIVE,
                                    PROPERTY_VALUES: test.PROPERTY_VALUES,
                                    DETAIL_TEXT: test.DETAIL_TEXT,
                                    DETAIL_PICTURE: test.DETAIL_PICTURE,
                                    DATE_ACTIVE_FROM: test.DATE_ACTIVE_FROM,
                                    DATE_CREATE: test.DATE_CREATE,
                                    CLOSED: closed,
                                    HIDE: flags.hide_cat || hide_extra || hide_dep
                                  });
                                }else{
                                    break;
                                }
                            }
                          $this.checkComments().then(res=>{
                            $this.testComments=res;
                          });

                        }
                      // console.log('::getTests listTests=', $this.parent.listTests);
                });
            },
            checkComments(){
            let $this = this;
            let list={};
            let nn=0;
            return new Promise(resolve=> {
              let nn=0;
              let params = {
                ENTITY: 'uni_comments',
                SORT: {ID: 'DESC'},
                FILTER: {'NAME': `%test_%`},
                LIMIT: 5000
              };
              
              let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
              $this.parent.b24POST(url, $this.parent.bx24params, function (res) {

                let data = res.result;
                  for(let item of data) {
                    list[item.NAME] = list[item.NAME] ? list[item.NAME] + 1 : 1;
                  }
                  
                    resolve(list);
                  
              });
            });
          },
        },
        components: {
            testExport,
            testImport,
            rating
        }
    }
</script>

<style scoped>


</style>

