<template>
  <q-card class="cat-card">
    <q-card-section class="h6">
      <q-list separator>
        <q-item-label header class="h4 text-positive">
          <div class="row">
            <div class="col-1">
              <q-btn size="sm" :color="isChecked?'positive':'secondary'" round icon="check" @click="$emit('doCheckAll')" >
                <q-tooltip class="bg-primary h-tt">{{ message[lang].check_all }}</q-tooltip>
              </q-btn>
            </div>
            <div class="col-2">
              {{ message[lang].assessment_name }}
            </div>
            <div class="col-4">
              {{ message[lang].persona }}
            </div>
            <div class="col-2">{{ message[lang].date_start }}<br />{{ message[lang].date_finish }}</div>
            <div class="col-1">
              {{ message[lang].status }}
            </div>
            <div class="col-2">
              {{ message[lang].action }}
            </div>
          </div>
        </q-item-label>

        <q-separator spaced />

        <q-item v-ripple clickable v-for="(item, index) in assListGroup" :key="item.ID">
          <q-item-section avatar top class="cursor-pointer">
            <q-icon :color="isCheckedGroup(index)?'secondary':'positive'" name="check_circle" @click="$emit('doCheckGroup', index); localExpanded[index]=isCheckedGroup(index)">
              <q-tooltip class="bg-primary h-tt">{{ message[lang].check_group }}</q-tooltip>
            </q-icon>
          </q-item-section>
          <q-item-section v-if="Array.isArray(item) && item[0]">
              <q-expansion-item v-model="localExpanded[index]" expand-separator>
                <template v-slot:header>
                  <q-item-section>
                    {{ item[0].name }}
                  </q-item-section>
                  <q-item-section v-if="isAnswered(item)" side>
                    <q-icon  color="secondary" name="done_all">
                      <q-tooltip class="bg-primary h-tt">{{ message[lang].is_answered }}</q-tooltip>
                    </q-icon>
                  </q-item-section>
                </template>
                <q-list>
                  <div v-for="(elem, index1) in item" :key="elem.ID">
                    <q-item v-ripple clickable>
                    <q-item-section>
                      <div class="row items-center text-positive">
                        <div class="col-1">
                          <q-checkbox size="md" v-model="elem.checked" color="secondary" @update:model-value="$emit('thisChecked', elem, index1)">
                            <q-tooltip class="bg-primary h-tt">{{ message[lang].check_one }}</q-tooltip>
                          </q-checkbox>
                        </div>
                        <div class="col-2 q-gutter-x-sm">
                            <q-avatar v-if="Array.isArray(elem.persona) && elem.persona[0] && elem.persona[0].photo">
                              <q-img :src="elem.persona[0].photo" />
                            </q-avatar>
                            <q-avatar v-else-if="elem.persona && elem.persona.photo">
                              <q-img :src="elem.persona.photo" />
                            </q-avatar>
                            <q-avatar v-else color="grey" text-color="white" icon="perm_identity" />
                        </div>
                        <div class="col-4" @click="$emit('editAssessment', elem, index1)">
                          <div v-if="elem.persona.name">{{ elem.persona.name }}</div>
                          <div v-else>{{ elem.name }}</div>
                        </div>
                        <div class="col-2">{{ customFormatter(elem.date_start) }}<br />{{ customFormatter(elem.date_finish) }}</div>
                        <div class="col-1">
                          {{ elem.active === 'Y' ? message[lang].published : message[lang].draft }}
                        </div>
                        <div class="col-2 text-center">
                          <q-btn size="sm" v-if="elem.active === 'N'" flat round color="primary" icon="edit" @click="$emit('editAssessment', elem, index)" />
                          <q-btn size="sm" flat round color="warning" icon="clear" @click="$emit('delSomeAssessment',index, index1, elem)" />
                        </div>
                        <!--  комменты  -->
                        <div class="col-8 cursor-pointer positive h5" v-if="isAnsweredItem(elem)">
                          <q-icon name="edit" color="positive"/> {{message[lang].comment}}:
                          <span v-if="elem.flags.com">{{elem.flags.com}}</span>

                          <q-popup-edit v-model="elem.flags.com" auto-save v-slot="scope" @update:model-value="$emit('updateFlags',index, index1)">
                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set">
                              <template v-slot:after>
                                <q-btn
                                  flat dense color="warning" icon="cancel"
                                  @click.stop.prevent="scope.cancel"
                                />

                                <q-btn
                                  flat dense color="secondary" icon="check_circle"
                                  @click.stop.prevent="scope.set"
                                  :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value"
                                />
                              </template>
                            </q-input>
                          </q-popup-edit>
                        </div>
                        <div class="col-4 h4 positive h5 text-right q-pr-sm" v-if="isAnsweredItem(elem)">
                          <q-toggle
                            v-model="elem.flags.acc"
                            false-value="N"
                            true-value="Y"
                            color="primary"
                            :label="message[lang].acc1"
                            left-label
                            size="xs"
                            @update:model-value="$emit('updateFlags',index, index1)"
                          />
                        </div>
                      </div>
                    </q-item-section>
                    <q-item-section v-if="isAnsweredItem(elem)" side>
                      <q-icon  color="secondary" name="done">
                        <q-tooltip class="bg-primary h-tt">{{ message[lang].is_answered }}</q-tooltip>
                      </q-icon>
                    </q-item-section>
                  </q-item>
                    <q-separator inset />
                  </div>
                </q-list>
              </q-expansion-item>
          </q-item-section>
          <q-item-section v-else>
            <div class="row items-center">
              <div class="col-1">
                <q-checkbox size="md" v-model="localCheckIndex[index]" color="secondary" >
                  <q-tooltip class="bg-primary h-tt">{{ message[lang].check_one }}</q-tooltip>
                </q-checkbox>
              </div>
              <div class="col-2 q-gutter-x-sm">
                <template>
                  <template v-if="Array.isArray(item.persona)">
                    <q-avatar v-if="item.persona[0] && item.persona[0].photo">
                      <q-img :src="item.persona[0].photo" />
                    </q-avatar>
                    <q-avatar v-else color="grey" text-color="white" icon="perm_identity" />
                  </template>

                  <q-avatar v-else-if="item.persona && item.persona.photo">
                    <q-img :src="item.persona.photo" />
                  </q-avatar>
                  <q-avatar v-else color="grey" text-color="white" icon="perm_identity" />
                </template>
                <template>
                  <template v-if="Array.isArray(item.persona)">
                    <div v-for="(pers, num) in item.persona" :key="num">
                      <a class="text-primary" :href="'https://' + parent.portal + '/company/personal/user/' + pers.id + '/'" target="_blank">
                        {{ pers.name }}
                      </a>
                    </div>
                  </template>

                  <a v-else class="text-primary" :href="'https://' + parent.portal + '/company/personal/user/' + item.persona.id + '/'" target="_blank">
                    {{ item.persona.name }}
                  </a>
                </template>
              </div>
              <div class="col-4" @click="$emit('editAssessment', item, index)">
                {{ item.name }}
              </div>
              <div class="col-2">{{ customFormatter(item.date_start) }}<br />{{ customFormatter(item.date_finish) }}</div>
              <div class="col-1">
                {{ item.active === 'Y' ? message[lang].published : message[lang].draft }}
              </div>
              <div class="col-1 text-center">
                <q-btn v-if="item.active === 'N'" flat round color="primary" icon="edit" @click="$emit('editAssessment', item, index)" />
                <q-btn flat round color="warning" icon="clear" @click="$emit('delSomeAssessment', index, item)" />
              </div>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
      <div class="text-center" v-if="isShowMore">
        <q-btn :loading="isLoading" size="lg" round flat icon="expand_more" color="positive" @click="$emit('loadAssessmentsMore')" />
      </div>
    </q-card-section>
  </q-card>
</template>
<script>
import {messageObj} from 'src/helpers/message';
import dayjs from 'dayjs';
export default {
  name: 'AssessmentsList',
  props: {
    isLoading: {
      type: Boolean,
      required: false,
    },
    isShowMore: {
      type: Boolean,
      required: false,
    },
    lang: {
      type: String,
      required: false,
    },
    checkIndex: {
      type: Array,
      required: false,
    },
    assListGroup: {
      type: Array,
      required: false,
    },
    parent: {
      type: Object,
      required: false,
    },
    expanded: {
      type: Object,
      required: false,
    },
    isChecked:{
      type: Boolean,
      required:false
    }
  },
  data() {
    return {
      message: messageObj,
      localCheckIndex:this.checkIndex,
      localExpanded: this.expanded,
      globalIndex:0,
    };
  },
  mounted(){
    console.log(':mounted: assessments-list assListGroup=',this.assListGroup)
  },
  computed:{

  },
  methods: {
    isAnswered(group){
      for(let i in group){
        if(group[i].scores && group[i].scores.length>0){
          return true;
        }
      }
      return false;
    },
    isAnsweredItem(item){
      return item.scores && item.scores.length>0;
    },

    isCheckedGroup(index){
      let ret=true;
      if(this.assListGroup && this.assListGroup.length>0) {
        for (let i in this.assListGroup[index]) {
          if(!this.assListGroup[index][i].checked){
            ret=false;
            break;
          }
        }
      }
      return ret;
    },
    customFormatter(date) {
      let ret = '';
      let format = this.lang == 'ru' ? 'DD.MM.YYYY' : 'DD-MM-YYYY';
      if (!date) {
        ret = dayjs().format(format);
      } else {
        ret = dayjs(date).format(format); // HH:mm:ss');
      }
      return ret;
    },
  },
};
</script>
